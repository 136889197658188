@import "src/mixins/styles";
.soundSelector {
  width: 100% !important;
  display: flex;
  align-items: center;

  .MuiAutocomplete-root {
    width: 100%;
    .MuiOutlinedInput-notchedOutline {
      border-color: $border-color;
    }

    .MuiOutlinedInput-root {
      border-radius: 25px !important;
      padding: 0 0 0 10px;
      background-color: #fff;
      input:hover {
        outline: none;
      }
      .text-field-play-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        height: 30px;
        width: 30px;
        background: none;
        cursor: pointer;
        svg {
          fill: #2b2b2b;
          width: 25px;
          height: 25px;
        }
      }
      .rec-btn {
        text-align: center;
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
      }
    }
  }
}

.play-btn {
  background: none;
  min-width: 34px;
  min-height: 34px;
  max-width: 34px;
  max-height: 34px;
  box-shadow: none;
  border: none;
  border-radius: 50%;
  &.draft-sound-fill {
    svg {
      fill: $button-color !important;
    }
  }
  &:hover {
    box-shadow: none;
    transform: scale(1.1);
  }
  svg {
    width: 20px;
    min-width: 20px;
    fill: $main-font-color;
  }
}

.MuiTooltip-tooltip {
  font-size: 16px !important;
  min-width: 20px !important;
  max-width: 1000px !important;
}

.soundSelector-popper {
  min-width: 500px;
  div {
    border-radius: 15px;
    margin: 10px 0;
  }
  li {
    color: $main-font-color;
    border-bottom: 1px solid rgb(240, 240, 240);

    .rec-btn-box {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      cursor: auto;
      .rec-btn {
        border-radius: 50%;
        min-width: 40px;
        min-height: 40px;
      }
    }
    .option-box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: fit-content;
      width: 100%;
      margin: 0px;

      div {
        margin: 0px;
      }
      .play-btn-and-separator {
        display: flex;
        align-items: center;
        margin-right: 6px;
      }
      .option-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        .option-name {
          display: flex;
          width: 100%;
        }
        .option-additional-info {
          display: flex;
          width: 100%;
          justify-content: flex-start;
          align-items: center;
          font-size: 12px;
          .option-language,
          .option-author,
          .option-bind {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid rgb(230, 230, 230);
            border-radius: 5px;
            width: fit-content;
            padding-left: 5px;
            padding-right: 5px;
            height: 20px;
            margin-right: 5px;
            &.display-none {
              display: none;
            }
          }
          .option-language {
            text-transform: uppercase;
          }
        }
      }

      .clear-sound-icon {
        display: flex;
        justify-content: flex-end;
        transform: scale(1);
        svg {
          g {
            stroke: $button-color;
            circle {
              fill: white !important;
            }
          }
        }
      }
    }
  }
}
