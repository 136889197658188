@import "src/mixins/styles";

.subscription_card {
  @include flex-center;
  flex-direction: column;
  min-width: 320px;
  height: auto;
  gap: 16px;
  border-radius: 10px;
  font-family: "Manrope", sans-serif;
  p {
    font-family: "Manrope", sans-serif;
  }
  h2 {
    font-family: "Manrope", sans-serif;
  }
  &__columns {
    @include flex-center-between;
    width: 100%;
    border-radius: 12px;
    gap: 12px;

    p {
      @media screen and (max-width: $media-query-m) {
        font-size: smaller;
      }

      &:disabled {
        color: #374151;
      }
      font-weight: 400;
      color: black;

      &.subscription_status_active {
        color: #10b981;
      }
      &.subscription_status_canceled {
        color: #ef4444;
      }
    }
  }

  &__details {
    min-width: 300px;
    position: relative;
    @include flex-column;
    gap: 16px;
    width: 100%;
    margin: 4px;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid rgba(116, 115, 115, 0.18);

    .circularProgress {
      position: absolute;
      top: calc(50% - 50px);
      left: calc(50% - 50px);
      .MuiCircularProgress-svg {
        color: $subscription-button-color;
      }
    }
  }

  &__wrapper {
    padding: 32px;
    @include flex-center-column;
    width: 100%;
    box-shadow: 0 28px 60px 0 rgba(154, 154, 175, 0.27);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 12px;
    @media screen and (max-width: $media-query-m) {
      padding: 16px;
    }
    position: relative;
    .refreshButtonBlock {
      position: absolute;
      top: 20px;
      right: 20px;
      .header-refreshDataButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        background-color: $subscription-button-color;
        border-radius: 50%;

        cursor: pointer;
        &.spin-animation {
          background-color: $subscription-button-color;
          animation: spin 1s linear infinite;
          transition: all 1s ease-in-out;
          cursor: default;
        }
        &:hover {
          box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
          transform: scale(1.1);
          transition: all 0.2s ease-in-out;
        }
        svg {
          height: 25px;
          width: 25px;
          fill: white;
        }
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
    img {
      margin: 0 auto;
      display: flex;
      object-fit: contain;
      width: 100px;
    }

    &-content {
      padding-top: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
    }
  }

  &__name {
    font-weight: 400;
    line-height: 29px;
    color: black;
  }

  &__active {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    gap: 10px;
  }

  p {
    font-weight: 400;
  }

  a {
    font-weight: 400;
    text-decoration: none;
    color: $main-accent-color;
  }

  button {
    width: 100%;
    height: 40px;
    margin-top: 10px;
    font-size: $font-size-ml;
    font-weight: 400;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 12px;
    background: $subscription-button-color;
    &:hover {
      transform: scale(1.03);
    }
  }

  .subscription_cancel_btn {
    background: rgba(246, 11, 11, 0.18);
    max-width: 55%;
    margin-top: 4px;
    max-height: 22px;
    p {
      font-size: $font-size-s;
    }
  }

  .subscription-after-action-text {
    margin-top: 10px;
    max-width: 300px;
    position: relative;
    @include flex-column;
    align-items: center;
    white-space: wrap;
  }
}
