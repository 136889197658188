@import "src/mixins/styles";

.assetBundleActiveItem-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-shadow: none;
  border: 1px solid $border-color;
  background: linear-gradient(270deg, rgba(0, 179, 255, 0.077) 0%, rgba(0, 179, 255, 0.009) 100%);

  .item-active-content {
    position: relative;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    .item-left-content {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin: 10px 5px 10px 5px;
      .item-image {
        height: 60px;
        width: 60px;
        box-sizing: border-box;
        .placeholder {
          box-sizing: border-box;
          height: 60px;
          width: 60px;
          border: 4px solid gray;
          border-radius: 50%;
          background-color: aliceblue;
        }
        img {
          height: 60px;
          width: 60px;
          border: none;
          object-fit: contain;
        }
      }
      .buttons-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 10px;
        .remove-btn {
          min-width: 34px;
          min-height: 34px;
          max-width: 34px;
          max-height: 34px;
        }
      }
    }
    .item-ranges {
      width: 100%;
      margin-left: 10px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .top-row {
        display: flex;
        height: fit-content;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .item-reaction-selector-block {
          display: flex;
          height: 100%;
          align-items: center;
          .item-reaction-selector-label {
            display: flex;
            align-items: center;
          }
          .item-reaction-selector {
            display: flex;
            align-items: center;
            margin-left: 5px;
            width: 165px;
          }
        }
      }

      .input-with-label {
        margin: 0px 0px 0px 10px;
        input {
          margin: 0;
        }
        .slider-title {
        }
      }
      .two-columns {
        display: flex;
        height: fit-content;
        align-items: center;
        margin-bottom: 5px;
        .column1 {
          display: flex;
          align-items: center;
          height: fit-content;
          width: calc(100% - 160px);
        }
        .column2 {
          display: flex;
          height: fit-content;
          justify-content: flex-end;
          align-items: center;
          width: 160px;
        }
      }
      .scale-and-flippers {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
      }
    }
  }
  .slider {
    width: 100%;
    input {
      min-width: 53px;
    }
    &.scale {
      margin-left: 10px;
    }
  }

  .checkbox-with-label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: $main-font-color;
    margin-right: 10px;
    .checkbox {
      padding: 0;
      margin-right: 3px;
    }

    &.vflipper {
      margin-left: 5px;
    }
  }

  .activeItem-color-picker {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: $main-font-color;
    margin-left: 10px;
    .color-picker-button {
      margin-left: 5px;
      border: 1px solid $border-color;
      border-radius: 5px;
      width: 20px;
      height: 20px;
    }
    .custom-color {
      width: 65px;
      height: 20px;
      margin: 0 0 0 5px;
      padding: 2px 0 2px 3px;
      font-size: 12px;
      border-radius: 5px;
      &:hover {
        outline: none;
      }
    }
    div {
      div {
        border-radius: 4px;
      }
      .react-colorful {
        position: absolute;
        filter: drop-shadow(5px 10px 10px rgba(0, 0, 0, 0.3));
        top: -210px;
        right: 60px;
        z-index: 9999;
      }
    }
    .outline-check {
      padding: 0;
      margin-right: 3px;
    }
  }
}
