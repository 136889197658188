@import "src/mixins/styles";

.group-tile-component {
  display: flex;
  width: 100%;
  margin-bottom: 25px;
  transition: all 200ms ease-in-out;
  border-radius: 15px 30px 15px 30px;
  &.mode-group-serial {
  }
  .tile1 {
    height: 100%;
    display: flex;
    width: 100%;
    background-color: $white-color;
    border: solid 1px $border-color;
    border-radius: 15px;
    cursor: pointer;
    overflow: visible;

    &.group-serial {
      max-width: calc(100% - 10px + 2px);
      max-height: calc(100% - 10px + 3px);
    }
    .tile2 {
      margin-top: 5px;
      margin-left: -1px;
      height: calc(100% - 4px);
      min-width: calc(100% + 2px);
      display: flex;
      background-color: $white-color;
      border: solid 1px $border-color;
      border-radius: 15px;
      cursor: pointer;
      overflow: visible;

      &.group-serial {
        margin-left: 5px;
        min-width: calc(100% + 0px);
        min-height: calc(100% + 0px);
      }
      .tile3 {
        margin-top: 5px;
        margin-left: -1px;
        height: calc(100% - 4px);
        min-width: calc(100% + 2px);
        display: flex;
        background-color: $white-color;
        border: solid 1px $border-color;
        border-radius: 15px;
        padding: 5px;
        cursor: pointer;
        overflow: visible;

        &.group-serial {
          margin-left: 5px;
          min-width: calc(100%);
          min-height: calc(100%);
        }
      }
    }
  }

  &:hover {
    transform: scale(1.01);
    box-shadow: 0px 28px 27px rgba(0, 0, 0, 0.05), 0px 0px 25px rgba(0, 0, 0, 0.05), 0px 11px 15px rgba(0, 0, 0, 0.06);
  }
}
.single-tile-component {
  display: flex;
  width: 100%;
  background-color: $white-color;
  border: solid 1px $border-color;
  border-radius: 15px;
  padding: 5px;
  margin-bottom: 25px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  &:hover {
    transform: scale(1.01);
    box-shadow: 0px 28px 27px rgba(0, 0, 0, 0.05), 0px 0px 25px rgba(0, 0, 0, 0.05), 0px 11px 15px rgba(0, 0, 0, 0.06);
  }
}

.no-hover-transforms {
  &:hover {
    transform: scale(1);
    box-shadow: none;
  }
}
