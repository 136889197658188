@import "src/mixins/styles";

.round-button {
  color: $main-font-color;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  min-height: 80px;
  background-color: $main-accent-color;
  border: 1px solid $border-color;
  border-radius: 50%;
  cursor: pointer;
  transition: all 300ms;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  &:disabled,
  [disabled] {
  }
  .round-button-icon {
    width: 20px;
    height: 20px;
    fill: $white-color;
    &:disabled,
    [disabled] {
    }
  }
  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
}
