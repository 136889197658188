@import "src/mixins/styles";

.tile-wrapper {
  display: flex;
  //max-width: 1200px;
  width: 100%;
  min-height: 115px;
  max-height: 115px;
  background-color: $white-color;
  border: solid 1px $border-color;
  border-radius: 15px;
  padding: 5px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  @media screen and (max-width: 1560px) {
    max-width: 1000px;
  }
  @media screen and (max-width: 1360px) {
    max-width: 800px;
  }
  @media screen and (max-width: 1160px) {
    max-width: 700px;
  }
  &:hover {
    transform: scale(1.01);
    box-shadow: 0px 28px 27px rgba(0, 0, 0, 0.05), 0px 0px 25px rgba(0, 0, 0, 0.05), 0px 11px 15px rgba(0, 0, 0, 0.06);
  }
  .tile-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    .header {
      display: flex;
      justify-content: flex-end;
      position: relative;
      .header-left-content {
        display: flex;
        flex-direction: row;
        gap: 5px;
        position: absolute;
        top: 0px;
        left: 0px;
        .webUserEmailFlag {
          fill: lightgray;
          &.active {
            fill: green;
          }
        }
        .demo-status {
          border: solid 1px lightgray;
          border-radius: 5px;
          padding: 2px 5px;
          color: rgb(76, 76, 76);
        }
        .quickTest-status {
          border: solid 1px lightgray;
          border-radius: 5px;
          padding: 2px 5px;
          color: green;
        }
        .active-subscription-status {
          border: solid 1px lightgray;
          border-radius: 5px;
          padding: 2px 10px;
          color: rgb(2, 10, 2);
          background-color: lightgreen;
        }
        .active-canceled-subscription-status {
          border: solid 1px lightgray;
          border-radius: 5px;
          padding: 2px 10px;
          color: rgb(2, 10, 2);
          background-color: lightgreen;
        }
        .canceled-subscription-status {
          border: solid 1px lightgray;
          border-radius: 5px;
          padding: 2px 10px;
          color: rgb(2, 10, 2);
          background-color: lightcoral;
        }
        .subscription-not-active-status {
          border: solid 1px lightgray;
          border-radius: 5px;
          padding: 2px 10px;
          color: rgb(2, 10, 2);
          background-color: lightcoral;
        }
        .teacher-status {
          border: solid 1px lightgray;
          border-radius: 5px;
          padding: 2px 10px;
          color: rgb(2, 10, 2);
          background-color: #80d4f0;
        }
        .telegram-bot-status {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 23px;
          width: 23px;
          background-color: #1976d2;
          border-radius: 50%;
          transition: all 200ms ease;
          &:hover {
            transform: scale(1.1);
            transition: all 200ms ease;
          }
          svg {
            height: 18px;
            width: 18px;
            fill: white;
          }
        }
      }
      .header-right-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        position: absolute;
        top: 0px;
        right: 0px;
        .pushPinIconClickArea {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          transition: all 200ms ease-in-out;
          .pushPinIcon {
            fill: $main-accent-color-light;
            &.active {
              transform: rotate(35deg);
              fill: $main-accent-color;
            }
          }
        }
        .hideByDeviceIdIconClickArea {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          transition: all 200ms ease-in-out;
          .hideByDeviceIdIcon {
            fill: $main-accent-color-light;
            &.active {
            }
          }
        }
      }
      .user-name-block {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $main-font-color;
        width: 100%;
        .label {
        }
        .user-name {
          margin-left: 10px;
          font-weight: 600;
          font-size: 18px;
        }
      }
    }
    .body {
      display: flex;
      height: 100%;
      padding: 5px 0px 5px 0px;

      .body-left-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
      }

      .body-middle-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .info-row {
          display: flex;
          align-items: center;
          margin-left: 0px;
          margin-bottom: 5px;
          color: $main-font-color;
          .unityId-block {
            display: flex;
            align-items: center;
            margin-left: 0px;
            .label {
              font-weight: 600;
            }
            .unityId {
              margin-left: 10px;
            }
          }
          .webUserEmail-block {
            display: flex;
            align-items: center;
            margin-left: 10px;
            gap: 5px;
            .webUserEmail {
              color: green;
            }
            .loginIcon {
              display: flex;
              align-items: center;
              transition: all 200ms ease;
              &:hover {
                transform: scale(1.2);
                transition: all 200ms ease;
              }
              svg {
                fill: green;
                height: 20px;
                width: 20px;
              }
            }
          }
          .deviceInfo-block {
            display: flex;
            align-items: center;
            margin-left: 10px;
            .label {
              font-weight: 600;
            }
            .deviceParam {
              margin-left: 10px;
            }
          }
        }
      }

      .body-right-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        position: relative;

        .delete-button {
          margin-right: 10px;
          width: 100px;
        }
      }
    }
    .tile-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .date-block {
        display: flex;
        font-size: 12px;
        .date-label {
          display: flex;
          font-weight: 600;
        }
        .date {
          display: flex;
          min-width: 150px;
          max-width: fit-content;
          margin-left: 10px;
          p {
            text-wrap: none;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
.tile-wrapper-selected {
  min-height: 350px;
  max-height: 350px;
  transform: scale(1.01);
  box-shadow: 0px 28px 27px rgba(0, 0, 0, 0.05), 0px 0px 25px rgba(0, 0, 0, 0.05), 0px 11px 15px rgba(0, 0, 0, 0.06);
  &:hover {
    transform: scale(1.01);
    box-shadow: 0px 28px 27px rgba(0, 0, 0, 0.05), 0px 0px 25px rgba(0, 0, 0, 0.05), 0px 11px 15px rgba(0, 0, 0, 0.06);
  }
}
