@import "src/mixins/styles";

.summary-results-component {
  display: flex;
  width: 100%;
  .summary-results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .summary-result-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 5px;
      width: 100%;
      border-bottom: 1px solid $border-color;
      .summary-result-label {
        display: flex;
        display: inline;
        align-items: center;
        min-width: 150px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .progress-and-param {
        display: flex;
        width: 100%;
        align-items: center;
        .summary-result-progress {
          display: flex;
          align-items: center;
          width: 100%;
          height: 10px;
          border-radius: 5px;
          overflow: hidden;

          .summary-result-progress-element {
            height: 10px;
            background-color: $statistic-progress-color;
            border-radius: 5px;
            border: 1px solid gray;
          }
        }

        .summary-result-param {
          display: flex;
          justify-content: flex-end;
          margin-left: 5px;
          width: 30px;
          overflow: hidden;
        }

        .controlled-result-progress {
          display: flex;
          align-items: center;
          width: 100%;
          height: 10px;
          border-radius: 5px;
          overflow: hidden;
          border: 1px solid gray;
          background-color: $statistic-controlled-incorrect-color;

          .controlled-result-progress-element-correct {
            height: 10px;
            background-color: $statistic-controlled-correct-color;
            border-radius: 5px 0px 0px 5px;
            border: 1px solid gray;
          }
        }
        .controlled-result-param {
          display: flex;
          justify-content: flex-end;
          margin-left: 5px;
          min-width: fit-content;
          overflow: hidden;
        }
      }
    }
  }
}
