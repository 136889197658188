@import "src/mixins/styles";

.audio-recorder-container {
  position: relative;
  overflow: hidden;
  width: 350px;
  height: 300px;
  .sound-recorder-input {
    height: 40px;
    border-radius: 15px;
  }
  .language-switch-box {
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    .label {
      margin-right: 5px;
      font-weight: 500;
      color: $main-font-color;
    }
  }
  .recorder-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 140px;
    .record-btn,
    .play-btn-recorder {
      position: absolute;
      svg {
        height: 60px;
        width: 40px;
      }
    }
    .record-btn {
      background: #fec900;
    }
    .recording {
      background: #c05050;
    }
    .playing {
      background: rgb(132, 192, 29);
    }
    .substrate {
      position: absolute;
      width: 90px;
      height: 90px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0px 30px 40px rgb(0 0 0 / 10%);
    }
    .cancel-record,
    .confirm-record {
      min-width: 40px;
      width: 40px;
      min-height: 40px;
      height: 40px;
      margin: 0;
      background-color: #fec900;
    }
    .cancel-record {
      background-color: #c05050;
    }
    .confirm-record {
      background-color: #5daaaf;
    }
    &:disabled,
    [disabled] {
      background-color: $placeholder-color;
      cursor: auto;
      pointer-events: none;
    }
    .btn-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 66px;
      box-shadow: 0px -7px 30px rgba(0, 0, 0, 0.15);
      padding: 0 20px 0 20px;
      .record-time {
        input {
          font-size: 40px;
          color: $main-font-color;
          text-align: center;
          background: none;
          border: none;
        }
      }
    }
  }
}
