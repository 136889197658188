@import "src/mixins/styles";
.joinUsFooterForm {
  .joinUsFooterForm-content {
    padding: 40px 64px 30px 64px;
    width: 638px;
    border-radius: 22px;
    box-shadow: 0 28px 60px 0 rgba(154, 154, 175, 0.27);
    backdrop-filter: blur(14px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @media screen and (max-width: $media-query-xl) {
    }
    @media screen and (max-width: $media-query-l) {
    }
    @media screen and (max-width: $media-query-m) {
    }
    @media screen and (max-width: $media-query-s) {
      padding: 20px 5px 10px 5px;
    }

    &__heading {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    p {
      font-family: "Manrope";
      color: #6b7280;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    h3 {
      font-family: "Manrope";
      font-weight: 500;
      max-width: 321px;
      color: #374151;
      text-align: center;
      font-size: 46px;
      line-height: 90%;
      letter-spacing: -1px;
      @media screen and (max-width: $media-query-s) {
        font-size: 38px;
      }
    }

    span {
      font-family: "Manrope";
      font-weight: 500;
      padding-left: 5px;
      color: #ffb300;
      line-height: 90%;
      letter-spacing: -1px;
    }
  }
  &.background {
    background: url("../../../../assets/images/Login/login_bg.svg") bottom center no-repeat, rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 40px;
    margin-bottom: 0px;
    @media screen and (max-width: $media-query-xl) {
    }
    @media screen and (max-width: $media-query-l) {
    }
    @media screen and (max-width: $media-query-m) {
      padding-top: 40px;
      padding-bottom: 40px;
      margin-bottom: 0px;
    }
    @media screen and (max-width: $media-query-s) {
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 0px;
    }
  }
}
