@import "src/mixins/styles";

.tags {
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: auto;
  width: fit-content;
  max-width: 100%;
  padding: 5px 10px 10px 10px;
  margin-top: 10px;
  color: $main-font-color;
  @include scroll-styles;
  .tag {
    color: $placeholder-color;
    cursor: pointer;
    padding: 5px 10px;
    font-size: $font-size-m;
    width: fit-content;
    white-space: nowrap;
    transition: all 200ms;
    height: fit-content;
    border-radius: 10px;
    &:hover {
      transform: scale(1.1);
    }
  }
  .tag.selected {
    color: $main-font-color;
    background-color: rgb(232, 240, 254);
    margin: 0 5px;
    &:hover {
      box-shadow: 0 0 6px rgba($color: #003662, $alpha: 0.3);
    }
  }
}
