@import "src/mixins/styles";

.header-wrapper {
  position: absolute;
  justify-content: space-between;
  min-width: 260px;
  height: 40px;
  background: $white-color;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 5px;
  margin-bottom: 10px;
  z-index: 1;
  .customize-button {
    font-size: $font-size-s;
    height: 30px;
    width: 49%;
  }
  .main-menu-btn {
    width: 100%;
    .home {
      border-radius: 20px 3px 3px 20px;
      z-index: 1;
    }
  }
  .back {
    border-radius: 3px 20px 20px 3px;
  }
  .creator {
    border-radius: 3px 20px 20px 3px;
  }
}
