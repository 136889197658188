@import "src/mixins/styles";
.gameDataTile-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  border: 2px solid $border-color;
  border-radius: 15px;
  padding: 5px 5px 10px 5px;
  position: relative;
  .gameDataTile-gameType {
    font-weight: 600;
    color: $main-font-color;
  }
  .gameDataTile-stages-view-wraper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 5px;
    margin-top: 5px;

    .gameDataTile-stages-view-stageIcon {
      display: flex;

      max-width: calc(100% / 2 - 10px) !important;
      min-width: calc(100% / 2 - 10px) !important;
      object-fit: cover;
      min-height: auto;
      transform: none;
    }
  }

  .fullfilledPreconfigs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 5px;

    .fullfilledPreconfig-block {
      display: flex;
      flex-direction: row;
      gap: 5px;

      .fullfilledPreconfig {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3px;

        .rightKnowledgeTags,
        .wrongKnowledgeTags,
        .questionKnowledgeTags {
          display: flex;
          border-radius: 10px;
          padding: 2px 5px 2px 5px;
        }
      }
    }
  }
}
