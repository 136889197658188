@import "src/mixins/styles";

.assetBundleCommonConfig-component {
  width: 100%;
  height: calc(100vh - 10px);
  background-color: $white-color;
  border: 1px solid $border-color;
  border-radius: 15px;
  padding: 0px 20px 20px 20px;
  .assetBundleCommonConfig-content {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 0px;
    .main-assetBundle-config,
    .sounds-assetBundle-config,
    .errors-assetBundle-config {
      display: flex;
      border-bottom: 1px solid $border-color;
      padding: 20px 0;
      .mgc-one-label,
      .sgc-one-label,
      .egc-one-label {
        width: 15%;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .main-assetBundle-config {
      .mgc-two {
        width: 45%;
        margin-right: 15px;
        .assetBundle-icon-block {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 10px;
          .mgc-assetBundle-icon {
            display: flex;
            align-items: center;
            width: 180px;
            margin-right: 20px;
            height: 100%;
          }
          .type-and-name {
            display: flex;
            flex-direction: column;
            width: 100%;
            .mgc-assetBundle-name {
              height: 50px;
              border-radius: 25px;
              padding-left: 15px;
              font-size: 18px;
              margin-bottom: 10px;
            }
            
            .mgc-assetBundle-created-updated {
              display: flex;
              .mgc-assetBundle-createdBy {
                margin-left: 17px;
              }
              .mgc-assetBundle-updatedBy {
                margin-left: 17px;
              }
            }
          }
        }

        .mgc-assetBundle-description {
          width: 100%;
          resize: none;
          height: 200px;
          border-radius: 25px;
          padding-left: 15px;
          padding-top: 10px;
          font-size: 18px;
          margin-bottom: 15px;
          border: 1px solid $border-color;
          &:focus {
            outline: 0;
          }
          &:hover {
            outline: 1px solid $main-accent-color;
          }
          &::-webkit-input-placeholder {
            color: $placeholder-color;
            font-size: $font-size-m;
          }
        }

        
      }
      .mgc-three {
        display: flex;
        flex-direction: column;
        width: 40%;
        .top {
          display: flex;
          justify-content: flex-end;
        }
        .middle {
          display: flex;
          justify-content: flex-end;
          height: 100%;
          margin-top: 20px;
          .creator-buttons-block {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: flex-start;
            gap: 20px;
          }
        }
        .bottom {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    .sounds-assetBundle-config {
      .sgc-two {
        width: 45%;
        .sgc-sound-selector-wrapper {
          display: flex;
          align-items: center;
          &:not(:first-child) {
            margin-top: 15px;
          }
          .sgc-sound-selector-label {
            min-width: 125px;
            text-align: right;
            span {
              padding: 0 10px 0 0;
              margin-top: -4px;
            }
          }
          .sgc-assetBundle-sound-selector {
            width: 100%;
            .autocomplete {
              margin: 0 0 0 10px;
              width: 100%;
            }
          }
          .sgc-assetBundle-sound-selector {
            .sgc-single {
              .MuiOutlinedInput-root {
                height: 50px;
                .rec-btn {
                  min-height: 35px;
                  min-width: 35px;
                  top: 9px;
                  left: 10px;
                }
              }
            }
          }
          .sgc-assetBundle-sound-selector {
            .sgc-multiple {
              .MuiOutlinedInput-root {
                padding: 5px;
                
                .MuiChip-label {
                  color: $main-font-color;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      padding: 10px 10px 10px 10px;
    }
    .gc-one-width {
      min-width: 150px;
    }
  }

  .gc-btn-icon-style {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: $button-color;
    svg {
      fill: $white-color;
      width: 14px;
      height: 14px;
    }
  }
  .background-colorир-red {
    background-color: $alert-color;
  }

  .gc-assetBundle-btn-style {
    @include game-button;
    margin-right: 10px;
  }
}
