@import "src/mixins/styles";

.marketingStatistic-page {
  @include background-style;
  align-items: center;
  padding: 5px;
  color: $main-font-color;

  .main-header {
    margin: 0px;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0;
    width: 270px;
    .home {
      border-radius: 20px;
      width: 260px;
      margin: 0;
      div {
        margin-left: 10px;
        font-size: $font-size-ml;
      }
      &:hover {
        width: 265px;
        height: 34px;
        margin-bottom: 0.5px;
        transform: scale(1);
      }
      &:hover > .header-title,
      &:hover > .icon-header {
        transform: scale(1.1);
      }
      &:hover > .icon-header {
        margin-right: 10px;
      }
      .header-title,
      .icon-header {
        transition: all 200ms;
      }
    }

    .back {
      display: none;
    }
  }

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    height: 100%;
    .left-content-wrapper {
      display: flex;
      height: calc(100% - 45px);
      margin-top: 45px;
      width: 270px;
      min-width: 270px;
      margin-right: 5px;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
    }
    .middle-content-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 360px;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      .newUsersStatistic {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
        .newUsersPlot-header {
          margin-left: 20px;
          font-size: 18px;
          color: $main-font-color;
          font-weight: bold;
        }
      }
    }
  }
}
