@import "src/mixins/styles";
.planElementCheckResults-component {
  display: flex;
  flex-direction: column;

  .controlFormula-block {
    display: flex;
    flex-direction: row;
  }
  .formulaBasedCombinations {
    display: flex;
    flex-direction: column;
    gap: 5px;
    
    .combination-block{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 5px;
      .addToSoundSets-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default;
        svg {
          fill:lightgray;
        }
        &.active {
          cursor: pointer;
          svg {
            fill: $main-accent-color;
          }
        }

      }
      .combination {
        display: flex;
        flex-direction: row;
        gap: 3px;
        border-radius: 10px;
        //padding: 5px 5px 5px 5px;
        &.has-all-resources-color {
          background-color: rgb(200, 255, 200);
        }
        .firstPartExtra,
        .secondPartExtra,
        .thirdPartExtra {
          display: flex;
          border-radius: 10px;
          padding: 5px 5px 5px 5px;
          &.no-bundles-color {
            background-color: lightgray
          }
          &.no-soundSets-color {
            background-color: rgb(255, 200, 180);
          }
        }
      }
    }
    
  }
}
