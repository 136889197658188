@import "src/mixins/styles";

.sound-types-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .label {
    margin-left: 10px;
  }
  .sound-types {
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: auto;
    width: fit-content;
    max-width: 100%;
    color: $main-font-color;
    @include scroll-styles;
    .sound-type {
      color: $placeholder-color;
      cursor: pointer;
      padding: 5px 10px;
      font-size: $font-size-m;
      width: fit-content;
      white-space: nowrap;
      transition: all 200ms;
      height: fit-content;
      border-radius: 10px;
      &:hover {
        transform: scale(1.1);
      }
    }
    .sound-type.selected {
      color: $main-font-color;
      background-color: rgb(232, 240, 254);
      margin: 1px 5px;
      &:hover {
        box-shadow: 0 0 6px rgba($color: #003662, $alpha: 0.3);
      }
    }
  }
}
