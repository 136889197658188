@import "src/mixins/styles";

.knowledgeGroupTile-component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: fit-content;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 5px;
  color: $main-font-color;
  background-color: $main-accent-color-light;
  cursor: pointer;
  .groupName {
    font-weight: 550;
  }
  .deps {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    font-size: 14px;
    color: rgb(83, 82, 82);
    width: 100%;
    .dep {
      width: 100%;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.selected {
    border: 2px solid gray;
    background-color: $main-accent-color;
    transform: scale(1.05);
    transition: all 0.2s ease;
  }
  &.backgroundAlert {
    background-color: $alert-color;
  }
}
