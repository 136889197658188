@import "src/mixins/styles";
.gameSetTile-border {
  border: 2px solid lightgray;
}
.gameSetTile-component {
  display: flex;
  flex-direction: column;
  width: calc(100%);
  transition: all 200ms ease-in-out;
  padding: 0px 10px 0px 10px;

  .gst-header {
    display: flex;
    flex-direction: column;
    .gst-gamesSet {
      display: flex;
      justify-content: center;
      width: 100%;
      gap: 10px;

      .gst-gamesSet-icon {
        width: 20% !important;
      }
      .gst-gamesSet-stageIcon {
        min-width: 20% !important;
        max-width: 20% !important;
        object-fit: cover;
        min-height: auto;
        transform: none;
        //aspect-ratio: 16 / 9;
      }
    }
    .gst-time-row {
      display: flex;
      .gst-time {
        display: flex;
        width: fit-content;
      }
    }
  }
}
