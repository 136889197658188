@import "src/mixins/styles";

.resetPassword-page-wrapper {
  @include login-bg;
  color: $main-font-color;
  display: flex;
  align-items: center;
  justify-content: center;
  .resetPassword-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .resetPassword-title {
      margin-bottom: 40px;
      font-size: $font-size-xl;
      text-transform: uppercase;
      text-align: center;
    }
    .button-box {
      padding: 30px 50px;

      .button:not(:last-child) {
        margin-bottom: 20px;
      }

      .link-box {
        margin-top: 20px;
        .login-link {
          text-align: center;
          font-size: 16px;
          color: black;
        }
      }

      .ok-message {
        height: 25px;
        margin-bottom: 15px;
        text-align: center;
        color: black;
      }
      .error-message {
        height: 25px;
        margin-bottom: 15px;
        text-align: center;
        color: red;
      }

      .resetPassword-form {
        margin: auto;
        width: 270px;
        height: 270px;
        input {
          padding: 13px 8px 12px 8px;
          border-radius: 8px;
          background-color: rgb(232, 240, 254);
          margin-bottom: 20px;
          font-size: $font-size-ml;
        }
        .resetPassword-lable {
          font-size: 16px;
          color: black;
          text-align: center;
          margin-bottom: 5px;
        }
        .customize-button {
          padding: 13px 24px 12px 24px;
          border-radius: 8px;
          background: #ffb300;
          font-size: $font-size-s;
          width: 100%;
        }
      }
    }
  }
}
