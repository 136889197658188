.modal-wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100vh !important;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99999;
  backdrop-filter: blur(10px);
  .modal-cancel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100vh !important;
  }
  .modal {
    position: relative;
    z-index: 1;
  }
}
