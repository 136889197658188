@import "src/mixins/styles";

.common-tab-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  font-size: 18px;
  margin-top: 5px;

  .left-block {
    display: flex;
    flex-direction: column;
    width: calc(100%);
    padding-left: 10px;

    .email-block {
    }
    .age-block {
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .demoCheckbox-block {
      display: flex;
      width: fit-content;
      align-items: center;
      margin-left: -10px;
    }

    .userLanguageSelector-block {
      display: flex;
      align-items: center;
      .label {
        width: 160px;
      }
      .userLanguageSelector {
        display: flex;
        width: 40px;
        font-size: 18px;
        max-width: 150px;
        min-width: 150px;
        min-height: 35px;
        max-height: 35px;
      }
    }
    .unity-id-block {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .label {
        min-width: 160px;
      }
      .input {
        margin: 0%;
        font-size: 18px;
        min-width: 200px;
      }
      .webUserEmail {
        margin-left: 10px;
        color: green;
      }
      .webUserSelector {
        margin-left: 10px;
        min-width: 300px;
      }
    }
    .telegramBotClientId-block {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 30px;
      .label {
        min-width: 160px;
      }
      .input {
        margin: 0%;
        font-size: 18px;
        min-width: 300px;
      }
    }
    .openSendPulse-button {
      width: 200px;
    }
  }
  .right-block {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: 100%;
    &-top {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: flex-end;
      gap: 10px;
      justify-content: flex-start;
      .clearStatistic-button {
        width: 200px;
      }
    }
    &-bottom {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: flex-end;
      gap: 10px;
      justify-content: flex-end;
      .delete-button {
        width: 200px;
      }
    }
  }
}
