@import "src/mixins/styles";

.app-market-buttons {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;

  @media screen and (max-width: $media-query-xl) {
  }
  @media screen and (max-width: $media-query-l) {
  }
  @media screen and (max-width: $media-query-m) {
  }
  @media screen and (max-width: $media-query-s) {
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }
}
