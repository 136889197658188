.svgConnectionArrow-component {
  position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  height: 100%;
  pointer-events: none;
  &.dimmed {
    opacity: 0.3;
  }
}
