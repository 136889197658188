@import "src/mixins/styles";

.aboutChild-tab-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  font-size: 18px;
  margin-top: 5px;
  gap: 10px;
  .name-block {
    display: flex;
    align-items: center;
    .label {
      min-width: 200px;
    }
    .input {
      margin: 0%;
      min-width: 300px;
      max-width: 300px;
      font-size: 18px;
    }
  }

  .birthdayMonth-block {
    display: flex;
    align-items: center;
    .label {
      min-width: 200px;
    }
    .input {
      margin: 0%;
      min-width: 100px;
      max-width: 100px;
      font-size: 18px;
      margin-right: 10px;
    }
  }
  .birthdayYear-block {
    display: flex;
    align-items: center;
    .label {
      min-width: 200px;
    }
    .input {
      margin: 0%;
      min-width: 100px;
      max-width: 100px;
      font-size: 18px;
    }
    .age-block {
      margin-left: 10px;
    }
  }

  .genderSelector-block {
    display: flex;
    align-items: center;
    .genderSelector-label {
      width: 160px;
    }
    .genderSelector {
      display: flex;
      width: 40px;
      font-size: 18px;
      max-width: 150px;
      min-width: 150px;
      min-height: 35px;
      max-height: 35px;
    }
  }

  .aboutChild-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    height: 100%;
    .aboutChild-label {
      width: 120px;
      height: 25px;
    }
    .aboutChild-input {
      display: flex;
      min-height: calc(100% - 30px);
      min-width: 600px;
      max-width: 600px;
      resize: none;
      border: 1px solid lightgray;
      border-radius: 10px;
      padding: 10px;
    }
  }
}
