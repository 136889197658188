@import 'src/mixins/styles';

.list-wrapper {
  .label {
    text-align: center;
    color: $main-font-color;
  }
  .list {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    li div {
      margin: 0;
      padding: 3px;
    }
  }
}
