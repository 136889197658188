@import "src/mixins/styles";

.stageItemLibrary-component {
  height: 100%;
  .stageItemLibrary-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
    .stageItemLibrary-list {
      display: flex;
      flex-wrap: wrap;
      overflow-x: auto;
      overflow-y: auto;
      justify-content: flex-start;
      width: 100%;
      padding: 0px 10px 0px 10px;
      @include scroll-styles;
      .stageItemLibrary-element {
        margin-bottom: 10px;
        min-height: 70px;
        max-height: 70px;
      }
    }
  }
}
