@import "src/mixins/styles";

.gameCard-wrapper {
  color: #2b2b2b;
  position: relative;
  min-width: 85px;
  width: 235px;
  min-height: 85px;
  height: 120px;
  margin: 2px;
  margin-bottom: 10px;
  transition: all 200ms;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 0%);
  border: none;
  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 28px 27px rgba(0, 0, 0, 0.05), 0px 0px 25px rgba(0, 0, 0, 0.05), 0px 11px 15px rgba(0, 0, 0, 0.06);
  }
  &:active {
    transform: scale(1);
    box-shadow: 0px 18px 17px rgba(0, 0, 0, 0.05), 0px 0px 15px rgba(0, 0, 0, 0.05), 0px 5px 15px rgba(0, 0, 0, 0.06);
  }
  .gameCard-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    padding: 10px;
    .game-icon-wrapper {
      margin-bottom: 5px;
      width: 65%;
    }
    .locked-game-icon {
      opacity: 0.5;
    }
    .game-name {
      position: absolute;
      bottom: 3px;
      font-size: $font-size-m;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 205px;
      left: 15px;
      overflow: hidden;
      height: 16px;
      border: 0 solid $border-color;
      padding: 0;
      text-align: center;
      background: none;
      cursor: pointer;
      &:hover {
        outline: 0 solid $border-color !important;
      }
    }
  }
  .errors-icon {
    display: flex;
    position: absolute;
    bottom: 20px;
    right: 12px;
  }

  .save-btn,
  .cancel-btn,
  .copy-btn,
  .remove-btn,
  .lock-btn {
    display: block;
    position: absolute;
    right: -30px;
    cursor: pointer;
    .save-btn-content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      span,
      .button-icon {
        margin: 0;
        width: 13px;
        height: 13px;
      }
    }
  }
  .save-btn,
  .cancel-btn {
    top: 15px;
    width: fit-content;
    animation: 300ms ease-in-out 100ms forwards rightBtn;
    &:disabled,
    [disabled] {
      background-color: $placeholder-color;
      opacity: 0.5;
      &:hover {
        transform: scale(1);
        cursor: auto;
      }
    }
    &:hover {
      transform: scale(1);
    }
  }
  .cancel-btn {
    animation: 300ms ease-in-out 200ms forwards leftBtn;
  }
  .copy-btn {
    top: 70px;
    animation: 300ms ease-in-out 300ms forwards rightBtn;
    &:disabled,
    [disabled] {
      background-color: $placeholder-color;
      opacity: 0.5;
      &:hover {
        transform: scale(1);
        cursor: auto;
      }
    }
    &:hover {
      transform: scale(1);
    }
  }

  .lock-checkbox {
    top: 65px;
    animation: 300ms ease-in-out 500ms forwards leftBtn;
    width: 18px;
    height: 18px;
    margin-top: 10px;
    padding: 0;
    appearance: none;
    outline: none;
    border: 1px solid $placeholder-color;
    cursor: pointer;
    &:hover {
      outline: none;
    }
    &:checked {
      background: url("../../assets/icons/check.svg") no-repeat center 5px;
      background-size: 12px;
      background-color: $alert-color;
      border: 0px;
    }
  }

  .remove-btn {
    top: 42px;
    animation: 300ms ease-in-out 400ms forwards rightBtn;
    .save-btn-content {
      background-color: $alert-color;
    }
  }
}
.active-gameCard-wrapper {
  overflow: hidden;
  outline: 5px solid $main-accent-color;
  transform: scale(1.02);
  box-shadow: 0px 28px 27px rgba(0, 0, 0, 0.05), 0px 0px 25px rgba(0, 0, 0, 0.05), 0px 11px 15px rgba(0, 0, 0, 0.06);
  position: relative;
  
  cursor: auto;
  .game-name {
    position: absolute;
    font-size: $font-size-s;
    text-overflow: ellipsis;
    white-space: nowrap;
    left: 0px;
    width: 200px;
    overflow: hidden;
    text-align: center;
    cursor: auto;
    &::placeholder {
      font-size: 11px;
    }
    &:hover {
      outline: 1px solid $border-color !important;
    }
  }
}

@keyframes rightBtn {
  from {
    opacity: 0;
    right: -30px;
    transform: rotate(360deg);
  }
  to {
    opacity: 1;
    right: 10px;
    transform: rotate(0deg);
  }
}

@keyframes leftBtn {
  from {
    opacity: 0;
    left: -30px;
    transform: rotate(-360deg);
  }
  to {
    opacity: 1;
    left: 10px;
    transform: rotate(0deg);
  }
}
.tooltip {
  color: $white-color;
  font-size: 14px;
}
