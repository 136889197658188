@import url("https://fonts.googleapis.com/css2?family=Outfit&display=swap");

$primary-color: #202020;
$secondary-color: #ffffff;
@keyframes blink {
  0%,
  100% {
    box-shadow: none;
  }
  50% {
    box-shadow: 0px 0px 10px 5px rgba(63, 63, 63, 0.5);
  }
}

.appMarketLinkButtonSvgHref {
  max-height: 42px;
  svg {
  }
}

.appMarketLinkButtonHref {
  color: inherit;
  text-decoration: none;
  border: 2px solid $primary-color;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  height: 50px;
  max-width: 200px;
  min-width: 200px;

  &.light {
    background-color: $secondary-color;
    span {
      color: $primary-color !important;
    }
  }

  &.dark {
    background-color: $primary-color;
    span {
      color: $secondary-color !important;
    }
  }

  &.onlyLogo {
    width: 60px !important;
    height: 50px !important;
  }

  &.blinking {
    animation: blink 1.5s infinite;
  }

  .button-container {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    &_text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-family: "Outfit", sans-serif !important;
      line-height: 100% !important;
      letter-spacing: normal !important;

      &__store-name {
        font-size: 20px !important;
        //font-weight: bold !important;
        line-height: 100% !important;
        letter-spacing: normal !important;
      }

      &__title {
        font-size: 12px !important;
        line-height: 100% !important;
        letter-spacing: normal !important;
      }
    }
  }
}
