@import "src/mixins/styles";

.item-universal-wrapper {
  .sound-icon {
    position: absolute;
    top: 3px;
    left: 3px;
    height: 20px;
    width: 20px;
    background-color: $main-accent-color;
    border-radius: 25px;
    padding: 3px;
    cursor: default;
  }
  .show-remove-btn {
    display: block;
    .remove-btn {
      position: absolute;
    }
  }
  img {
    &:hover {
      filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.8)) !important;
    }
  }
  .outlined {
    --stroke-pos: 2px;
    --stroke-neg: -2px;

    filter: drop-shadow(var(--stroke-pos) 0 0 var(--stroke-color)) drop-shadow(var(--stroke-neg) 0 var(--stroke-color))
      drop-shadow(0 var(--stroke-pos) 0 var(--stroke-color)) drop-shadow(0 var(--stroke-neg) 0 var(--stroke-color))
      drop-shadow(var(--stroke-pos) var(--stroke-pos) 0 var(--stroke-color))
      drop-shadow(var(--stroke-pos) var(--stroke-neg) 0 var(--stroke-color))
      drop-shadow(var(--stroke-neg) var(--stroke-pos) 0 var(--stroke-color))
      drop-shadow(var(--stroke-neg) var(--stroke-neg) 0 var(--stroke-color)) !important;
  }
  .itemError {
    display: flex;
    white-space: nowrap;
    background-color: lightcoral;
    min-width: fit-content;
    padding:20px;
    border-radius: 25% ;
  }
}
