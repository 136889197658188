@import "src/mixins/styles";

.item-active-component {
  display: flex;
  width: 100%;
  height: 100%;
  .item-active-card {
    min-width: 200px;
    min-height: 118px;
    height: calc(100% - 10px);
    box-shadow: none;
    border: 2px solid $border-color;
    margin: 5px;
    background: linear-gradient(270deg, rgba(36, 255, 0, 0.15) 0%, rgba(36, 255, 0, 0) 100%);
    .iac-remove-btn-sound-selector-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .remove-btn {
        min-width: 34px;
        min-height: 34px;
        max-width: 34px;
        max-height: 34px;
        margin-right: 10px;
      }
    }
    .item-active-content {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .item-img {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 5px 35px 5px;
        img {
          width: 60px;
        }
      }
      .item-ranges {
        width: 100%;
        margin-left: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .color-pickers {
          display: flex;
          justify-content: flex-end;
        }
        .reaction-zindex {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          .item-reaction-selector-block {
            display: flex;
            align-items: center;
            .item-reaction-selector-label {
              display: flex;
              align-items: center;
            }
            .item-reaction-selector {
              display: flex;
              align-items: center;
              margin-left: 5px;
              width: 165px;
            }
          }
          .static-checkbox {
            display: flex;
            margin-left: 10px;
            margin-right: 0px;
            margin-bottom: 0px;
            justify-content: flex-end;
            align-items: center;
            color: $main-font-color;
            .outline-check {
              padding: 0;
              margin-right: 0px;
              margin-left: 5px;
            }
          }
        }
        .slider-with-input {
          margin: 0 0 10px 0;
          input {
            margin: 0;
          }
          .slider-title {
            //margin-left: 10px;
          }
        }
        .two-columns {
          display: flex;
          .column1 {
            display: flex;
            flex-direction: column;
            width: 50%;
          }
          .column2 {
            display: flex;
            flex-direction: column;
            width: 50%;
          }
        }
        .scale-and-flippers {
          display: flex;
          align-items: center;
          .flipper-checkbox {
            display: flex;
            margin-left: 0px;
            margin-right: 10px;
            margin-bottom: 10px;
            justify-content: flex-end;
            align-items: center;
            color: $main-font-color;
            .outline-check {
              padding: 0;
              margin-right: 0px;
              margin-left: 5px;
            }
          }
          .slider-with-input {
            flex: 1;
          }
        }
      }
    }
  }
}
.color-picker {
  display: flex;
  margin-bottom: 10px;
  margin-left: 10px;
  justify-content: flex-end;
  align-items: center;
  color: $main-font-color;
  .color-picker-button {
    margin-left: 5px;
  }
  .custom-color {
    width: 65px;
    height: 20px;
    margin: 0 0 0 5px;
    padding: 2px 0 2px 3px;
    font-size: 12px;
    border-radius: 5px;
    &:hover {
      outline: none;
    }
  }
  div {
    div {
      border-radius: 4px;
    }
    .react-colorful {
      position: absolute;
      filter: drop-shadow(5px 10px 10px rgba(0, 0, 0, 0.3));
      top: -210px;
      right: 60px;
      z-index: 9999;
    }
  }
  .outline-check {
    padding: 0;
    margin-right: 0px;
    margin-left: 5px;
  }
}
