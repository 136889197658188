@import "src/mixins/styles";
.gameSession-border {
  border: 2px solid lightgray;
}
.game-session-tile-component {
  display: flex;
  flex-direction: column;
  width: calc(100%);
  transition: all 200ms ease-in-out;
  padding: 0px 10px 10px 10px;

  .gst-header {
    display: flex;
    flex-direction: column;
    .gst-gamesSet {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 10px;
      margin-bottom: 15px;
      .gst-gamesSet-icon {
        width: 20% !important;
      }
      .gst-gamesSet-stageIcon {
        min-width: 20% !important;
        max-width: 20% !important;
        object-fit: cover;
        min-height: auto;
        transform: none;
        //aspect-ratio: 16 / 9;
      }
    }
    .gst-name-row {
      display: flex;
      .gst-game-name {
        display: flex;
        width: fit-content;
        margin-left: 10px;
      }
      .gst-game-time {
        display: flex;
      }
    }
  }
  .gst-middle {
    display: flex;
    min-height: 100px;
    width: 100%;

    .gst-main-block {
      display: flex;
      flex-direction: column;
      min-width: 200px;
      margin-right: 5px;

      .gst-game-icon {
        display: flex;
        align-items: flex-start;
        height: 100%;
        padding: 5px 0px 5px 0px;

        width: 200px;
        aspect-ratio: 16/9;

        .gst-game-icon-stageIcon {
          transform: none;
        }
      }
    }
    .gst-results-block {
      display: flex;
      min-width: calc((100% - 215px));
      margin: 0px 15px 0px 10px;
    }
  }
  .statistic-table {
    margin-top: 15px;
    width: 100%;
  }

  .gst-stages-view-wraper {
    display: flex;
    width: 100%;
    gap: 5px;
    margin-top: 10px;

    .gst-stages-view-stageIcon {
      display: flex;
      min-width: calc(100% / 6) !important;
      max-width: calc(100% / 5) !important;
      object-fit: cover;
      min-height: auto;
      transform: none;
      &:hover {
        transform: scale(1.5);
        z-index: 1000;
      }
    }
  }
  .events-view-wrapper {
    margin-top: 15px;
  }
  .game-config-wrapper {
    margin-top: 15px;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgb(245, 245, 245);
      border-radius: 5px;
      .title {
        display: flex;
        margin-left: 5px;
      }
      .buttons {
        display: flex;
        align-items: center;
        .openConfigOnNewPage-button {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $button-color;
          border-radius: 50%;
          height: 25px;
          width: 25px;
          svg {
            height: 17px;
            width: 17px;
          }
        }
        .collapser {
          display: flex;
          align-items: center;
          padding: 10px;
          transition: 200ms ease-in-out;
          &.flip {
            svg {
              transition: 200ms ease-in-out;
              transform: scale(1.5);
            }
          }
          svg {
            transition: 200ms ease-in-out;
            transform: scale(1.5) rotate(180deg);
          }
        }
      }
    }
    .content {
      max-height: 300px;
      overflow: auto;
    }
  }
  .gst-footer {
  }
  &.game-session-tile-component-expanded {
    height: fit-content;
    transition: all 200ms ease-in-out;
  }
}
