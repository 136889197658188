@import "src/mixins/styles";

.customer-template-wrapper {
  @include background-style;
  padding: 5px;
  height: initial;
  overflow: hidden;
  .user-editor-left-header {
    width: 100%;
  }
  .content-wrapper {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 85px);

    .middle-wrapper {
      width: 100%;
      height: calc(100vh - 85px);
      background-color: $white-color;
      border: 1px solid $border-color;
      border-radius: 15px;
    }
    .transparent-bg {
      border: 0;
      padding: 0;
      box-shadow: none;
      background: transparent;
    }
    .right-wrapper {
      max-width: 260px;
      height: 100%;
      text-align: center;
      margin-left: 5px;
      //padding: 10px 10px 10px 10px;
    }
  }
}
