@import "src/mixins/styles";


.registration-page-wrapper {
  @include login-bg;
  color: $main-font-color;
  display: flex;
  align-items: center;
  justify-content: center;
  .registration-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .registration-title {
      margin-bottom: 40px;
      font-size: $font-size-xl;
      text-transform: uppercase;
      text-align: center;
    }
    .button-box {
      padding: 30px 50px;
      .customize-button {
        padding: 13px 24px 12px 24px;
        border-radius: 8px;
        background: #FFB300;
        font-size: $font-size-s;
        width: 100%;
      }
      .button:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}

.registration-form {
  margin: auto;
  width: 270px;
  height: 270px;
  input {
    padding: 13px 8px 12px 8px;
    border-radius: 8px;
    background-color: rgb(232, 240, 254);
    margin-bottom: 20px;
    font-size: $font-size-ml;
  }
}

.registration-lable {
  font-size: 16px;
  color: black;
  text-align: center;
}


.link-box,
.login-link {
  text-align: center;
  font-size: 16px;
  color: black;
}

.ok-message {
  margin-bottom: 15px;
  text-align: center;
  color: black;
}
.error-message {
  text-align: center;
  color: red;
}
