.group-types-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  .group-types-tile-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
  }
}
