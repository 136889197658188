@import "src/mixins/styles";

.planOptions-tab-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  font-size: 18px;
  margin-top: 5px;
  gap: 10px;
  .planOptions-tab-columns {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: fit-content;
    gap: 10px;
    align-items: stretch;
    .planOptions-tab-column {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      border: 2px solid $border-color;
      border-radius: 10px;
      padding: 10px;
      .header {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        padding-bottom: 15px;
      }
      &:empty {
        border: none;
      }
    }
  }

  .inputWithTitle {
    display: flex;
    align-items: center;
    width: fit-content;

    .param-title {
      display: flex;
      align-items: center;
      &[disabled] {
        color: gray;
      }
    }
    .param-input {
      display: flex;
      align-items: center;
      min-height: 25px;
      max-height: 25px;
      width: 50px;
      margin-left: 10px;
      margin-bottom: 0px;
      border-radius: 10px;
      padding: 3px;
      background-color: white !important;
    }
  }

  .useForgetRateCheckbox-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    white-space: nowrap;
    margin-left: -12px;
    height: 30px;
    &.usedDefaultValue {
      .MuiCheckbox-root {
        color: lightgray;
      }

      .Mui-checked {
        svg {
          color: lightgray;
        }
      }
    }
  }
}
