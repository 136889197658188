@import "src/mixins/styles";
.inputWithPopup-component {
  width: 200px !important;
  display: flex;
  align-items: center;

  .MuiAutocomplete-root {
    width: 100%;
    .MuiOutlinedInput-notchedOutline {
      border-color: $border-color;
    }

    .MuiOutlinedInput-root {
      border-radius: 25px !important;
      padding: 0 0 0 10px;
      background-color: #fff;
      input:hover {
        outline: none;
      }
      .text-field-play-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        height: 30px;
        width: 30px;
        background: none;
        cursor: pointer;
        svg {
          fill: #2b2b2b;
          width: 25px;
          height: 25px;
        }
      }
      .rec-btn {
        text-align: center;
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
      }
    }
  }

  .MuiTooltip-tooltip {
    font-size: 16px !important;
    min-width: 20px !important;
    max-width: 1000px !important;
  }
}
