.benefits {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &_header {
    display: flex;
    gap: 15px;
    flex-direction: column;

    h3 {
      font-family: "Manrope";
      font-weight: 500;
      color: #374151;
      font-size: 46px;
      line-height: 90%;
      letter-spacing: -1px;
    }

    p {
      font-family: "Manrope";
      font-weight: 400;
      color: #6b7280;
      font-size: 18px;
      line-height: 120%;
    }
  }

  &_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
    flex: 1 0 0;
  }

  &_container {
    width: 336px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__text {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .benefit-header-text-and-icon {
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          width: 64px;
          height: 64px;
        }
        h4 {
          font-family: "Manrope";
          font-weight: 500;
          color: #374151;
          font-size: 24px;
          line-height: 100%;
          letter-spacing: -1px;
        }
      }

      p {
        font-family: "Manrope";
        font-weight: 400;
        max-width: 336px;
        color: #374151;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 19.2px */
      }
    }
  }
}
