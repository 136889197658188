@import "src/mixins/styles";

.login-page-wrapper {
  @include login-bg;
  color: $form-text-color;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 22px;

  .login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .login-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
      font-size: $font-size-xl;
      text-transform: uppercase;
      text-align: center;

      img {
        width: 105px;
        height: 49px;
        object-fit: contain;
      }
    }

    .button-box {
      padding: 30px 50px;
      .customize-button {
        color: $form-btn-color;
        font-size: $font-size-s;
        background: $button-color;
        padding: 13px 24px 12px 24px;
        border-radius: 8px;
        margin-top: 20px;
        width: 100%;
      }

      .button:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}

.login-form {
  margin: auto;
  width: 270px;
  height: 230px;

  input {
    font-size: $font-size-ml;
    padding: 13px 8px 12px 8px;
    border-radius: 8px;
    background-color: rgb(232, 240, 254);
    margin-bottom: 12px; // remove if needed
  }
}

.login-lable {
  margin-bottom: 12px;
  font-size: 16px;
  color: black;
  text-align: center;
}

.link-box {
  text-align: center;
}

.registration-link,
.password-recovery-link {
  display: inline-block;
  margin: 0 10px;
  font-size: 16px;
  color: black;
}

.message {
  margin-bottom: 15px;
  text-align: center;
  color: red;
}
