@import "src/mixins/styles";

.image-editor-wrapper {
  @include background-style;
  align-items: center;
  padding: 5px;
  color: $main-font-color;
  .image-editor-header {
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0;
    .home {
      border-radius: 20px;
      width: 250px;
      margin: 0;
      div {
        margin-left: 10px;
        font-size: $font-size-ml;
      }
      &:hover {
        width: 254px;
        height: 34px;
        margin-bottom: 0.5px;
        transform: scale(1);
      }
      &:hover > .header-title,
      &:hover > .icon-header {
        transform: scale(1.1);
      }
      &:hover > .icon-header {
        margin-right: 10px;
      }
      .header-title,
      .icon-header {
        transition: all 200ms;
      }
    }

    .back {
      display: none;
    }
  }
  .content-wrapper {
    display: flex;
    justify-content: space-between;
    .left-side {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $white-color;
      border: 1px solid $border-color;
      box-sizing: border-box;
      border-radius: 15px;
      width: 260px;
      min-width: 260px;
      margin: 45px 5px 0 0;
      height: calc(100vh - 55px);
      padding: 20px 10px 10px 10px;
      position: relative;
      .title {
        color: $main-font-color;
        font-size: $font-size-m;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
      .edit-images {
        position: absolute;
        left: 10px;
        top: 10px;
        .edit-images-btn {
          span {
            margin: 0;
            width: 15px;
            height: 15px;
          }
        }
      }
      .reload-images {
        position: absolute;
        right: 10px;
        top: 10px;
        .reload-images-btn {
          span {
            margin: 0;
            width: 15px;
            height: 15px;
          }
        }
      }
      input[type="file"] {
        display: none;
      }
      .file-upload {
        @include button;
        font-size: $font-size-s;
        margin-bottom: 20px;
        height: $button-height;
        &:hover {
          transform: scale(1.05);
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
        }
        .button-icon {
          margin-right: 7px;
          height: 16px;
        }
      }
      .generate-image {
        margin-bottom: 20px;
      }
      .fetch-progress {
        position: absolute;
        top: 52px;
        width: 250px;
        height: 33px;
        .progress {
          margin: 10px;
        }
      }
      .image-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        overflow-y: auto;
        @include scroll-styles;
        padding-bottom: 20px;
        .new-image {
          height: 45px;
          margin: 15px;
          cursor: pointer;
          transition: all 200ms;
          &:hover {
            transform: scale(1.1);
            filter: drop-shadow(0px 10px 10px rgb(0, 0, 0, 0.3));
          }
        }
      }
    }

    .middle {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $white-color;
      border: 1px solid $border-color;
      overflow-y: auto;
      border-radius: 15px;
      width: 50%;
      margin-right: 5px;
      height: calc(100vh - 10px);
      padding: 18px 10px 10px 10px;
      min-width: 360px;
      overflow-x: hidden;
      overflow-y: auto;
      .top-wrapper {
        position: relative;
        width: 100%;
        display: flex;
        .title {
          font-size: $font-size-l;
          margin-bottom: 20px;
          color: $main-font-color;
          width: calc(100% - 80px);
        }
      }
      .remove-button {
        display: flex;
        position: absolute;
        right: 0;
        .button-box {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          input[type="file"] {
            display: none;
          }
          .file-upload {
            @include button;
            font-size: $font-size-xs;
            height: $button-height;
            &:hover {
              transform: scale(1.05);
              box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
            }
            .button-icon {
              margin-right: 7px;
            }
          }
        }
      }
      .new-name-wrapper {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
        height: 85px;
        .new-name-box {
          margin-right: 10px;
          input {
            margin-bottom: 0;
          }
          &:last-child {
            margin-right: 10px;
          }
        }
        .id-and-resolution {
          justify-content: space-between;
          max-width: 50%;
          .item-id-wrap {
            display: flex;
            justify-content: flex-start;
            //margin-top: 10px;
            .item-id-title {
              color: $main-font-color;
              margin-right: 10px;
            }
            .item-id {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
          .item-resolution-wrap {
            display: flex;
            .item-resolution-title {
              color: $main-font-color;
              margin-right: 10px;
            }
            .item-resolution {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }
      .tags-wrapper {
        margin-bottom: 10px;
        max-height: 265px;
        .tags-inputs {
          display: flex;
          align-items: flex-end;
          width: 100%;
          height: 60px;
          padding: 0 10px;
          margin-bottom: 10px;
          .tag-search {
            width: 100%;
            margin-right: 10px;
            input {
              @include input;
              padding: 0 10px 0 30px;
              margin-bottom: 0;
              background: url("../../assets/icons/search.svg") $input-search-icon;
            }
          }
          .tag-new {
            width: 100%;
            margin-right: 10px;
            input {
              margin-bottom: 0;
            }
          }
        }
      }
      .added-image-wrapper {
        margin-bottom: 10px;
        background: url("../../assets/images/background.png");
        background-repeat: no-repeat;
        background-size: cover;
        .added-image-content {
          display: flex;
          justify-content: center;
          width: 100%;
          .image {
            display: flex;
            justify-content: center;
            img {
              height: 200px;
              margin: 20px;
            }
          }
        }
        .buttons {
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: center;
          .button-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }
        }
      }
      .customize-button {
        min-width: fit-content;
        height: $button-height;
        font-size: $font-size-xs;
        &:hover {
          transform: scale(1.05);
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
        }
      }
      .fetch-status {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
      }
    }

    .right-side {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $white-color;
      border: 1px solid $border-color;
      box-sizing: border-box;
      border-radius: 15px;
      width: 50%;
      height: calc(100vh - 10px);
      padding: 10px;
      min-width: 170px;
      overflow-y: auto;
      overflow-x: hidden;

      .tabs-block {
        min-height: 50px;
        margin-bottom: 10px;
        border: 1px solid $border-color;
      }
      .tabs-content-block {
        width: 100%;
        height: calc(100% - 60px);
        .universal-game-stage-image-library {
          height: 100%;
        }
      }
    }
  }
}
