@import "src/mixins/styles";

  .item-covers-component {
    display: flex;
    flex-direction: column;
    width: 50%;
    color: $main-font-color;
    font-size: $font-size-s;
    margin-top: 10px;
    &:not(:last-child) {
      margin-right: 10px;
    }
    .item-covers-label {
      text-align: center;
    }
    .item-covers-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 10px;
      height: 45px;
      .splitter {
        margin: 0 5px 0 5px;
      }
      .cover-foreground {
        width: 45px;
        max-width: 45px;
        margin-right: 5px;
      }
      .cover-title {
        margin: 10px;
      }
      .cover-background {
        width: 45px;
        max-width: 45px;
        margin-right: 5px;
      }
      .btn-box {
        display: flex;
        .add-btn,
        .rem-btn {
          width: 40px;
          height: 40px;

          span {
            margin: 3px 0 0 0;
          }
          .plus-icon,
          .rem-icon {
            fill: $white-color;
          }
          .rem-icon {
            height: 20px;
            width: 20px;
          }
        }
        .add-btn {
          &.active {
            background-color: $white-color;
            transform: scale(1.1);
            box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3);
            .plus-icon {
              fill: $button-color;
            }
          }
        }
        .rem-btn {
          background-color: $alert-color;
        }
      }
    }
  }

