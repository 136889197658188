@import "src/mixins/styles";

.stageItem-component {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 70px;
  height: 70px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0 0 0 / 10%);
  overflow: hidden;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  padding: 5px 5px 5px 10px;
  .main-picture {
    max-width: 100%;
    height: 80%;
  }
  .sub-pictures {
    margin-left: 20px;
    height: 60%;
    .sub-picture {
      height: 100%;
      margin-right: 5px;
    }
  }
  .customize-button {
    width: 25px;
    height: 25px;
    z-index: 100;

    &.deleteItemFromLibrary {
      top: 22px;
      right: 5px;
      position: absolute;
    }

    .deleteItemFromLibrary-button-icon {
      width: 12px;
      height: 12px;
      margin-left: 5px;
      margin-top: 2px;
      transform: rotate(45deg);
      path {
        stroke: white;
      }
    }
  }

  &:hover {
    transform: scale(1.02);
  }
  &.active-layer {
    background-color: #95c4e256;
  }
}
