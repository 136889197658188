@import "src/mixins/styles";
.tabs-component {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 14px;
  width: 100%;
  padding: 5px;
  .tabs-list {
    display: flex;
    flex-direction: row;
    gap: 5px;

    .tab {
      display: flex;
      text-align: center;
      cursor: pointer;
      border-radius: 10px;
      overflow: hidden;
      padding: 5px;
      transition: all 0.3s;

      &:hover {
        background: $user-plan-tabs-color-hover;
      }
      &.active {
        color: #fff;
        background: $user-plan-tabs-color;
        outline: 0px;
      }
    }
  }
}
