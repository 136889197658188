@import "src/mixins/styles";

.input-title {
  margin-bottom: 10px;
  font-size: $font-size-m;
  color: $main-font-color;
}
.input-error {
  border-color: #f44336;
}
.input-error-message {
  color: #f44336;
  font-size: 0.875em;
  margin-top: 4px;
}
input {
  color: $main-font-color;
  @include input;
  padding: 0 10px 0 10px;
}
textarea {
  width: 100%;
  border: 0;
  padding: 12px 16px;
  border-radius: 10px;
  background: var(--Gray-100, #F2F3F3);
  color: $main-font-color;
}
