@import "src/mixins/styles";

.customer-header {
  display: flex;
  position: relative;
  width: 100%;
  padding: 10px;
  margin: 0 auto;
  position: relative;
  @include flex-center-between;

  .active-link {
    color: $main-accent-color;
    text-decoration: underline;
    text-underline-offset: 5px;
  }

  .toastClassName {
    background: #fff;
    color: #374151;
  }

  .userEmail {
    position: absolute;
    right: 64px;
    top: 10px;
    color: #374151;
    &:hover {
      color: #ffb300;
    }
  }

  .add-customer-btn-content {
    width: 250px;
    font-size: 16px;
    text-transform: inherit;
  }

  &_links {
    width: 100%;
    justify-content: center;
    display: flex;
    gap: 39px;

    a {
      font-weight: 500;
      text-decoration: none;
      color: #374151;
      text-align: center;
      font-size: $font-size-ml;
      line-height: 120%;

      &:hover {
        color: #ffb300;
      }
    }
  }

  &_features {
    display: flex;
    align-items: center;
    gap: 26px;

    a {
      text-decoration: none;
    }

    &__translation {
      display: flex;
      align-items: center;

      button {
        cursor: pointer;
        border: none;
        background: none;

        &:hover {
          color: #ffb300;
        }
      }
    }

    .logoutButtonAndEmail {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &__feedback {
      cursor: pointer;
      border: 0;
      padding: 13px 24px 12px 24px;
      width: fit-content;
      white-space: nowrap;
      border-radius: 8px;
      background: #ffb300;
      color: #f9fafb;
      font-size: $font-size-ml;
      font-family: "Manrope", sans-serif;
      font-weight: 500;
    }
  }
}

#header-toggle {
  position: absolute;
  right: 32px;
  top: 50px;
  transform: translateY(-50%);
  width: 30px;
  cursor: pointer;
  z-index: 2;
}

#header-toggle span,
#header-toggle span:before,
#header-toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  width: 30px;
  background: #374151;
  position: absolute;
  left: 0;
  display: block;
  content: "";
}

#header-toggle span:before {
  top: -8px;
}

#header-toggle span:after {
  top: 8px;
}

#header-toggle span,
#header-toggle span:before,
#header-toggle span:after {
  transition: all 0.5s ease-in-out;
}

#header-toggle.active span {
  background-color: transparent;
}

#header-toggle.active span:before {
  transform: rotate(45deg);
  top: 0;
}

#header-toggle.active span:after {
  transform: rotate(-45deg);
  top: 0;
}
