@import "src/mixins/styles";

.depsGraph-component {
  width: 100%;
  height: 100%;
  cursor: default;
  display: flex;
  flex-direction: column;

  .depsGraph-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    height: 100%;
    width: 100%;
    .depsGraph-levels {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      overflow: auto;
      .depsGraph-level {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 20px;

        .depsGraph-level-title {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 10px;
          color: $form-text-color;
        }

        .depsGraph-level-groups {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          position: relative;
          .depsGraph-level-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 230px;
            height: 47px;
            min-width: 230px;
            min-height: 47px;
            border-radius: 16px;
            padding: 4px 15px 4px 15px;
            border: 1px solid #e5e6e7;
            background-color: #e5e6e7;

            &.know-color {
              background-color: #8dbc03;
              border: 1px solid #abbd77;
              color: white;
            }
            &.almost-know-color {
              background-color: #98c2071a;
              border: 1px solid #a5c80c;
            }
            &.in-progress-color {
              background-color: #ffcd1c;
              border: 1px solid #ffcd1c;
            }
            &.dimmed {
              opacity: 0.5;
            }
            &:hover {
              cursor: pointer;
            }
            &.selected {
              background-color: $main-accent-color;
              border: 2px solid gray;
            }
            .depsGraph-level-group-dots-and-title {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;
              .depsGraph-level-group-dots {
                display: flex;
                width: 6px;
                .depsGraph-level-group-dots-content {
                  display: flex;
                  flex-direction: column;
                  gap: 1px;
                  &.dontShow {
                    display: none;
                  }
                  .dot {
                    box-sizing: border-box;
                    height: 6px;
                    width: 6px;
                    background-color: #a5c80c;
                    border-radius: 50%;
                    border: 1px solid white;
                    &.know-color {
                      background-color: #8dbc03;
                    }
                    &.almost-know-color {
                      background-color: #8dbc03;
                    }
                    &.in-progress-color {
                      background-color: #ffcd1c;
                    }
                  }
                }
              }
              .depsGraph-level-group-title {
                font-size: 15px;
              }
            }
            .depsGraph-level-group-knCount {
              font-size: 15px;
              color: #4c543a;
            }
          }
        }
      }
    }
  }
}
