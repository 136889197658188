@import "src/mixins/styles";

.assetBundleTile-component {
  display: flex;
  min-width: calc(100%);
  transition: all 200ms ease-in-out;

  .sgt-header {
  }
  .sgt-middle {
    display: flex;
    min-height: 100px;
    min-width: 100%;
    .sgt-main-block {
      display: flex;
      flex-direction: column;
      min-width: calc(30%);
      margin-right: 5px;
      .sgt-assetBundle-name {
        margin-left: 5px;
      }
      .sgt-assetBundle-icon-and-type {
        display: flex;
        align-items: flex-start;
        height: 100%;
        margin-left: 5px;
        padding: 5px 0px 5px 0px;
        .sgt-assetBundle-icon {
          height: 80px;
          aspect-ratio: 16/9;
        }
        .sgt-assetBundle-type-block {
          display: flex;
          flex-direction: column;
          margin-top: 30px;
          align-items: center;
          width: 100%;
          height: 100%;
          .sgt-assetBundle-createdBy {
          }
          .sgt-assetBundle-updatedBy {
          }
        }
      }
    }
    .sgt-knowledges-block {
      display: flex;
      min-width: calc(70%);
      padding-right: 10px;
    }
  }
  .sgt-footer {
  }
}
