@import "src/mixins/styles";

.messages-wrapper {
  @include background-style;
  align-items: center;
  padding: 5px;
  color: $main-font-color;
  .messages-header {
    //margin: 5px;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0;
    .home {
      border-radius: 20px;
      width: 250px;
      margin: 0;
      div {
        margin-left: 10px;
        font-size: $font-size-ml;
      }
      &:hover {
        width: 254px;
        height: 34px;
        margin-bottom: 0.5px;
        transform: scale(1);
      }
      &:hover > .header-title,
      &:hover > .icon-header {
        transform: scale(1.1);
      }
      &:hover > .icon-header {
        margin-right: 10px;
      }
      .header-title,
      .icon-header {
        transition: all 200ms;
      }
    }

    .back {
      display: none;
    }
  }
  .content-wrapper {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    .left-content-wrapper {
      display: flex;
      width: 320px;
      margin-top: 45px;
      background: $white-color;
      border: 1px solid $border-color;
      border-radius: 15px;
    }

    .middle-content-wrapper {
      display: flex;
      flex-direction: column;
      background: $white-color;
      border: 1px solid $border-color;
      border-radius: 15px;
      height: 100%;
      width: 100%;
      min-width: 360px;
      margin-left: 5px;
      padding: 10px;

      .header {
        position: relative;
        display: flex;
        justify-content: center;
        .title {
          color: $main-font-color;
          font-size: $font-size-m;
          text-transform: uppercase;
          margin-bottom: 15px;
        }

        .reload-messagelist-button {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: -5px;
          right: 0px;
          border: none;
          width: 30px;
          height: 30px;
          background: $main-accent-color;
          border-radius: 50%;
          cursor: pointer;
          transition: all 200ms;
          &:hover {
            transform: scale(1.15);
          }
          svg {
            fill: $white-color;
          }
        }
      }

      .message-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid $border-color;
        border-radius: 15px;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100% - 25px);

        .message-box {
          width: 100%;
          margin: 10px;
          padding: 0 15px;

          .message-card {
            display: flex;
            padding: 0;

            .header {
              display: flex;
              gap: 20px;
              width: 100%;
              border-radius: 15px 15px 0 0;
              padding: 10px;
              margin: 0;
              font-size: 18px;
              background-color: $border-color;
              .date {
                display: flex;
                width: fit-content;
                white-space: nowrap;
                font-size: 18px;
                text-align: center;
              }
              .app-version {
                display: flex;
                width: 100%;
                justify-content: left;
                font-size: 18px;
                text-align: left;
              }
              .email {
                display: flex;
                font-size: 18px;
                width: fit-content;
                white-space: nowrap;
                display: inline-block;
                color: green;
                &:hover {
                  text-decoration: underline;
                }
              }
              .unityId {
                display: flex;
                font-size: 18px;
                width: fit-content;
                white-space: nowrap;
                display: inline-block;
                &:hover {
                  text-decoration: underline;
                }
              }
            }

            .content {
              display: flex;
              align-items: center;
              height: fit-content;
              padding: 20px 20px;
              .content-message {
                display: flex;
                flex-direction: column;
                width: 100%;
                .message-text {
                  display: flex;
                  width: 100%;
                  font-size: 16px;
                }
              }
              .deleteMessageButton {
                position: relative;
                top: auto;
                right: auto;
                left: auto;
                
              }
            }
          }
        }
      }
    }
  }
}
