@import "src/mixins/styles";
.profile-page {
  width: 100%;
  border-radius: 15px;
  min-height: 65vh;
  height: calc(100vh - 200px);
  backdrop-filter: blur(14px);
  background: url("../../../assets/images/Login/login_bg.svg") center center no-repeat, rgba(255, 255, 255, 0.9);

  .profile {
    height: 65vh;
    width: 100%;
    gap: 24px;
    align-items: center;

    &__content {
      height: 100%;
      @include flex-between;
    }
  }

  .profile__wrapper {
    .nav {
      padding-bottom: 24px;
    }

    footer {
      padding: 42px;
    }
  }
}
