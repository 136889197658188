@import "src/mixins/styles";

.editor-template-wrapper {
  @include background-style;
  padding: 5px;
  overflow: hidden;
  .user-editor-left-header {
    position: absolute;
    padding-top: 5px;
    padding-left: 5px;
    width: 40px;
    height: calc(100% - 10px);
  }
  .content-wrapper {
    display: flex;
    justify-content: space-between;
    margin-left: 45px;
    height: calc(100vh - 10px);
    .left-wrapper {
      max-width: 260px;
      height: 100%;
      text-align: center;
      padding: 10px 10px 10px 10px;
    }
    .middle-wrapper {
      width: 100%;
      height: calc(100vh - 10px);
      background-color: $white-color;
      border: 1px solid $border-color;
      border-radius: 15px;
      margin-left: 5px;
      padding: 10px 10px 10px 10px;
    }
    .right-wrapper {
      max-width: 260px;
      height: 100%;
      text-align: center;
      margin-left: 5px;
      padding: 10px 10px 10px 10px;
    }
  }
}
