@import "src/mixins/styles";

.knowledge-card {
  position: relative;
  width: 200px;
  min-height: 112.5px;
  border-radius: 10px;
  border: 1px solid $border-color;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  @include round-remove-button;
  padding: 0px 5px 5px 5px;
  background-color: aliceblue;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  .knowledgeCommonData {
    display: flex;
    flex-direction: column;
    .knSound {
      display: flex;
      align-items: center;
      gap: 5px;
      width: 100%;
      height: fit-content;
      padding-top: 5px;
      &-playButton {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 25px;
        width: 25px;
        background-color: $main-accent-color;
        .stopIcon {
          width: 20px;
          height: 20px;
          fill: white;
        }
        .playIcon {
          width: 25px;
          height: 25px;
          fill: white;
          &.disabled {
            fill: gray;
          }
        }
        &:hover{
          transition: all 0.2s ease-in-out;
          transform: scale(1.05);
          box-shadow: 0px 0px 15px rgb(0, 0, 0, 0.15);
        }
        &.disabled {
          background-color: lightgray;
          &:hover{
            transition: none;
            transform:none;
            box-shadow: none
          }
        }
        
      }
      &-soundInfo {
        display: flex;
      }
    }
    .knowledge-items-list {
      display: flex;
      flex-wrap: wrap;
      padding: 5px 0px 5px 0px;
      .knowledge-item {
        display: flex;
        align-items: center;
        justify-items: center;
        height: 58px;
        width: 58px;
        padding: 5px;
        box-sizing: border-box;
        &.selected {
          border: 2px solid $main-accent-color;
          border-radius: 10px;
        }
        img {
          height: 48px;
          width: 48px;
          object-fit: contain;
        }
      }
    }
  }
  .wrongKnowledges {
    margin-top: 5px;
    margin-bottom: 5px;
    .knowledgesSelector-multiple {
      width: 100%;
      min-height: 100%;

      .MuiOutlinedInput-root {
        padding: 5px;

        .MuiChip-label {
          color: $main-font-color;
          font-weight: 500;
        }
      }
    }
  }
  .footer {
    height: 25px;
  }
  .circular-progress {
    position: absolute;
    left: calc(100% / 2 - 50px);
    top: calc(100% / 2 - 50px);
  }

  .customize-button {
    width: 25px;
    height: 25px;
    z-index: 100;

    &.remove {
      bottom: -5px;
      right: -5px;
    }
    &.showWrongKnowledges {
      bottom: -5px;
      left: -5px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      .button-mui-icon {
        width: 20px;
        height: 20px;

        path {
          color: white;
        }
      }
    }
    &.copy {
      bottom: -5px;
      right: 25px;
    }
    .copy-button-icon {
      width: 16px;
      height: 16px;
    }

    &.addToLibrary {
      bottom: -5px;
      left: -5px;
    }
    .addToLibrary-button-icon {
      width: 12px;
      height: 12px;
      margin-left: -2px;
      path {
        stroke: white;
      }
    }

    &.deleteFromLibrary {
      top: 0px;
      right: 0px;
      position: absolute;
    }

    .deleteFromLibrary-button-icon {
      width: 12px;
      height: 12px;
      margin-left: 0px;
      margin-top: -2px;
      transform: rotate(45deg);
      path {
        stroke: white;
      }
    }
  }

  &:hover {
    //transform: scale(1.05);
    box-shadow: 0px 10px 15px rgb(0, 0, 0, 0.15);
  }
}
.active-knowledge-card {
  outline: 5px solid $main-accent-color;
  border: none;
  box-shadow: 0px 10px 15px rgb(0, 0, 0, 0.15);
  //transform: scale(1.05);
}
