@import "src/mixins/styles";

.assetBundleEditor-wrapper {
  @include background-style;
  padding: 5px;
  overflow: hidden;

  .no-assetBundle-content-wrapper {
    display: flex;
    width: calc(100%);
    height: 100%;
    .no-assetBundle-content-left {
      min-width: 305px;
      max-width: 305px;
      height: 100%;
      margin-right: 5px;
    }
    .no-assetBundle-content-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 100%;
      width: 100%;

      .progressFetchingAssetBundle {
        position: relative;
        .progressFetchingAssetBundleLabel {
          position: absolute;
          top: calc(100% / 2);
          width: 100%;
          font-size: 18px;
          text-align: center;
          color: $main-font-color;
        }
      }
    }
  }
  .content-wrapper {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 10px);
    .content-left-wrapper {
      min-width: 305px;
      max-width: 305px;
      height: 100%;

      .card-and-knowledges-wrapper {
        height: calc(100% - 45px);
        margin-top: 45px;
        width: 100%;
        text-align: center;
        padding: 0px;
        .assetBundle-card-wrapper {
          padding: 10px 10px 0px 10px;
        }

        .knowledge-add-btn {
          justify-content: flex-start;
          padding: 5px 10px;
          height: 34px;
          width: 210px;
          margin: 10px auto 10px auto;
          font-size: $font-size-ml;
          span,
          .plus-icon {
            width: 23px;
            height: 23px;
            fill: $white-color;
            margin-right: 30px;
          }
        }

        .knowledges-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: 100%;
          padding: 10px 15px 20px 0;
          overflow-y: auto;
          overflow-x: hidden;
          @include scroll-styles;
          .knowledge-card-with-updown {
            display: flex;
            justify-content: flex-end;
            position: relative;
            width: 100%;
            .customize-button {
              position: absolute;
              width: 25px;
              height: 25px;
              z-index: 100;
            }

            .customize-button.moveUp,
            .customize-button.moveDown {
              background: none;
              box-shadow: none;
              .MuiSvgIcon-root {
                color: $button-color;
              }
              &:hover {
                transform: scale(1.5);
              }
              &:active {
                transform: none;
              }
            }
            .customize-button.moveUp {
              top: 5px;
              left: 5px;
            }
            .customize-button.moveDown {
              bottom: 15px;
              left: 5px;
            }

            .knowledge-card-position {
              margin-bottom: 15px;
              margin-left: 10px;
              width: calc(100% - 35px);
            }
          }
        }
      }
      margin-right: 5px;
    }
    .content-middle-wrapper {
      display: flex;
      overflow-y: auto;
      flex-direction: column;
      width: -webkit-fill-available;
      width: 60%;
      min-width: 745px;
      height: 100%;
      background: $white-color;
      border: 1px solid $border-color;
      box-sizing: border-box;
      border-radius: 15px;
      margin-right: 5px;
      padding: 5px 10px;
      position:relative;
      .toast-position{
        position: absolute;
        top: 0px;
        width: calc(100% - 11px)

      }
      .toastClassName{
        min-height: 30px;
        background-color: rgb(253, 214, 214);
      }

      .scenesBar-wrapper {
        margin-bottom: 5px;
      }
      .buttons-top-block {
        display: flex;
        margin-bottom: 5px;
        .left-buttons,
        .middle-buttons,
        .right-buttons {
          display: flex;
          width: calc(100% / 3);
          .top-block-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border-radius: 10px;
            border: 2px solid $border-color;
            margin: 0px 5px 0px 5px;
            cursor: pointer;
            background-color: $main-accent-color !important;
            transition: all 200ms ease-in-out;
            &.margin-left10{
              margin-left: 10px;
            }
            &.margin-left20{
              margin-left: 20px;
            }
            svg {
              
            }
            &.svgRotate180 {
              svg {
                transform: rotate(0.5turn);
              }
            }
            &:disabled,
            [disabled] {
              cursor: auto;
              background-color: rgb(240, 240, 240) !important;
            }
          }
        }
        .left-buttons {
          
        }
        .middle-buttons {
          justify-content: center;
          align-items: center;
        }
        .right-buttons {
          justify-content: flex-end;
        }
      }
      .mainScreen-wrapper {
        margin-bottom: 10px;
        border-radius: 10px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      }

      .activeItem-and-buttons {
        display: flex;
        align-items: center;
        width: 100%;
        height: fit-content;
        padding: 5px;
        background: $white-color;
        border: 1px solid $border-color;
        box-sizing: border-box;
        border-radius: 15px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        .activeItem-wrapper {
          box-sizing: border-box;
          width: calc(100% - 50px);
          height: calc(100%);
        }

        .activeItem-plus-button {
          width: 35px;
          height: 35px;
          border-radius: 10px;
          border: 2px solid $border-color;
          margin-left: 10px;
          cursor: pointer;
          background-color: white !important;
          transition: all 200ms ease-in-out;
          svg {
            margin-top: 3px;
          }
          &.remove {
            svg {
              transform: rotate(45deg);
            }
          }
          &:disabled,
          [disabled] {
            cursor: auto;

            background-color: rgb(240, 240, 240) !important;
          }
        }
      }
    }
    .content-right-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $white-color;
      border: 1px solid $border-color;
      box-sizing: border-box;
      border-radius: 15px;
      width: 40%;
      min-width: 400px;
      padding: 10px;
      overflow-y: auto;
      .tabs-block {
        min-height: 50px;
        margin-bottom: 10px;
        border: 1px solid $border-color;
      }
      .tabs-content-block {
        width: 100%;
        height: calc(100% - 60px);
      }
    }
  }
}

.assetBundleEditor-header {
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0;
  min-width: 305px;
  max-width: 305px;

  .home {
    border-radius: 15px;
    width: 295px;
    margin: 0;
    div {
      margin-left: 10px;
      font-size: $font-size-ml;
    }
    &:hover {
      width: 298px;
      height: 34px;
      margin-bottom: 0.5px;
      transform: scale(1);
    }
    &:hover > .header-title,
    &:hover > .icon-header {
      transform: scale(1.1);
    }
    &:hover > .icon-header {
      margin-right: 10px;
    }
    .header-title,
    .icon-header {
      transition: all 200ms;
    }
  }

  .back {
    display: none;
  }
}
