.customTooltip-component {
    position: relative;

  .customTooltip {
    display: flex;
    position: absolute;
    top: 10px;
    left: 100%;
    margin-left: 10px;
    padding: 5px;
    white-space: nowrap;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    background-color: #f9f9f9;
    color: #333;
    border-radius: 10px;
    z-index: 1000;
    
    opacity: 0;
    animation: fadeIn 0.3s forwards;
    @keyframes fadeIn {
      to {
        opacity: 1;
      }
    }
  }
}