@import "src/mixins/styles";

.scenesBar-component {
  display: flex;
  overflow: hidden;
  min-height: 115px;
  max-height: 115px;
  padding: 0px 5px 10px 0px;
  border: 1px solid $border-color;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  .sceneCard-list {
    display: flex;
    overflow-x: auto;
    padding: 7px 5px 5px 4px;
    height: 110px;
    .sceneCard-wrapper {
      margin: 0 5px;
      min-width: 160px;
      max-width: 160px;
      height: 90px;
      //aspect-ratio: 16/9;
      &:first-child {
        margin: 0 5px 0 5px;
      }
      &:last-child {
        margin: 0 0 0 5px;
      }
    }
  }
  .plusButton-wrapper {
    display: flex;
    align-items: center;
    .plus-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid $border-color;
      margin: 0px 5px 0px 5px;
      cursor: pointer;
      background-color: $main-accent-color !important;
      transition: all 200ms ease-in-out;
      svg {
      }
      &.svgRotate180 {
        svg {
          transform: rotate(0.5turn);
        }
      }
      &:disabled,
      [disabled] {
        cursor: auto;
        background-color: rgb(240, 240, 240) !important;
      }
    }
  }
}
