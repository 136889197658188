@import "src/mixins/styles";

// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900

.aboutTrial-page {
  * {
    font-family: "Onest", sans-serif !important;
    font-optical-sizing: auto;
    font-style: normal;
  }
}

.aboutTrial-page {
  width: 100%;
  border-radius: 15px;
  min-height: 73vh;
  height: calc(100%);
  backdrop-filter: blur(14px);
  padding: 0px 0px 0px 0px;
  background: url("../../../assets/images/Login/login_bg.svg") center center no-repeat, rgba(255, 255, 255, 0.9);

  @media screen and (max-width: $media-query-l) {
    min-height: 69vh;
    width: 100%;
    margin-left: 0px;
  }
  @media screen and (max-width: $media-query-m) {
    min-height: 63vh;
    background: none;
    width: 100%;
    //padding: 0px 10px 0px 10px;
    //margin-left: -10px;
  }
  @media screen and (max-width: $media-query-s) {
    min-height: 58vh;
    width: 110%;
    //padding: 0px 10px 0px 10px;
    margin-left: -5%;
  }
  @media screen and (max-width: $media-query-xs) {
    min-height: 58vh;
    width: 110%;
    //padding: 0px 10px 0px 10px;
    margin-left: -5%;
  }

  .aboutTrial {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(14px);
    border-radius: 15px;
    min-height: 45vh;
    gap: 60px;
    padding: 0px 0px 0px 0px;
    @media screen and (max-width: $media-query-m) {
      width: 100%;
      padding: 0px;
      gap: 40px;
    }
    overflow: hidden;

    .header-image {
    }

    .telegram-button {
      background-image: linear-gradient(123.55deg, #1d9dde 0%, #0081c3 100%);
      border-radius: 16px;
      flex: 0 auto;
      justify-content: flex-start;
      align-items: center;
      height: 48px;
      width: fit-content;
      padding: 10px 16px 10px 14px;
      display: flex;
      box-shadow: 0 6px 12px rgba(29, 157, 222, 0.5);
      gap: 5px;
      text-decoration: none;
      transition: all 300ms ease-in-out;
      &:hover {
        transform: scale(1.1);
        transition: all 300ms ease-in-out;
      }
      .telegram-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
      }
      .telegram-text {
        color: #fff;
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;

        @media screen and (max-width: $media-query-xs) {
          font-size: 12px;
        }
      }
    }

    .freeAccessBlockAndVideo {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      gap: 40px;
      @media screen and (max-width: $media-query-l) {
        gap: 0px;
      }
      @media screen and (max-width: $media-query-m) {
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 20px;
      }
      .freeAccess-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        width: 60%;
        padding: 0px 100px 0px 100px;
        @media screen and (max-width: $media-query-xl) {
          width: 60%;
          padding: 0px 0px 0px 0px;
          align-items: flex-start;
        }
        @media screen and (max-width: $media-query-l) {
          width: 55%;
          padding: 0px 30px 0px 0px;
          align-items: flex-start;
        }
        @media screen and (max-width: $media-query-m) {
          width: 100%;
          padding: 0px 0px 0px 0px;
          align-items: center;
        }
        .freeAccess-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
          width: 100%;

          .free7Days {
            display: flex;
            flex-direction: column;
            width: 100%;
            color: #374151;
            text-align: left;
            letter-spacing: -1px;
            margin-top: 0;
            margin-bottom: 0;
            font-size: 56px;

            line-height: 95%;
            //text-decoration: none;
            .firstWord {
              color: #f7b804;
            }
            @media screen and (max-width: $media-query-l) {
              font-size: 50px;
              text-align: left;
            }
            @media screen and (max-width: $media-query-m) {
              font-size: 32px;
              text-align: center;
            }
          }
          .invite {
            color: #3c4759;
            text-align: left;
            margin-top: 0;
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 400;
            line-height: 160%;

            .part1 {
            }
            .part2 {
              font-weight: 700;
            }
            .part3 {
            }
            @media screen and (max-width: $media-query-m) {
              text-align: center;
            }
          }
        }
        .telegram-button {
          display: flex;
        }
      }
      .howItWorks {
        display: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 28px;
        font-weight: 600;
        color: #3c4759;
        @media screen and (max-width: $media-query-m) {
          display: flex;
          margin-top: 20px;
        }
      }
      .video-block {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 40%;
        height: 100%;
        padding: 0px 0px 0px 0px;

        @media screen and (max-width: $media-query-m) {
          width: 100%;
          justify-content: center;
          padding: 0px 0px 0px 0px;
        }
        .video-wrapper {
          position: relative;
          min-width: 345px;
          aspect-ratio: 9/16;
          border-radius: 24px;
          overflow: hidden;
          @media screen and (max-width: $media-query-m) {
            border-radius: 24px;
            min-width: 345px;
          }
          @media screen and (max-width: $media-query-s) {
            border-radius: 24px;
            min-width: 320px;
          }
          @media screen and (max-width: $media-query-xs) {
            border-radius: 24px;
            min-width: 280px;
          }
        }

        .video-wrapper video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          @media screen and (max-width: $media-query-s) {
            border-radius: 24px;
          }
        }
      }
      .telegram-button {
        display: none;
      }
      @media screen and (max-width: $media-query-m) {
        .telegram-button {
          display: flex;
          margin-top: 0px;
        }
      }
    }

    .features-block {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      gap: 20px;
      .telegram-button {
        display: none;
      }
      @media screen and (max-width: $media-query-m) {
        align-items: center;
        .telegram-button {
          display: flex;
        }
      }
      .features-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 16px;
        width: 100%;

        @media screen and (max-width: $media-query-m) {
          flex-direction: column;
          width: 100%;
        }
        .features-item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          padding: 32px;
          border-radius: 24px;
          width: 33%;
          @media screen and (max-width: $media-query-m) {
            width: 100%;
          }
          &:nth-child(1) {
            background-color: #fc9651;
            .features-item-icon {
              color: #fc9651;
            }
          }
          &:nth-child(2) {
            background-color: #7b78ff;
            .features-item-icon {
              color: #7b78ff;
            }
          }
          &:nth-child(3) {
            background-color: #a3d320;
            .features-item-icon {
              color: #a3d320;
            }
          }
          .features-item-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 30px;
            max-width: 30px;
            min-height: 30px;
            max-height: 30px;
            border-radius: 50%;
            background-color: #fff;
            font-size: 18px;
            font-weight: 600;
          }
          .features-item-content {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .features-item-title {
              color: white;
              letter-spacing: -1px;
              margin-top: 0;
              margin-bottom: 0;
              font-size: 24px;
              font-weight: 500;
              line-height: 100%;
            }
            .features-item-text {
              color: white;
              margin-top: 0;
              margin-bottom: 0;
              font-size: 16px;
              font-weight: 500;
              line-height: 130%;
            }
          }
        }
      }
    }
    .tryNow-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 260px;
      position: relative;
      border-radius: 24px;
      background-color: #15a9f40d;
      width: 100%;
      padding: 50px 64px 64px 64px;
      gap: 32px;
      @media screen and (max-width: $media-query-m) {
        padding: 30px 30px 30px 30px;
      }
      @media screen and (max-width: $media-query-s) {
        padding: 30px 10px 30px 10px;
        height: 260px;
      }

      .img1 {
        position: absolute;
        z-index: -1;
        top: 0px;
        background-repeat: no-repeat; // Prevents the image from repeating
        background-size: cover; // Ensures the image covers the entire element
        background-position: center; // Centers the image
        @media screen and (max-width: $media-query-s) {
          display: none;
        }
      }
      .img2 {
        display: none;
        position: absolute;
        z-index: -1;
        top: 0px;
        background-repeat: no-repeat; // Prevents the image from repeating
        background-size: cover; // Ensures the image covers the entire element
        background-position: center; // Centers the image
        @media screen and (max-width: $media-query-s) {
          display: flex;
        }
      }

      .tryNow {
        text-align: center;
        font-size: 40px;
        font-weight: 500;

        @media screen and (max-width: $media-query-m) {
          font-size: 32px;
        }
        @media screen and (max-width: $media-query-s) {
          font-size: 28px;
        }
      }
    }
  }
}
