@import "src/mixins/styles";

.administration-wrapper {
  @include background-style;
  align-items: center;
  padding: 5px;
  color: $main-font-color;
  .main-header {
    margin: 0px;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0;
    width: 270px;
    .home {
      border-radius: 20px;
      width: 260px;
      margin: 0;
      div {
        margin-left: 10px;
        font-size: $font-size-ml;
      }
      &:hover {
        width: 265px;
        height: 34px;
        margin-bottom: 0.5px;
        transform: scale(1);
      }
      &:hover > .header-title,
      &:hover > .icon-header {
        transform: scale(1.1);
      }
      &:hover > .icon-header {
        margin-right: 10px;
      }
      .header-title,
      .icon-header {
        transition: all 200ms;
      }
    }

    .back {
      display: none;
    }
  }
  .content-wrapper {
    display: flex;
    gap: 5px;
    height: 100%;
    .left {
      display: flex;
      width: 330px;
      background: $white-color;
      border: 1px solid $border-color;
      border-radius: 20px;
      margin-top: 45px;
    }
    .middle {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $white-color;
      border: 1px solid $border-color;
      border-radius: 20px;
      box-sizing: border-box;
      overflow-y: auto;
      border-radius: 15px;
      width: 100%;
      height: 100%;
      padding: 5px;
      min-width: 360px;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;

      .user-list-area {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 10px 20px;
        min-height: 630px;
        min-width: 1000px;
        width: 100%;
        height: 100%;

        .title-web-user-box {
          position: relative;
          display: flex;
          justify-content: center;
          height: fit-content;
          .title {
            color: $main-font-color;
            font-size: $font-size-m;
            text-transform: uppercase;
            margin-bottom: 15px;
          }
        }

        .web-user-list {
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 1px solid $border-color;
          border-radius: 15px;
          height: 100%;
          width: 100%;
          overflow: hidden;
          padding: 10px;
          .search-box-and-button {
            display: flex;
            justify-content: space-between;
            position: relative;
            width: 100%;
            align-items: center;
            padding: 10px 10px 10px 10px;
            .search-box {
              width: 400px;
            }
            .reload-button {
              position: relative;
              top: 0px;
            }
          }
          .web-users-list {
            @include scroll-styles;
            display: flex;
            flex-direction: column;
            width: 100%;
            overflow-y: auto;
            padding: 10px;
            gap: 10px;
            .web-user {
              display: flex;
              align-items: center;
              gap: 10px;
              .remove-user-button {
                display: flex;
                position: relative;
                top: 0px;
              }

              // .remove-user-icon {
              //   height: 25px;
              //   fill: $main-accent-color;
              // }
            }
          }
        }
      }
    }
  }
}
