@import "src/mixins/styles";
.success-page {
  padding: 20px;
  min-height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);

  h1 {
    font-size: $font-size-l;
    font-weight: 600;
    color: #ffb300;
  }

  p {
    font-size: $font-size-s;
    color: black;
  }
}
