@import "src/mixins/styles";

.gameOptions-tab-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-size: 18px;
    margin-top: 5px;
    .waitForQuestionCheckbox-block {
      width: fit-content;
      white-space: nowrap;
      margin-left: -10px;
    }
    .task-reaction-selector-block {
      display: flex;
      align-items: center;

      padding: 5px 0px;
      .task-reaction-selector-label {
        display: flex;
        width: 200px;
      }
      .task-reaction-selector {
        display: flex;
        margin-left: 10px;
        width: 300px;
        max-width: 300px;
        min-width: 300px;
        min-height: 35px;
        max-height: 35px;
      }
    }
  }
