@import "src/mixins/styles";

.statisticResultsView-component {
  width: 100%;
  height: 100%;

  cursor: pointer;
  display: flex;
  flex-direction: column;
  .filter-row {
    display: flex;
    width: 100%;
    gap: 10px;
    height: max-content;
    align-items: center;
    padding: 5px 15px;
    .timestampScale-block {
      display: flex;
      align-items: center;
      gap: 5px;
      .timestampScale-title {
      }
    }
    .recreate-button {
      min-width: 250px;
      height: 35px;
    }
    margin-bottom: 20px;
  }

  .groups {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 10px;
    gap: 5px;
    padding: 0px 0px 10px 0px;
    overflow: auto;
    .level-block {
      display: flex;
      flex-direction: column;
      height: fit-content;
      gap: 5px;
      padding-bottom: 5px;
      width: 100%;
      .level-header {
        display: flex;
        background-color: lightslategray;
        border-radius: 10px;
        padding: 5px 5px;
        color: white;
        font-weight: bold;

        position: sticky; // Make the header sticky
        top: 0; // Stick to the top of its container
        z-index: 1; // Ensure it stacks above other content
      }
      .group-block {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 0px 0px 5px 20px;
        .group-header {
          display: inline;
          padding: 5px 10px;
          background-color: lightgray;
          border-radius: 10px;
          font-weight: bold;
          font-size: 18px;
          color: $main-font-color-light;
          margin-bottom: 5px;
          margin-top: 0px;
          .group-name {
            &::first-letter {
              text-transform: uppercase;
            }
          }

          &.known-color {
            background-color: $statistic-known-color;
          }
        }
        .knowledge-row {
          display: flex;
          min-height: 25px;
          height: fit-content;
          align-items: center;

          .knowledge-name-block {
            display: flex;
            flex-direction: row;
            margin-left: 20px;
            padding: 3px 0px 3px 5px;
            min-width: 300px;
            max-width: 300px;
            border-radius: 10px 10px 10px 10px;
            border: 1px solid lightgray;
            &.known-color {
              background-color: $statistic-known-color;
            }
            &.knownMinor-color {
              background-color: $statistic-knownMinor-color;
            }
            &.control-color {
              background-color: $statistic-ready-to-control-color;
            }
            &.learn-color {
              background-color: $statistic-ready-to-learn-color;
            }

            .knowledge-name {
              font-weight: bold;
              color: $main-font-color-light;
              &::first-letter {
                text-transform: uppercase;
              }
            }
            .knowledge-progress {
              display: flex;
              flex-direction: row;
              margin-left: 10px;
            }
          }

          .knowledge-elements {
            display: flex;
            flex-direction: row;
            gap: 1px;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-left: 10px;
            .knowledge-element {
              box-sizing: border-box;
              margin-right: 1px;
              margin-left: 1px;
              border-radius: 5px;
              width: 6px;
              height: 20px;
              position: absolute;
              //border: 1px solid gray;
              &.uncontrolled {
                background-color: rgb(255, 217, 0);
              }
              &.controlled {
                background-color: #9bed8d;
              }
              &.controlled-incorrect {
                background-color: #ff4e4e;
              }

              &.color60 {
                background-color: hsl(76, 74%, 48%);
              }

              &.color55 {
                background-color: hsla(57, 75%, 56%);
              }

              &.color50 {
                background-color: hsl(55, 78%, 57%);
              }

              &.color45 {
                background-color: hsl(49, 85%, 50%);
              }

              &.color40 {
                background-color: hsl(41, 91%, 52%);
              }

              &.color35 {
                background-color: hsl(31, 92%, 56%);
              }

              &.color30 {
                background-color: hsl(21, 93%, 58%);
              }

              &.color25 {
                background-color: hsl(12, 94%, 61%);
              }

              &.color20 {
                background-color: hsl(8, 94%, 60%);
              }

              &.color15 {
                background-color: hsl(359, 94%, 61%);
              }

              &.color10 {
                background-color: hsl(359, 94%, 61%);
              }
            }
          }
        }
      }
    }
  }
}
