@import "src/mixins/styles";

.socials-component {
  width: 100%;
  font-family: "Manrope", sans-serif;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include flex-center-column;

  .icons {
    display: flex;
    justify-content: center;
    gap: 25px;
    @media screen and (max-width: 420px) {
      //gap: 10px;
      //flex-direction: column;
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      text-decoration: none;
      color: #ffb300;
      width: 70px;
      img {
        width: 60px;
        height: 60px;
        // @media screen and (max-width: 768px) {
        //   width: 45px;
        //   height: 45px;
        // }
      }
    }
  }
}
