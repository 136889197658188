@import "src/mixins/styles";

.add-game {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: 100px;
  margin-bottom: 25px;
  box-shadow: none;
  margin-right: 0;
  color: $main-font-color;
  background: $white-color;
  border: 1px solid $border-color;
  border-radius: 15px;
  cursor: pointer;
  transition: all 300ms;
  .plus-icon {
    width: 40px;
    height: 40px;
    fill: $placeholder-color;
    margin-bottom: 10px;
  }
  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 28px 27px rgba(0, 0, 0, 0.05), 0px 0px 25px rgba(0, 0, 0, 0.05), 0px 11px 15px rgba(0, 0, 0, 0.06);
  }
   @media screen and (max-width: 1560px) {
     max-width: 1000px;
  }
  @media screen and (max-width: 1360px) {
    max-width: 800px;
  }
  @media screen and (max-width: 1160px) {
    max-width: 700px;
  }

  svg {
    margin: 10px 25px;
  }
  .add-game-title {
    width: calc(100% - 180px);
    font-size: 22px;
    text-align: center;
  }
}
