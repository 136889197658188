@import "src/mixins/styles";
.soundSetList-component {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  .soundSetList {
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;

    gap: 10px;
    padding: 10px;
    .soundSetCard {
      display: flex;
      width: fit-content;
      flex-direction: column;
      background-color: aliceblue;
      border-radius: 10px;
      border: 1px solid $border-color;
      box-sizing: border-box;
      padding: 5px 5px 5px 5px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      .nameRow {
      }
      &:hover {
        box-shadow: 0px 10px 15px rgb(0, 0, 0, 0.15);
      }

      &.selected {
        outline: 3px solid $main-accent-color;
        border: none;
        box-shadow: 0px 5px 10px rgb(0, 0, 0, 0.15);
      }
      &.alarm_noSoundName {
        background-color: rgb(220, 160, 160);
      }
    }
  }
}
