@import "src/mixins/styles";

.knowledgeList-component {
  width: 100%;
  overflow-y: hidden;
  height: 100%;
  .knowledgeList {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    overflow-x: auto;
    overflow-y: auto;
    padding: 10px;
    @include scroll-styles;
    .knowledge {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      position: relative;
      transition: all 200ms;
      .knowledge-main {
        display: flex;
        align-items: center;
        width: 175px;
        margin: 8px;
        object-fit: contain;
        border-bottom: solid 1px #a4a4a4;
        padding-bottom: 5px;
        cursor: pointer;
        
        
      }

      &:hover {
        transform: scale(1.1);
        filter: drop-shadow(0px 4px 5px rgb(0, 0, 0, 0.3));
      }
    }
  }
  .knowledgeList-library {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    overflow-x: auto;
    overflow-y: auto;
    padding: 10px;
    @include scroll-styles;
    .knowledge {
      display: flex;
      
     height: 45px;
      position: relative;
      transition: all 200ms;
      .knowledge-main {
        display: flex;
        align-items: center;
        margin: 0 10px 10px 0;
        cursor: pointer;
        background: #f8fcff;
        padding: 10px;
        border: 1px solid #eaf3fa;
        border-radius: 5px;
        .folder-icon{
          display: flex;
          align-items: center;
          margin-right: 5px;
        }
        .knowledge-name{
          display: flex;
          align-items: center;
          height: 100%;

        }
        
      }
      .knowledge-selected {
        background: $main-accent-color;
        color: white;
      }
      
      &:hover {
        filter: drop-shadow(0px 4px 10px rgb(0, 0, 0, 0.1));
      }
    }
  }
}
