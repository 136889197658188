@import "src/mixins/styles";

.knowledgeGroupEditor-page {
  @include background-style;
  align-items: center;
  padding: 5px;
  color: $main-font-color;
  .knowledgeGroupEditor-header {
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0;
    .home {
      border-radius: 20px;
      width: 300px;
      margin: 0;
      div {
        margin-left: 10px;
        font-size: $font-size-ml;
      }
      &:hover {
        width: 304px;
        height: 34px;
        margin-bottom: 0.5px;
        transform: scale(1);
      }
      &:hover > .header-title,
      &:hover > .icon-header {
        transform: scale(1.1);
      }
      &:hover > .icon-header {
        margin-right: 10px;
      }
      .header-title,
      .icon-header {
        transition: all 200ms;
      }
    }

    .back {
      display: none;
    }
  }
  .content-wrapper {
    display: flex;
    justify-content: space-between;
    .left-content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $white-color;
      border: 1px solid $border-color;
      box-sizing: border-box;
      border-radius: 15px;
      width: 300px;
      min-width: 300px;
      margin: 45px 5px 0 0;
      height: calc(100vh - 55px);
      padding: 0px 5px;
      position: relative;
      .title {
        color: $main-font-color;
        font-size: $font-size-m;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 15px;
        margin-top: 15px;
      }
      .buttons-block {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 20px;

        .newKnowledgeGroup-button {
          margin-left: 10px;
          height: $button-height;
          width: $button-height;
          svg {
            margin-left: 6px;
            margin-top: 2px;
            fill: #ffffff;
          }
        }
      }
      .knowledgeGroupList-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .knowledgeGroupList-list {
          display: flex;
          flex-direction: column;
          gap: 10px;
          overflow-x: auto;
          padding: 10px 10px 10px 0px;
          height: calc(100% - 10px);
          .knowledgeGroupList-level {
            display: flex;
            width: 100%;
            background-color: lightgray;
            padding: 3px 5px 3px 10px;
            border-radius: 7px;
          }
          .knowledgeGroupList-item {
            display: flex;
            width: 100%;
            padding: 0px 0px 0px 10px;
          }
        }
      }
    }

    .middle-content-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      background: $white-color;
      border: 1px solid $border-color;
      box-sizing: border-box;
      border-radius: 15px;
      width: 40%;
      margin-right: 5px;
      height: calc(100vh - 10px);
      min-width: 320px;
      .toast-position {
        position: absolute;
        top: 10px;
        width: calc(100% - 11px);
      }
      .toastClassName {
        min-height: 30px;
      }

      .alert-box {
        width: calc(100% - 20px);
        margin: 5px 20px 10px 20px;

        border-radius: 15px;
        overflow: hidden;
        height: fit-content;
      }
    }

    .right-content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $white-color;
      border: 1px solid $border-color;
      box-sizing: border-box;
      border-radius: 15px;
      width: 60%;
      height: calc(100vh - 10px);
      padding: 10px;
      min-width: 170px;
      overflow-y: auto;
      overflow-x: hidden;
      gap: 10px;

      .title {
        color: $main-font-color;
        font-size: $font-size-m;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 15px;
        margin-top: 0px;
      }
      .knowledgeTagList {
        .chip {
          font-size: 18px;
        }
      }
    }
  }
}
