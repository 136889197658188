.knowledgesPairsEditor-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  gap: 10px;
  .knowledgeTags-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: fit-content;
    .header-label {
      font-weight: 600;
      margin-bottom: 10px;
    }
    .alarm-color {
      background-color: lightcoral !important;
    }
  }
  .similarKnowledgeTags-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    .header-label {
      font-weight: 600;
      margin-bottom: 10px;
    }
    .percentageTags-blocks {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      overflow-x: auto;
      .percentageTags-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        
        .percentageTags-label {
          margin-left: 5px;
        }
      }
    }
  }
}
