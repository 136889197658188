@import "src/mixins/styles";
.game-icon {
  position: relative;
  display: flex;
  img {
    width: 100%;
    border-radius: 10px;
    
  }
  .empty-icon {
    width: 75%;
  }
  .icon-progress {
    position: absolute;
    left: calc(100% / 2 - 30px);
    top: calc(100% / 2 - 30px);
    .MuiCircularProgress-circle {
      stroke: $main-accent-color;
    }
  }
}
