@import "src/mixins/styles";
.new-element-btn {
  border: none;
  background: none;
  transition: all 200ms ease-in-out;
  width: fit-content;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    filter: drop-shadow(0px 28px 27px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.05))
      drop-shadow(0px 11px 15px rgba(0, 0, 0, 0.06));
  }

  .new-element-btn-content {
    svg {
      circle {
        fill: $main-accent-color;
      }
      transition: all 200ms ease-in-out;
    }
  }
  .new-element-btn-content-active {
    svg {
      circle {
        fill: $main-accent-color;
      }
      transform: rotate(45deg);
    }
  }
}
