@import "src/mixins/styles";

.itemPlaceholder-component {
  display: flex;
  flex-direction: column;
  position: absolute;
  border: 4px solid gray;
  border-radius: 50%;
  background-color: aliceblue;
  cursor: pointer;
  &.active {
    background-color: lightgreen;
  }
  .top-box {
    display: flex;
    box-sizing: border-box;
    min-height: 50%;
    height: 50%;
    border-bottom: 1px solid gray;
  }
  .bottom-box {
    box-sizing: border-box;
    display: flex;
    min-height: 50%;
    height: 50%;
    border-top: 1px solid gray;
  }
  .border-box {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-left: -3px;
    margin-top: -3px;
    &.border-box-selected {
      outline: 3px solid rgb(113, 202, 121);
      border-radius: 15%;
      width: calc(100% + 6px);
      height: calc(100% + 6px);
    }
  }
}
