body {
  margin: 0;
  font-family:
    "Manrope",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #e8e8e8;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 5px;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Manrope";
  src:
    local("Manrope Bold"),
    local("Manrope-Bold"),
    url("assets/fonts/Manrope/Manrope-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src:
    local("Manrope ExtraBold"),
    local("Manrope-ExtraBold"),
    url("assets/fonts/Manrope/Manrope-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src:
    local("Manrope Light"),
    local("Manrope-Light"),
    url("assets/fonts/Manrope/Manrope-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src:
    local("Manrope ExtraLight"),
    local("Manrope-ExtraLight"),
    url("assets/fonts/Manrope/Manrope-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src:
    local("Manrope Regular"),
    local("Manrope-Regular"),
    url("assets/fonts/Manrope/Manrope-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src:
    local("Manrope Medium"),
    local("Manrope-Medium"),
    url("assets/fonts/Manrope/Manrope-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}




