@import "src/mixins/styles";

.statistic-events-view-component {
  width: 100%;
  cursor: pointer;
  .no-events-message{
    color: red;
  }
  .events-view-header,
  .events-view-content,
  .events-view-footer {
    display: flex;
  }
  .events-view-header-col1,
  .events-view-header-col2,
  .events-view-col1,
  .events-view-col2,
  .events-view-footer-col1,
  .events-view-footer-col2 {
    border: 1px solid $border-color;
    height: 20px;
    font-size: 14px;
    color: $main-font-color;
    min-width: 100px;
    display: flex;
    align-items: center;
    position: relative;
  }
  .events-view-header-col1,
  .events-view-col1,
  .events-view-footer-col1 {
    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 25%;
  }
  .events-view-header-col1,
  .events-view-header-col2 {
    justify-content: center;
    align-items: center;
    font-weight: 550;
  }
  .events-view-header-col2,
  .events-view-col2,
  .events-view-footer-col2 {
    width: 75%;
  }
  .events-view-col2 {
    .events-view-element {
      border-radius: 5px;
      width: 5px;
      height: 14px;
      border: 1px solid gray;
    }
  }
  .events-view-footer {
    height: 30px;
    .events-view-footer-col1 {
      height: 30px;
    }
    .events-view-footer-col2 {
      position: relative;
      height: 30px;
      .events-view-footer-element {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30px;
        .vertical-line {
          color: #000;
          font-size: 8px;
        }
        .footer-element-text {
          color: #000;
          font-size: 12px;
        }
      }
    }
  }
}
