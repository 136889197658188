.userAchievementCardStart {
  width: 1080px;
  height: 1080px;
  padding: 64px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;

  .top {
    display: flex;
    width: 100%;
    height: fit-content;
    img {
      width: 179px;
    }
  }

  .middle {
    display: flex;
    width: 100%;
    height: 445px;
    gap: 24px;
    .middle-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 486px;
      height: 442px;
      padding: 0px 0px 0px 32px;
      gap: 24px;
      .top {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
        gap: 12px;
        .level-line {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          width: fit-content;
          height: 58px;
          padding: 7px 13px 7px 13px;
          gap: 10px;
          border-radius: 18px;
          background-color: #f3c00b1a;
          .image-container {
            display: flex;
            width: 45px;
            height: 45px;
            align-items: center;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .level-label {
            display: flex;
            align-items: center;
            width: fit-content;
            height: 100%;
            font-family: "Manrope" !important;
            font-size: 31.45px;
            font-weight: 500;
            line-height: 37.68px;
            letter-spacing: -0.022em;
            text-align: left;
            color: #f3c00b;
          }
        }
        .user-block-header {
          font-family: "Manrope" !important;
          font-size: 30px;
          font-weight: 500;
          //line-height: 52.71px;
          text-align: left;
          color: #374151;
        }
        .user-block {
          display: flex;
          width: 100%;
          height: fit-content;
          gap: 10px;
          flex-wrap: wrap;
          .user-name {
            font-family: "Manrope" !important;
            font-size: 44px;
            font-weight: 500;
            line-height: 52.71px;
            text-align: left;
            color: #f3c00b;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .user-action {
            font-family: "Manrope" !important;
            font-size: 44px;
            font-weight: 500;
            line-height: 52.71px;
            text-align: left;
            color: #374151;
          }
        }
      }
      .middle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: fit-content;
        .group-name {
          font-family: "Manrope" !important;
          font-size: 84px;
          font-weight: 500;
          line-height: 81px;
          text-align: left;
          color: #374151;
          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }
    .middle-right {
      display: flex;
      min-width: 442px;
      max-width: 442px;
      height: 442px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .bottom {
    display: flex;
    box-sizing: border-box;
    width: calc(100%);
    min-height: 232px;
    padding: 32px;
    gap: 32px;
    border: 4px solid #f0f1f3;
    border-radius: 32px;
    .battery-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 110px;
      .battery {
        display: flex;
        flex-direction: column;
        width: 110px;
        height: 170px;
        align-items: center;

        .batteryPlus {
          display: flex;
          background-color: #dfe3e9;
          height: 10px;
          width: 34px;
          margin-bottom: -1px;
          border-radius: 11px 11px 3px 3px;
        }
        .batteryBody {
          display: flex;
          box-sizing: border-box;
          flex-direction: column;
          justify-content: space-between;
          border: 5px solid #dfe3e9;
          min-height: 161px;
          height: 161px;
          width: 110px;
          border-radius: 32px;
          padding: 10px 5px;
          gap: 3px;
          .batteryElement {
            display: flex;
            height: 100%;
            width: 100%;
            background-color: #dfe3e9;
            border-radius: 10px;

            &:first-child {
              border-radius: 32px 32px 10px 10px;
            }
            &:last-child {
              border-radius: 10px 10px 32px 32px;
            }
          }
        }
      }
    }
    .chip-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      height: 100%;
      gap: 10px;
      .chip {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
        max-height: 71px;
        height: 71px;
        gap: 12px;
        border-radius: 24px;
        border: 4px solid #e0e2e7;
        padding: 14px 32px 14px 18px;
        .chip-ok {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          border: 6px solid #a4acb9;
        }
        .chip-label {
          font-family: "Manrope" !important;
          font-size: 36px;
          font-weight: 500;
          line-height: 43.13px;
          text-align: left;
          color: #505f76;
          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
