@import "src/mixins/styles";

.layers-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $white-color;
  border: 1px solid $border-color;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  padding: 0px 15px 0px 15px;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  @include scroll-styles;
  .layers-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .layers-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      .drop-delimiter {
        display: flex;
        align-items: center;
        width: 100%;
        height: 20px;
        padding: 5px 0px;
        overflow: hidden;
        .drop-delimiter-content {
          width: 100%;
          height: 100%;

          &.delimiter-drag-over {
            background-color: cadetblue;
          }
        }
      }
      .layer-component {
        .box-layer {
          position: relative;
          display: flex;
          align-items: center;
          height: 70px;
          cursor: pointer;
          width: 100%;
          border-radius: 15px;
          box-shadow: 0px 4px 10px rgba(0 0 0 / 10%);
          overflow: hidden;
          transition: all 200ms ease-in-out;

          .customize-button {
            width: 25px;
            height: 25px;
            z-index: 100;

            &.addToLibrary {
              top: 22px;
              right: 5px;
              position: absolute;
            }

            .addToLibrary-button-icon {
              width: 12px;
              height: 12px;
              margin-left: 5px;
              margin-top: 2px;
              path {
                stroke: white;
              }
            }
          }

          .picture {
            margin: 10px 10px 10px 10px;
            max-width: 100%;
            height: 80%;
          }
          .item-placeholder {
            margin-left: 10px;
            box-sizing: border-box;
            height: 50px;
            width: 50px;
            border: 4px solid gray;
            border-radius: 50%;
            background-color: aliceblue;
          }
          &:hover {
            transform: scale(1.02);
          }
          &.active-layer {
            background-color: #95c4e256;
            //background: #aaccb9;
          }
          &.layer-drag-over {
            background-color: cadetblue;
            //background: #aaccb9;
          }
        }
        .sublayers-wrapper {
          display: flex;
          width: 100%;
          .sublayers-left-box {
            min-width: 40px;
          }
          .sublayers-list {
            width: 100%;
          }
        }
      }
    }
  }
}
