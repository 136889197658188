@import "../../../src/mixins/styles";

.nav-page-wrapper {
  color: $main-font-color;
  display: flex;
  align-items: center;
  justify-content: center;
  @include background-style;
  .menu-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title {
      margin-bottom: 10px;
      font-size: $font-size-xl;
      text-transform: uppercase;
      text-align: center;
    }
    .button-box {
      padding: 20px 40px 20px 40px;
      .customize-button {
        font-size: $font-size-l;
        width: 100%;
      }
      .button:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
