@import "src/mixins/styles";

.game-editor-universal-wrapper {
  @include background-style;
  padding: 5px;
  overflow: hidden;
  .game-editor-universal-header {
    position: absolute;
    padding-top: 5px;
    padding-left: 4px;
    width: 40px;
    height: calc(100% - 10px);
  }
  .no-game-content-wrapper {
    display: flex;
    margin-left: 45px;
    width: calc(100% - 45px);
    height: 100%;
    .no-game-content-left {
      min-width: 260px;
      max-width: 260px;
      height: 100%;
      margin-right: 5px;
    }
    .no-game-content-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 100%;
      width: 100%;

      .progressFetchingGame {
        position: relative;
        //margin-top: 50px;
        .progressFetchingGameLabel {
          position: absolute;
          top: calc(100% / 2);
          width: 100%;
          font-size: 18px;
          text-align: center;
          color: $main-font-color;
        }
      }
    }
  }
  .content-wrapper {
    display: flex;
    justify-content: space-between;
    margin-left: 45px;
    height: calc(100vh - 10px);
    .left-side {
      .stages-box-wrapper {
        min-width: 260px;
        max-width: 260px;
        height: 100%;
        text-align: center;
        padding: 0px;
        .game-card-wrapper {
          padding: 10px 10px 0px 10px;
        }

        .stage-add-btn {
          justify-content: flex-start;
          padding: 5px 10px;
          height: 34px;
          width: 210px;
          margin: 10px auto 0px auto;
          font-size: $font-size-ml;
          span,
          .plus-icon {
            width: 23px;
            height: 23px;
            fill: $white-color;
            margin-right: 30px;
          }
        }
        .stages-title {
          margin-bottom: 10px;
        }
        .stages-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px 0 20px 0;
          overflow-y: auto;
          overflow-x: hidden;
          @include scroll-styles;
          .stage-card-with-updown {
            display: flex;
            position: relative;
            .customize-button {
              position: absolute;
              width: 25px;
              height: 25px;
              z-index: 100;
            }

            .customize-button.moveUp,
            .customize-button.moveDown {
              background: none;
              box-shadow: none;
              .MuiSvgIcon-root {
                color: $button-color;
              }
              &:hover {
                transform: scale(1.5);
              }
              &:active {
                transform: none;
              }
            }
            .customize-button.moveUp {
              top: 5px;
              left: -20px;
            }
            .customize-button.moveDown {
              bottom: 15px;
              left: -20px;
            }
            .stage-card-universal {
              margin-left: 10px;
              width: 200px;
            }
          }
        }
      }
    }
  }
}
