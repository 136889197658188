@import "src/mixins/styles";

.web-user-wrapper {
  color: $main-font-color;
  position: relative;
  display: -ms-inline-grid;
  width: 100%;
  min-height: 60px;

  .button-box {
    justify-content: flex-end;
    display: flex;

    .role-client,
    .role-admin {
      padding: 0 35px 0 0;
    }
  }
  .user {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .web-user-email {
      position: absolute;
      left: 30px;
      font-size: $font-size-m;
    }
  }
}
