@import "src/mixins/styles";

.knowledgeStrengthSetup-component {
  width: 100%;
  height: 100%;
  cursor: default;

  display: flex;
  flex-direction: column;
  .filter-row {
    display: flex;
    width: 100%;
    gap: 10px;
    height: max-content;
    align-items: center;
    padding: 5px 15px;
    .timestampScale-block {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    margin-bottom: 20px;
  }

  .groups {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    margin-left: 10px;
    gap: 5px;
    padding: 0px 0px 10px 0px;

    .level-block {
      display: flex;
      flex-direction: column;
      height: fit-content;
      gap: 5px;
      padding-bottom: 5px;
      width: 100%;
      .level-header {
        display: flex;
        background-color: lightslategray;
        border-radius: 10px;
        padding: 5px 5px;
        color: white;
        font-weight: bold;

        position: sticky; // Make the header sticky
        top: 0; // Stick to the top of its container
        z-index: 1; // Ensure it stacks above other content
      }

      .group-block {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 0px 0px 5px 20px;
        .group-header {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 5px 10px;
          background-color: lightgray;
          border-radius: 10px;
          font-size: 18px;
          font-weight: bold;
          color: $main-font-color-light;
          gap: 5px;
          &.known-color {
            background-color: $statistic-known-color;
          }
          .group-name {
            &::first-letter {
              text-transform: uppercase;
            }
          }
          .setStrength-button {
            min-width: 50px;
            height: 25px;
            span {
              color: black;
            }
            &.know {
              background-color: $statistic-known-color;
            }
            &.know-almost {
              background-color: $statistic-knownMinor-color;
            }
            &.dont-know {
              background-color: $statistic-ready-to-control-color;
            }
          }
        }
        .knowledge-row {
          display: flex;
          min-height: 25px;
          height: fit-content;
          align-items: center;
          gap: 5px;
          .setStrength-button {
            min-width: 50px;
            height: 25px;
            span {
              color: black;
            }
            &.know {
              background-color: $statistic-known-color;
            }
            &.know-almost {
              background-color: $statistic-knownMinor-color;
            }
            &.dont-know {
              background-color: $statistic-ready-to-control-color;
            }
          }
          .knowledge-name-block {
            display: flex;
            flex-direction: row;
            margin-left: 20px;
            padding: 3px 0px 3px 5px;
            min-width: 300px;
            max-width: 300px;
            border-radius: 10px 10px 10px 10px;
            border: 1px solid lightgray;
            &.known-color {
              background-color: $statistic-known-color;
            }
            &.knownMinor-color {
              background-color: $statistic-knownMinor-color;
            }
            &.control-color {
              background-color: $statistic-ready-to-control-color;
            }
            &.learn-color {
              background-color: $statistic-ready-to-learn-color;
            }

            .knowledge-name {
              font-weight: bold;
              color: $main-font-color-light;
              &::first-letter {
                text-transform: uppercase;
              }
            }
            .knowledge-progress {
              display: flex;
              flex-direction: row;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
