@import "src/mixins/styles";

.notification-tab-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: 18px;
  margin-top: 5px;
  gap: 10px;

  .title-block {
    display: flex;
    align-items: center;
    gap: 10px;
    .label {
      width: 120px;
    }
    .input-title {
      margin: 0%;
      min-width: 300px;
      max-width: 300px;
    }
  }
  .body-block {
    display: flex;
    align-items: center;
    gap: 10px;
    .label {
      width: 120px;
    }
    .input-body {
      margin: 0%;
      min-width: 600px;
      max-width: 600px;
    }
    .send-button {
      width: 200px;
    }
  }

  .notifications-list {
    height: 70vh;
    overflow-y: auto;

    @include scroll-styles;
  }

  .notification-card {
    margin-bottom: 10px;
  }

  .showCommunicationRequestCheckbox-block {
    display: flex;
    align-items: center;
    // margin-top: 20px;
    gap: 10px;
    .checkbox {
      padding-left: 0px;
    }
  }
}
