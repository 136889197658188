@import "src/mixins/styles";

.stage-template-card {
  position: relative;
  width: 200px;
  min-height: 112.5px;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: all 200ms;
  cursor: pointer;
  overflow: hidden;
  @include round-remove-button;
  font-size: 0;
  .attentionIcon {
    position: absolute;
    left: 5px;
    top: 5px;
  }
  .customize-button {
    width: 25px;
    height: 25px;
    z-index: 100;

    &.deleteStageFromLibrary {
      top: 0px;
      right: 0px;
      position: absolute;
    }

    .deleteStageFromLibrary-button-icon {
      width: 12px;
      height: 12px;
      margin-left: 0px;
      margin-top: -2px;
      transform: rotate(45deg);
      path {
        stroke: white;
      }
    }
  }

  .stage-bg {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    aspect-ratio: 16 / 9;
    transform: scale(1.33);
    &.no-scale {
      transform: scale(1);
    }
    &.srcSvg {
      object-fit: none;
    }
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 15px rgb(0, 0, 0, 0.15);
  }
}
.active-stage-template-card {
  outline: 5px solid $main-accent-color;
  border: none;
  box-shadow: 0px 10px 15px rgb(0, 0, 0, 0.15);
  transform: scale(1.05);
}
