@import "src/mixins/styles";

.image-list-wrapper {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  .images-title {
    text-align: center;
    color: $main-font-color;
  }
  .image-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    overflow-x: auto;
    overflow-y: auto;
    padding: 10px;
    //height: 100%;
    @include scroll-styles;
    .image {
      display: flex;
      position: relative;
      align-items: center;
      transition: all 200ms;
      max-width: 45px;
      width: 45px;
      margin: 8px;
      position: relative;
      .image-main {
        width: 45px;
        max-width: 45px;
        max-height: 45px;
        object-fit: contain;
        cursor: pointer;
      }

      .img-selected {
        filter: drop-shadow(0px 4px 5px rgb(0, 0, 0, 0.3));
      }

      .delete-button-wrapper {
        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: white;
        border-radius: 50%;
        border: 2px solid lightgray;
        height: 20px;
        width: 20px;
        cursor: pointer;
        svg {
          fill: gray;
          width: 10px;
          height: 10px;
        }
      }

      &:hover {
        .delete-button-wrapper {
          display: flex;
        }
        transform: scale(1.1);
        filter: drop-shadow(0px 4px 5px rgb(0, 0, 0, 0.3));
      }
    }
  }
}
