@import "src/mixins/styles";

.stage-card {
  position: relative;
  width: 200px;
  min-height: 112.5px;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: all 200ms;
  cursor: pointer;
  overflow: hidden;
  @include round-remove-button;
  font-size: 0;

  .sound-icon,
  .usedAsGameIcon-icon {
    position: absolute;
    z-index: 100;
    width: 25px;
    height: 25px;
    margin-top: 10px;

    fill: aqua;
  }
  .sound-icon {
    top: 0px;
    left: 10px;
    background-color: $main-accent-color;
    border-radius: 25px;
    padding: 3px;
    cursor: default;
  }
  .usedAsGameIcon-icon {
    bottom: 10px;
    left: 10px;
  }

  .checkbox {
    position: absolute;
    z-index: 100;
    width: 25px;
    height: 25px;
    margin-top: 10px;
    padding: 0;
    appearance: none;
    outline: none;
    background-color: $bg-color;
    border: 1px solid white;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    }
    &:disabled,
    [disabled] {
      background-color: $placeholder-color;
      opacity: 0.5;
    }
    &:hover {
      outline: none;
    }
    &:checked {
      background: url("../../assets/icons/check.svg") no-repeat center 7px;
      background-size: 15px;
      background-color: $bg-color;
      border: 1px solid white;
    }
  }
  .checkbox.tutorial {
    top: 0px;
    left: 5px;
  }
  .checkbox.useAsGameIcon {
    bottom: 7px;
    left: 5px;
  }

  .customize-button {
    width: 25px;
    height: 25px;
    z-index: 100;

    &.remove {
      top: 0;
    }
    &.copy {
      bottom: 0;
    }
    &.addToLibrary {
      bottom: 0;
      left: 0px;
    }
    .copy-button-icon {
      width: 16px;
      height: 16px;
    }
    .addToLibrary-button-icon {
      width: 12px;
      height: 12px;
      margin-left:-2px;
      path{
        stroke: white;
      } 
    }
  }
  .customize-button.moveUp,
  .customize-button.moveDown {
    background: none;
    box-shadow: none;
    .MuiSvgIcon-root {
      color: $button-color;
    }
    &:hover {
      transform: scale(1.5);
    }
    &:active {
      transform: none;
    }
  }
  .customize-button.moveUp {
    top: 0;
    left: -30px;
    margin-left: 0px;
    margin-top: 5px;
  }
  .customize-button.moveDown {
    bottom: 0;
    left: -30px;
    margin-left: 0px;
    margin-bottom: 5px;
  }
  .stage-bg {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    aspect-ratio: 16 / 9;
    transform: scale(1.33);
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 15px rgb(0, 0, 0, 0.15);
  }
}
.active-stage-card {
  outline: 5px solid $main-accent-color;
  border: none;
  box-shadow: 0px 10px 15px rgb(0, 0, 0, 0.15);
  transform: scale(1.05);
}
