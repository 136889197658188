@import "src/mixins/styles";

.subscription {
  @include flex-center-column;
  width: 100%;
  backdrop-filter: blur(14px);
  padding: 16px;
  border-radius: 15px;

  @media screen and (max-width: $media-query-m) {
    padding: 0;
  }

  &_wrapper {
    @include flex-center-column;
    gap: 16px;

    h1 {
      font-family: "Manrope", sans-serif;
      font-weight: normal;
      color: $form-text-header;
      @media screen and (max-width: $media-query-m) {
        font-size: 20px;
      }
    }

    &__products {
      @include flex-between;
      .autoActive {
        font-size: 20px;
      }
    }
  }
}

.loading-spinner {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  z-index: 1;
}
