@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap");
*,
div {
  box-sizing: border-box;
  font-family: "Rubik", sans-serif ;
  /* font-family: "Onest", sans-serif; */
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
a {
  margin: 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.d-f {
  display: flex;
}

.w-100 {
  width: 100%;
}
