@import "src/mixins/styles";

.new-user-tile-wrapper {
  display: flex;
  width: 100%;
  min-height: 115px;
  max-height: 115px;
  background-color: $white-color;
  border: solid 1px $border-color;
  border-radius: 15px;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 25px;
  transition: all 200ms ease-in-out;
  @media screen and (max-width: 1560px) {
    max-width: 1000px;
  }
  @media screen and (max-width: 1360px) {
    max-width: 800px;
  }
  @media screen and (max-width: 1160px) {
    max-width: 700px;
  }
  &:hover {
    transform: scale(1.01);
    box-shadow: 0px 28px 27px rgba(0, 0, 0, 0.05), 0px 0px 25px rgba(0, 0, 0, 0.05), 0px 11px 15px rgba(0, 0, 0, 0.06);
  }
  .new-user-tile-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    .body {
      display: flex;
      padding: 5px 0px 5px 0px;
      .body-left-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-width: 50%;
        .name-block {
          display: flex;
          align-items: center;
          
          .label {
            //width: 120px;
          }
          .input {
            margin: 0%;
            margin-left: 10px;
            width: 150px;
          }
        }
        .unity-id-block {
          display: flex;
          align-items: center;
          margin-left: 20px;
          .label {
            //width: 120px;
          }
          .input {
            margin: 0%;
            margin-left: 10px;
            width: 150px;
          }
        }
      }

      .body-right-content {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        width: fit-content;
        .create-button {
          margin-right: 10px;
          width: 100px;
        }
      }
    }
  }
}
