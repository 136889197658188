@import "src/mixins/styles";

.correct-combinations-wrapper {
  display: flex;
  align-items: center;
  min-height: 130px;
  max-height: 100%;
  max-width: 140px;
  min-width: 140px;
  padding: 0;
  
  .autocomplete {
    margin: 10px auto;
    width: 95%;
  }
  .combination-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    padding: 0 7px;
    margin-top: 5px;
    .combination {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 2px solid $border-color;
      padding: 2px;
      margin: 3px 0;
      cursor: pointer;
      border-radius: 20px;
      transition: all 500ms;
      width: 100%;
      min-height: 40px;
      &:hover {
        background: rgba(0, 0, 0, 0.08);
      }
      img {
        height: 30px;
        margin: 0 10px 0 10px;
      }
    }
    .combination-active {
      background: rgba(0, 0, 0, 0.08);
      border-radius: 20px;
    }
  }
}
