@import "src/mixins/styles";

.soundsBox-component {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-left: 10px;
    background-color: lightgray;
    border-radius: 10px 10px 0px 0px;
    height: 25px;
    padding-right: 3px;
    width: 100% !important;
    .title {
      display: flex;
      font-weight: 600;
      font-size: 16px;
    }
    .buttons {
      display: flex;

      .header-round-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        width: 20px;
        min-width: 0px;
      }
      span {
        margin-right: 0;
      }
      .delete-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        path {
          fill: white;
        }
      }
    }
  }

  .sounds {
    display: flex;
    flex-direction: column;
    .sound {
      display: flex;
      flex-direction: column;

      border-bottom: 1px solid gray;
      padding-bottom: 10px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
        border: none;
      }
      .first-row {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .activeStatusArrow {
          display: flex;
          align-items: center;
          transform: rotate(90deg);
          min-width: 20px;
          path {
            stroke: none;
          }
          &.active {
            path {
              stroke: $button-color;
            }
          }
        }
        .soundLanguage-selector {
          display: flex;
          margin-right: 5px;
          width: 55px;
          max-width: 55px;
          min-width: 55px;
          min-height: 35px;
          &.alarm-color {
            background-color: lightcoral;
          }
        }

        .soundName-input {
          display: flex;
          margin: 0;
          margin-right: 5px;
          min-width: 230px;
          width: 230px;
          min-height: 35px;
          max-height: 35px;
          background-color: white;
        }
        .soundSelector {
          width: 100%;
          min-width: 150px;
          min-height: 35px;
          max-height: 35px;

          .MuiOutlinedInput-root {
            padding-left: 5px !important;
            min-height: 35px;
            max-height: 35px;
            .round-button {
              margin: 0px;
            }
            .rec-btn {
              margin-top: -2px;
              min-width: 30px !important;
              max-width: 30px !important;

              min-height: 30px !important;
              max-height: 30px !important;
            }

            .round-button-icon {
              min-width: 11px !important;
              min-height: 18px !important;
            }
          }
        }
        .soundEditor-round-button {
          @include button;
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: $button-height;
          min-width: $button-height;
          max-height: $button-height;
          max-width: $button-height;
          margin-left: 5px;
          input[type="file"] {
            display: none;
          }
          &:hover {
            transform: scale(1.05);
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
          }
          .button-icon {
            fill: #ffffff;
            margin-left: -1px;
            &.upload {
              margin-left: -1px;
            }
          }
          span {
            margin: 0;
          }
          .button-icon-mic {
            display: flex;
            fill: #ffffff;
            height: 20px !important;
            width: 20px !important;
          }
        }
      }
    }
    .second-row {
      display: flex;
      padding-left: 55px;
      align-items: center;

      .waveform {
        display: flex;
        width: 230px;
        min-width: 230px;
        height: 50px;
        border: 1px solid $border-color;
        border-radius: 10px;
        padding: 2px;

        canvas {
          width: 226px;
          min-width: 226px;
        }
      }
      .info-col {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        margin-left: 10px;
        gap: 10px;

        .soundDuration-label {
          display: flex;
        }

        .soundAuthor-label {
          display: flex;
        }
      }
    }
  }
}
