@import "src/mixins/styles";

.vertical-header-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;

  .header-usersButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: $main-accent-color;
    border-radius: 50%;

    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
      transform: scale(1.1);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }
    svg {
      height: 20px;
      width: 20px;
      fill: white;
    }

    &.active {
      margin-top: 5px;
      margin-bottom: 5px;
      background-color: white;
      border: 2px solid $main-accent-color;
      transform: scale(1.2);
      box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
      transition: all 0.2s ease-in-out;
      cursor: default;
      svg {
        fill: $main-accent-color;
      }
    }
  }
}
