@import "src/mixins/styles";

.games-sessions-component {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 115px);
  padding: 0px 5px 10px 5px;
  position: relative;
 // overflow: hidden;
  .games-sessions-time {
    position: absolute;
    top: -35px;
    left: 20px;
    font-weight: bold;
    color: $main-font-color;
  }
  .games-sessions-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 10px 5px 5px;
    height: calc(100%);
    width: 100%;
  }
}
