@import "src/mixins/styles";

.assetBundleCard-component {
  position: relative;
  color: #2b2b2b;
  width: calc(100% - 10px);
  min-height: 85px;
  height: fit-content;
  margin: 5px 5px 10px 5px;
  //transition: all 200ms;
  overflow: hidden;
  border: 1px solid $border-color;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  &:hover {
    //transform: scale(1.02);
    box-shadow: 0px 28px 27px rgba(0, 0, 0, 0.05), 0px 0px 25px rgba(0, 0, 0, 0.05), 0px 11px 15px rgba(0, 0, 0, 0.06);
  }
  &:active {
    //transform: scale(1);
    box-shadow: 0px 18px 17px rgba(0, 0, 0, 0.05), 0px 0px 15px rgba(0, 0, 0, 0.05), 0px 5px 15px rgba(0, 0, 0, 0.06);
  }
  .assetBundleCard-content {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    .sound-selector-wrapper {
      width: 100%;
      min-width: 100px;
      margin-bottom: 5px;
      
      .MuiOutlinedInput-root {
        padding-left: 5px !important;
        .rec-btn {
          text-align: center;
          min-width: 25px !important;
          min-height: 25px !important;
          max-width: 25px !important;
          max-height: 25px !important;
        }
        .round-button-icon {
          width: 10px;
          height: 20px;
        }
      }
    }
    .assetBundle-icon-wrapper {
      width: calc(100% - 70px);
      aspect-ratio: 16/9;
      margin-bottom: 10px;
      &.empty-icon {
        border: 1px solid lightgray;
        border-radius: 10px;
      }
    }

    .assetBundle-name {
      font-size: $font-size-m;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 20px);
      overflow: hidden;
      height: 16px;
      padding: 0;
      text-align: center;
      background: none;
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        outline: 0 solid $border-color !important;
      }
    }
  }
  .errors-icon {
    display: flex;
    position: absolute;
    bottom: 20px;
    right: 12px;
  }

  .save-btn {
    right: 10px;
  }
  .cancel-btn {
  }

  .save-btn,
  .cancel-btn {
    position: absolute;
    .btn-content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      span,
      .button-icon {
        margin: 0;
        width: 13px;
        height: 13px;
      }
    }

    &:disabled,
    [disabled] {
      background-color: $placeholder-color;
      opacity: 0.5;
      &:hover {
        transform: scale(1);
        cursor: auto;
      }
    }
    &:hover {
      transform: scale(1);
    }
  }

  .tooltip {
    color: $white-color;
    font-size: 14px;
  }

  &.active-card {
    overflow: hidden;
    outline: 5px solid $main-accent-color;
    //transform: scale(1.02);
    box-shadow: 0px 28px 27px rgba(0, 0, 0, 0.05), 0px 0px 25px rgba(0, 0, 0, 0.05), 0px 11px 15px rgba(0, 0, 0, 0.06);
    position: relative;

    cursor: auto;
    .assetBundle-name {
      font-size: $font-size-s;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      cursor: auto;
      &::placeholder {
        font-size: 11px;
      }
      &:hover {
        outline: 1px solid $border-color !important;
      }
    }
  }
}
