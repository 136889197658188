@import "src/mixins/styles";

.userEditor-component {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  .header-refreshDataButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: $main-accent-color;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    left: 12px;
    top: 5px;
    cursor: pointer;
    &.spin-animation {
      background-color: $main-accent-color-light;
      animation: spin 1s linear infinite;
      transition: all 1s ease-in-out;
      cursor: default;
    }
    &:hover {
      box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
      transform: scale(1.1);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }
    svg {
      height: 25px;
      width: 25px;
      fill: white;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .user-card-block {
    position: absolute;
    left: 5px;
    width: 250px;
    min-height: 42px;
    max-height: 42px;
    z-index: 1;
  }

  .middle-content {
    display: flex;
    flex-direction: column;
    //margin-left: 5px;
    width: 100%;
    height: calc(100%);
    padding-left: 5px;
    .user-profile-block {
      display: flex;
      padding: 0px;
      //margin-left: 5px;
      width: calc(100%);
      height: 100%;
    }
    .tabs-block {
      display: flex;
      padding: 5px;
      margin-left: 255px;
      width: calc(100vw - 310px);
    }
    .planPlus-column,
    .statView-column,
    .userChoice-column,
    .depsGraph-column {
      display: flex;
      height: calc(100% - 50px);
      width: calc(100%);
      padding: 5px;
      margin-top: 5px;
    }

    .columns {
      display: flex;
      margin-top: 5px;
      height: 100%;
      align-items: stretch;
      height: calc(100% - 50px);
      gap: 5px;
      .left-column,
      .right-column {
        display: flex;
        flex-direction: column;
        width: calc(50%);
        height: 100%;
        padding: 0px;
        overflow: hidden;
        .column-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 5px;
          min-height: 55px;
          max-height: 55px;
          .label {
            width: calc(100%);
            text-align: center;
            font-size: 18px;
          }
          .right-button-block {
            margin-right: 10px;
            width: 35px;
            .lock-button {
              height: 35px;
              width: 35px;
              svg {
                height: 20px;
                width: 20px;
                margin-left: 7px;
                fill: white;
              }
              &.locked {
                svg {
                  fill: rgba(255, 0, 0, 50%);
                }
              }
            }
          }
          .left-button-block {
            margin-left: 10px;
            width: 35px;
            .back-button {
              height: 35px;
              width: 35px;
              svg {
                height: 25px;
                width: 25px;
                margin-left: 7px;
                fill: white;
              }
            }
          }
        }
      }
      .left-column {
        &.plan-width {
          width: calc(43.6% - 05px);
        }
      }
      .right-column {
        &.game-selector-width {
          width: calc(56.4% - 5px);
        }
      }
    }
  }
}
