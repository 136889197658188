@import "src/mixins/styles";

.filter-box-wrapper {
  border-bottom: solid 1px $border-color;
  * {
    color: $main-font-color;
  }
  //overflow-y:auto;
  .MuiList-root {
    padding: 0;
    .MuiListItemButton-root {
      &:hover {
        background-color: rgba($color: #000000, $alpha: 0);
      }
      .MuiListItemText-root {
        span {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    .switcher,
    .switcher-open {
      margin-right: 16px;
      svg {
        transition: all 100ms ease-in-out;
      }
    }
    .switcher-open {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
