@import "src/mixins/styles";

.task-items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  .items-blocks-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: calc(100% - 40px);
    overflow-y: auto;
    padding: 0px 5px 0px 0px;
    width: 100%;
    .items-block {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border: 1px solid $border-color;
      padding: 2px;
      margin-bottom: 5px;
      cursor: pointer;
      border-radius: 20px;
      transition: all 500ms;
      width: 100%;
      min-height: 45px;
      &.min-height-fit-content {
        min-height: fit-content;
      }
      
      &.block-selected {
        background: rgba(0, 0, 0, 0.03);
        border-radius: 20px;
      }
      .separator {
        height: 90%;
        border-left: 1px solid lightgray;
        margin-left: 5px;
        margin-right: 5px;
      }
      .active-part-wrapper {
        display: flex;
        width: calc((100% - 60px) / 2);
        flex-direction: column;
        &.width100 {
          width: calc((100% - 60px));
        }
        height: fit-content;
        .active-items-list {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          width: 100%;
          height: fit-content;
          .active-item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            margin: 2px;
            &.item-selected {
              background: rgba(0, 0, 0, 0.08);
              border-radius: 10px;
            }
          }
        }
      }
      .passive-part-wrapper {
        display: flex;
        width: calc((100% - 60px) / 2);
        flex-direction: column;
        height: fit-content;
        .passive-items-list {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          width: 100%;
          height: fit-content;
          .passive-item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            //padding: 5px;
            margin: 2px;
            &.item-selected {
              background: rgba(0, 0, 0, 0.08);
              border-radius: 10px;
            }
          }
        }
      }

      .delete-block-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        padding: 5px;
        border-radius: 50%;
        transform: scale(1.2);
        color: $white-color;
        background-color: $button-color;
        margin-right: 5px;
        
      }
    }
  }
  .bottom-buttons-row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100%;
    padding: 5px 5px 5px 5px;
    .add-block-button {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 20px;
      height: 20px;
      width: 20px;
      margin-top: 5px;
      border-radius: 50%;
      color:$white-color;
      background-color: $button-color;
      transform: scale(1.5);
      cursor: pointer;
      transition: all 200ms ease-in-out;
      &[disabled] {
        background-color: lightgray;
        color: gray;
        border: 2px solid gray;
      }
    }
  }
  img {
    height: 30px;
    margin: 5px 5px 5px 5px;
  }
}
