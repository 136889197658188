@import "src/mixins/styles";

.main-screen {
  display: flex;
  @include round-remove-button;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  aspect-ratio: 16/9;
  z-index: 0;

  .main-screen-bg {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    aspect-ratio: 16 / 9;
    transform: scale(1.33);
    z-index: -100;
  }
  &:focus {
    outline: none;
  }

  .img-active {
    outline: 3px solid rgb(113, 202, 121);
    border-radius: 15%;
    width: fit-content;
  }
  .img-passive {
    outline: 3px solid rgb(129, 142, 202);
    border-radius: 15%;
  }
}

.exit-button-wrapper {
  position: absolute;
  .exit-button-base {
    position: absolute;
  }
  .exit-button {
    top: 11px;
    left: 165px;
    position: absolute;
  }
}
