@import "src/mixins/styles";

.batteries-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .battery-groups {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 20px;

    .battery-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      .battery-group-header {
        display: flex;
        width: 100%;
        justify-content: center;
        text-align: center;
        background-color: $border-color;
        border-radius: 10px;
        font-size: $font-size-ml;
        padding: 5px;
        color: $main-font-color-light;
        font-weight: 600;
      }
      .batteries {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        gap: 10px;
        .battery {
          display: flex;
          flex-direction: column;
          width: 90px;
          min-height: 200px;
          height: 200px;
          border: 1px solid lightgray;
          border-radius: 10px;
          &.known {
            background-color: rgb(192, 144, 238);
          }
          .battery-name {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            color: $main-font-color;
            white-space: wrap;
            text-align: center;

            width: 100%;
          }
          .battery-body {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: calc(100% - 40px);
            margin: 0px 10px 10px 10px;
            border-radius: 10px;
            border: 1px solid lightgray;
            overflow: hidden;
            position: relative;
            background-color: white;
            .battery-levels {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 100%;
              position: absolute;
              top: calc(100% / 2 - 50px);
              color: $main-font-color;
              font-weight: 400;
            }
            .battery-delta-fill {
              display: flex;
              &.green {
                background-color: green;
              }
              &.light-green {
                background-color: lightgreen;
              }
              &.light-red {
                background-color: lightcoral;
              }
            }
            .battery-start-fill {
              display: flex;
              &.green {
                background-color: green;
              }
              &.light-green {
                background-color: lightgreen;
              }
            }
          }
        }
      }
    }
  }
}


// .battery {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 100%;
// }
// .battery-top {
//   display: flex;
//   width: 34px;
//   height: 10px;
//   border-radius: 11px 11px 4px 4px;
//   background-color: #dfe3e9;
// }
// .battery-body {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   box-sizing: border-box;
//   width: 110px;
//   height: 166px;
//   gap: 3.67px;
//   border-radius: 32px;
//   border: 5.5px solid #dfe3e9;
//   margin-top: -2px;
//   background-color: #a3d320;
// }