@import "src/mixins/styles";

.universal-game-stage-component {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  margin-left: 5px;
  .left-box-wrapper {
    width: -webkit-fill-available;
    width: 60%;
    min-width: 745px;
    height: 100%;
    background: $white-color;
    border: 1px solid $border-color;
    box-sizing: border-box;
    border-radius: 15px;
    margin-right: 5px;
    padding: 10px 0px;
    .stage-common-content {
      @include scroll-styles;
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0px 10px;
      border-radius: 15px;

      .game-main-screen {
        position: relative;
        margin-bottom: 10px;
        width: 100%;
      }

      .task-editor-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        //margin-bottom: 10px;
        .task-editor-additional-wrapper {
          display: flex;
          flex-direction: column;
          padding: 5px;
          margin-bottom: 10px;

          .task-controlled-knowledge-block {
            display: flex;
            width: 100%;
            align-items: center;
            margin-bottom: 10px;
            .task-controlled-knowledge-label {
              display: flex;
              align-items: center;
            }
            .task-controlled-knowledge-input {
              margin: 0px 0px 0px 10px;
              width: 200px;
              background-color: white;
            }
          }
          .task-reactions-block {
            display: flex;
            flex-direction: column;
            .task-reaction-selector-block {
              display: flex;
              align-items: center;
              margin-right: 10px;
              margin-bottom: 10px;
              .task-reaction-selector-label {
                display: flex;
                align-items: center;
              }
              .task-reaction-selector {
                display: flex;
                align-items: center;
                margin-left: 5px;
                width: 200px;
                height: 35px;
              }
            }
          }
        }
        .task-editor-top-wrapper {
          display: flex;
          width: 100%;
          margin-bottom: 10px;
          .collapser {
            display: flex;
            align-items: center;
            padding: 10px;
            transition: all 200ms ease-in-out;
            &.flip {
              svg {
                transition: all 200ms ease-in-out;
                transform: scale(1.5);
              }
            }
            svg {
              transition: all 200ms ease-in-out;
              transform: scale(1.5) rotate(180deg);
            }
          }
          .task-trigger-block {
            display: flex;
            justify-content: left;
            align-items: center;
            min-width: 390px;
       
            .outline-check {
              width: 50px;
              min-width: 50px;
            }
            .task-trigger-label {
              text-align: right;
              font-weight: 600;
              color: $main-font-color;
            }
            .autocomplete {
              margin: 0 0 0 15px;
              width: 345px;
              min-width: 150px;
            }
          }

          .task-type-block {
            display: flex;
            align-items: center;
            width: fit-content;
            margin-left: 10px;
            .task-type-label {
              white-space: nowrap;
            }
            .type-button {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 5px;
              margin-left: 10px;
              width: 50px;
              height: 40px;
              border-radius: 10px;
              color: $white-color;
              background-color: $button-color;
              cursor: pointer;
              transition: all 200ms ease-in-out;
              &.selected {
                border: 3px solid gray;
              }
            }
          }
          .tasks-tabs-block {
            display: flex;
            justify-content: left;
            align-items: center;
            margin-left: 0px;
            width: 100%;
            .tab-button {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 5px;
              margin-left: 5px;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              cursor: pointer;
              color: $white-color;
              background-color: $button-color;
              &.selected {
                border: 3px solid gray;
              }
            }
          }
          .task-add-delete-button-block {
            display: flex;
            align-items: center;
            width: fit-content;
            .task-add-delete-button {
              width: 35px;
              height: 35px;
              margin: 0 5px;
              border-radius: 10px;
              border: 2px solid $border-color;
              cursor: pointer;
              transition: all 200ms ease-in-out;
              background-color: white !important;
              transition: all 200ms ease-in-out;
              svg {
                margin-top: 3px;
              }
              &.remove {
                svg {
                  transform: rotate(45deg);
                }
              }
              &:disabled,
              [disabled] {
                cursor: auto;
                background-color: rgb(240, 240, 240) !important;
              }
            }
          }
        }
        .task-editor-middle-wrapper {
          display: flex;
          width: 100%;
          min-height: 200px;
          min-height: 365px;
          .item-editor {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            margin: 0 0px 0px 0;
            padding: 0;
            width: 50%;
            min-width: 495px;
            min-height: 100%;
            .item-active-wrapper {
              width: 100%;
              min-width: 450px;
              height: 100%;
            }
            .item-active-buttons-block {
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 20px 0px 20px 0px;
              height: 100%;
              width: 45px;
              .add-remove-item-button {
                width: 35px;
                height: 35px;
                border-radius: 10px;
                border: 2px solid $border-color;
                cursor: pointer;
                background-color: white !important;
                transition: all 200ms ease-in-out;
                svg {
                  margin-top: 3px;
                }
                &.remove {
                  svg {
                    transform: rotate(45deg);
                  }
                }
                &:disabled,
                [disabled] {
                  cursor: auto;

                  background-color: rgb(240, 240, 240) !important;
                }
                &.add-pair-button {
                  //margin-bottom: 120px;
                  &.pair-mode {
                    background-color: $main-accent-color !important;
                  }
                }
                &.remove-from-block-button {
                }
              }
            }

            .disabled {
              display: none;
            }
          }
          .task-items {
            width: 50%;
            margin-left: 10px;
            min-height: 100%;
          }
        }
      }
    }
  }
  .right-box-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white-color;
    border: 1px solid $border-color;
    box-sizing: border-box;
    border-radius: 15px;
    width: 40%;
    min-width: 400px;
    padding: 10px;
    overflow-y: auto;
    .tabs-block {
      min-height: 50px;
      margin-bottom: 10px;
      border: 1px solid $border-color;
    }
    .tabs-content-block {
      width: 100%;
      height: calc(100% - 60px);
      .universal-game-stage-image-library {
        height: 100%;
      }

      .layers-block {
        height: calc(100% - 50px);
        .layers-block-top-bar {
          margin-bottom: 10px;
          width: 100%;
          display:flex;
          justify-content: flex-end;

          .button-style {
            @include game-button;
            height: 25px;
            min-height: 25px;
            width: fit-content;
            margin-right: 15px;
            background-color: #95c4e256
          }
        }
      }
    }
  }
}
