@import "src/mixins/styles";

.reloadButton-component {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -5px;
  left: 0px;
  border: none;
  width: 30px;
  height: 30px;
  background: $main-accent-color;
  border-radius: 50%;
  cursor: pointer;
  transition: all 200ms;
  &:hover {
    transform: scale(1.15);
  }
  svg {
    fill: $white-color;
  }
}
