@import "src/mixins/styles";
.unityUsersPlot-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  .periodSelector {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;

    .period {
      display: flex;
      width: fit-content;
    }
  }
  .legend {
    margin-left: 25px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    .legend-item {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      .legend-line {
        width: 20px;
        height: 4px;
        &.legend-color-withStat {
          background-color: green;
        }

        &.legend-color-all {
          background-color: blue;
        }
        &.legend-color-activeCreatedEarlie {
          background-color: red;
        }
        &.legend-color-active {
          background-color: black;
        }
      }
    }
  }

  .additional-statistics {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-left: 20px;
    .statistic-item {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .statistic-title {
        font-size: 14px;
        font-weight: 500;
      }
      .statistic-value {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}
