@import "src/mixins/styles";

.userChoice-tab {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: hidden;
  .userChoice-itemsList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .userChoice-item {
      display: flex;
      gap: 10px;
      width: 200px;
      height: 100px;

      .userChoice-item-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .userChoice-item-count {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;
        font-size: 20px;
        font-weight: 550;
      }
    }
  }
}
