@import "src/mixins/styles";

.unity-user-list-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  .unity-user-list-title {
    font-size: 20px;
    margin-right: 5px;
  }

  .unity-user-list-value {
    font-size: 15px;
  }

  .unity-user-list-sort {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.unityUsers-leftContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .checkbox-showGrouped {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
}

  .progressFetchingMore {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

.unity-user-list-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 50px);

  &.justify-content-center {
    justify-content: center;
  }
  .progressFetchingList {
    position: relative;
    .progressFetchingListLabel {
      position: absolute;
      top: calc(100% / 2 - 9px);
      width: 100%;
      font-size: 18px;
      text-align: center;
      color: $main-font-color;
    }
  }
  .new-element-button {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .new-user-tile {
    width: calc(100% - 60px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .users-list-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    padding: 0px 10px 0px 5px;
    gap: 0;
    .users-group {
      display: flex;
      flex-direction: column;
      border: 2px solid $main-accent-color;
      border-radius: 10px;
      gap: 20px;
      padding: 5px;
    }

    .defaultUserTile {
      background-color: $user-plan-tabs-color;
    }
    .linkedUserTile {
      background-color: rgb(240, 250, 240);
    }
    .pinnedUserTile {
      background-color: rgb(250, 250, 230);
    }
  }
}
.unity-users-right-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .buttons-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    .bar-usersButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      background-color: $main-accent-color;
      border-radius: 50%;

      cursor: pointer;

      &.disabled {
        background-color: lightgray;
        cursor: default;
      }

      &:hover {
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
        transform: scale(1.1);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        &.disabled {
          box-shadow: none;
          transform: none;
          cursor: default;
        }
      }
      svg {
        height: 20px;
        width: 20px;
        fill: white;
      }
    }
  }
}

.highlighted .MuiSelect-select {
  color: $main-accent-color;
}

.highlighted {
  svg {
    color: $main-accent-color;
  }
}
