.dashboard-user-details {
  .userEditor {
    height: calc(100% - 85px);
    .middle-content {
      .tabs-block {
        width: calc(100% - 255px);
      }
    }
  }

  .toastClassName {
    background: #fff;
    color: #374151;
  }

  .account-details-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .account-details-label {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .account-details-title {
        margin-left: 10px;
        font-size: 20px;
      }
    }
    .account-details-tabs {
      width: initial;
    }
  }
}
