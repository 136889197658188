@import "src/mixins/styles";

.knowledgeLibrary-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .filter-wrapper {
    margin-bottom: 10px;
    max-height: 50%;
    .filter-inputs {
      
      .filter-search {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 35px;
        input {
          @include input;
          height: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          
          background: url("../../assets/icons/search.svg") $input-search-icon;
          padding-left: 30px;
          margin-bottom: 0;
        }
      }
    }
  }
}
