@import "src/mixins/styles";

.stageLibrary-component {
  height: 100%;
  .stageLibrary-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
    .stageLibrary-list {
      display: flex;
      flex-wrap: wrap;
      overflow-x: auto;
      overflow-y: auto;

      justify-content: flex-start;
      //height: 100%;
      width: 100%;
      @include scroll-styles;
      .stageLibrary-element {
        min-height: fit-content;
        height: fit-content;
        min-width: 30%;
        max-width: 30%;
        aspect-ratio: 16/9;
        border-radius: 5px;
        margin: 5px;
      }
    }
  }
}
