@import "src/mixins/styles";

.badge {
  font-size: 14px;
  background-color: $game-group-tile-background-color;
  color: $badge-color;
  display: inline-block;
  // padding: 2px 12px;
  padding: 5px 9px;
  border-radius: 9px;
  margin-left: 6px;
  font-size: $font-size-xs;
}
