@import "src/mixins/styles";

.selector-game-tile-component {
  display: flex;
  flex-direction: column;
  width: calc(100%);
  transition: all 200ms ease-in-out;
  .sgt-header {
  }
  .sgt-game-name {
    margin-left: 5px;
  }
  .sgt-middle {
    display: flex;
    min-height: 100px;
    width: 100%;
    .sgt-main-block {
      display: flex;
      flex-direction: column;
      width: 40%;
      justify-content: center;
      .sgt-game-icon-and-type {
        display: flex;
        align-items: flex-start;
        margin-left: 5px;
        padding: 5px 0px 5px 0px;
        .sgt-game-icon {
          width: 100%;
          aspect-ratio: 16/9;
        }
      }
    }
    .sgt-knowledges-block {
      display: flex;
      margin-left: 20px;
      min-width: calc(60% - 45px);
      padding-right: 10px;
      margin-bottom: 10px;
    }
    .sgt-buttons-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 55px;
      justify-content: center;
      .check-button {
        border: 2px solid rgb(170, 170, 170);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        width: 35px;
        height: 35px;
        span {
          margin-top: 1px;
          margin-left: 8px;
          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
      .remove-button {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          //margin-top: 1px;
          margin-left: 6px;
          svg path {
            fill: rgb(255, 255, 255);
          }
        }
      }
    }
  }
  .sgt-footer {
  }
  &.selector-game-tile-component-expanded {
    min-height: 500px;
    max-height: 500px;
    transition: all 200ms ease-in-out;
  }
}

.game-tile-component {
  display: flex;
  width: calc(100%);
  transition: all 200ms ease-in-out;

  .sgt-header {
  }
  .sgt-middle {
    display: flex;
    min-height: 100px;
    width: 100%;
    .sgt-main-block {
      display: flex;
      flex-direction: column;
      min-width: calc(30%);
      margin-right: 5px;
      .sgt-game-name {
        margin-left: 5px;
      .sgt-game-createdBy {
        padding-top: 4px;
        color: $placeholder-color;
          }
      }
      .sgt-game-icon-and-type {
        display: flex;
        align-items: flex-start;
        height: 100%;
        margin-left: 5px;
        padding: 5px 0px 5px 0px;
        .sgt-game-icon {
          height: 80px;
          aspect-ratio: 16/9;
        }
        .sgt-game-type-block {
          display: flex;
          flex-direction: column;
          margin-top: 30px;
          align-items: center;
          width: 100%;
          height: 100%;
        }
      }
    }
    .sgt-knowledges-block {
      display: flex;
      min-width: calc(70%);
      padding-right: 10px;
    }
  }
  .sgt-footer {
  }
}
