@import "src/mixins/styles";
.bindedBundleKnowledges-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;

  .bundlesKnowledges-filter-box {
    display: flex;
    padding: 5px 10px;
    width: 100%;
    .searchValue-input {
      width: 100%;
    }
  }

  .bindedBundleKnowledges-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 0px;

    .splitter {
      min-width: 100%;
      min-height: 5px;
      background-color: lightgray;
      margin-top: 0px;
      margin-bottom: 20px;
      border-radius: 5px;
    }
    .bindedBundleKnowledge-card {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      width: 100%;
      border-radius: 10px;
      border: 1px solid $border-color;
      box-sizing: border-box;
      padding: 5px 5px 5px 5px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      color: $main-font-color;
      font-size: 14px;
      background-color: rgb(240, 240, 240);
      position: relative;
      &.active {
        background-color: aliceblue;
      }
      .selectIcon {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 0px;
        right: 5px;
        fill: lightgray;
        cursor: pointer;
        &.active {
          fill: $main-accent-color;
        }
      }

      .bundle-name-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 5px;

        .label {
          display: flex;
          font-weight: 600;
        }
        .bundle-name {
          display: flex;
          width: 100%;
        }
      }

      .knowledge-items-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        padding: 5px 0px 5px 0px;
        .knowledge-item {
          display: flex;
          align-items: center;
          justify-items: center;
          height: 95px;
          width: 95px;
          padding: 5px;
          box-sizing: border-box;
          &.selected {
            border: 2px solid $main-accent-color;
            border-radius: 10px;
          }
          img {
            height: 85px;
            width: 85px;
            object-fit: contain;
          }
        }
      }

      .circular-progress {
        position: absolute;
        left: calc(100% / 2 - 50px);
        top: calc(100% / 2 - 50px);
      }

      &:hover {
        box-shadow: 0px 10px 15px rgb(0, 0, 0, 0.15);
      }
    }
    .active-knowledge-card {
      outline: 5px solid $main-accent-color;
      border: none;
      box-shadow: 0px 10px 15px rgb(0, 0, 0, 0.15);
    }
  }
}
