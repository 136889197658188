@import "src/mixins/styles";

.planElementTile-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  border: 2px solid $border-color;
  border-radius: 15px;
  padding: 5px 5px 0px 5px;
  position: relative;

  &.subElement {
    background-color: aliceblue;
    padding: 5px 0px 5px 5px;
    .rightButtonBlock {
      left: 5px;
      right: auto;
    }
  }

  &.color-knownElement {
    background-color: rgb(230, 250, 230) !important;
  }
  &.color-dragOver {
    transition: 200ms all;
    background-color: lightgray;
  }
  .tile-header {
    display: flex;
    width: 100%;
    color: $main-font-color;
    justify-content: center;
    margin-bottom: 5px;
  }

  .rightButtonBlock {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    .plusButton,
    .minusButton,
    .clearButton,
    .checkButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid $border-color;

      cursor: pointer;
      background-color: $main-accent-color !important;
      transition: all 200ms ease-in-out;
      svg {
        fill: $white-color;
        width: 18px;
        height: 18px;
      }
      &:disabled,
      [disabled] {
        cursor: auto;
        background-color: rgb(240, 240, 240) !important;
        svg {
          fill: $main-accent-color;
        }
      }
    }
  }
  .middle {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    gap: 5px;
    .arrowDownIcon {
      position: absolute;
      top: 5px;
      left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid $border-color;
      cursor: pointer;
      background-color: $main-accent-color !important;
      transition: all 200ms ease-in-out;
      &.rotate180 {
        transform: rotate(180deg);
      }
      svg {
        fill: $white-color;
        transform: scale(1);
      }
    }
    .tagList {
      display: flex;
      width: 100%;
      min-height: 45px;
      height: 100%;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      overflow-y: auto;
      margin-bottom: 5px;
      gap: 5px;
      padding: 0px 5px;

      .chip {
        height: 25px;
        width: fit-content;
        border: 1px solid rgba(0, 0, 0, 0.26);
        box-sizing: border-box;
        background-color: rgb(225, 225, 225);
        &.deleted {
          background-color: rgb(253, 20, 20);
          color: white;
          font-weight: bold;
        }
        &.doesntHaveSoundSet {
          border: 2px solid rgb(253, 20, 20);
        }
        &.learn-color {
          background-color: $statistic-ready-to-learn-color;
        }
        &.control-color {
          background-color: $statistic-ready-to-control-color;
        }
        &.known-color {
          background-color: $statistic-known-color;
        }
        &.knownMinor-color {
          background-color: $statistic-knownMinor-color;
        }
        .MuiChip-label {
          color: $main-font-color;
          padding: 0px 7px 0px 7px;
        }
        .MuiChip-deleteIcon {
          margin: 0px;
        }
      }
    }

    .subElementList {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding-left: 20px;
    }

    .checkResultsBlock {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      height: fit-content;
    }
  }
}
