@import "src/mixins/styles";

.image-library-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  //height: calc(100vh - 100px);
  .tags-wrapper {
    margin-bottom: 10px;
    max-height: 50%;
    .tags-inputs {
      .tag-search {
        display: flex;
        flex-direction: column;
        align-items: center;
        input {
          display: flex;
          justify-content: center;
          flex-direction: column;
          @include input;
          background: url("../../assets/icons/search.svg") $input-search-icon;
          padding-left: 30px;
          margin-bottom: 0;
        }
      }
    }
  }
}
