@import "src/mixins/styles";

.item-wrapper {
  .zindex-buttons-wrapper {
    display: none;
  }
  &:hover > .zindex-buttons-wrapper {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    .zindex-top,
    .zindex-bottom {
      width: 30px;
      height: 20px;
      background-color: $main-accent-color;
      text-align: center;
      border: solid 1px $border-color;
      cursor: pointer;
      &:hover p {
        color: $white-color;
        user-select: none;
      }
    }
    .zindex-top {
      border-radius: 10px 10px 0 0;
      & p {
        margin-top: 1px;
      }
    }
    .zindex-bottom {
      border-radius: 0 0 10px 10px;
      & p {
        margin-top: 2px;
      }
    }
  }
  .show-remove-btn {
    display: block;
    .remove-btn {
      position: absolute;
    }
  }
  img {
    &:hover {
      filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.8));
    }
  }
  .outlined {
    --stroke-pos: 2px;
    --stroke-neg: -2px;

    filter: drop-shadow(var(--stroke-pos) 0 0 var(--stroke-color)) drop-shadow(var(--stroke-neg) 0 var(--stroke-color))
      drop-shadow(0 var(--stroke-pos) 0 var(--stroke-color)) drop-shadow(0 var(--stroke-neg) 0 var(--stroke-color))
      drop-shadow(var(--stroke-pos) var(--stroke-pos) 0 var(--stroke-color))
      drop-shadow(var(--stroke-pos) var(--stroke-neg) 0 var(--stroke-color))
      drop-shadow(var(--stroke-neg) var(--stroke-pos) 0 var(--stroke-color))
      drop-shadow(var(--stroke-neg) var(--stroke-neg) 0 var(--stroke-color)) !important;
  }
}
//   .img-active {
//     outline: 3px solid rgb(113, 202, 121);
//     border-radius: 15%;
//     width: fit-content;
//   }

//   .img-passive {
//     outline: 3px solid rgb(129, 142, 202);
//     border-radius: 15%;
//   }
