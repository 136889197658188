@import "src/mixins/styles";

.footer {
  @include flex-column;
  gap: 10px;
  position: relative;
  width: 100%;
  padding: 0px 64px 10px 64px;
  max-width: 1440px;
  margin: 0 auto;

  @media screen and (max-width: $media-query-l) {
  }
  @media screen and (max-width: $media-query-s) {
    padding: 0px 32px 10px 32px;
  }

  &_top {
    @include flex-between;

    @media screen and (max-width: $media-query-l) {
      @include flex-column;

      gap: 20px;
    }
    @media screen and (max-width: $media-query-s) {
      gap: 12px;
    }

    &__logo-with-download-buttons {
      @include flex-center;

      @media screen and (max-width: $media-query-s) {
        @include flex-column;
        align-items: center;
        gap: 12px;
      }

      ul {
        display: flex;
        flex-direction: row;
        gap: 12px;

        @media screen and (max-width: $media-query-m) {
          gap: 12px;
        }
        @media screen and (max-width: $media-query-s) {
          padding-left: 0px;
        }
        @media screen and (max-width: $media-query-xs) {
          @include flex-column;
          align-items: center;
          padding-left: 0px;
        }
      }
    }

    &__links {
      @include flex-center;
      gap: 39px;
      @media screen and (max-width: $media-query-s) {
        padding-left: 0;
      }
      a {
        color: #374151;
        text-align: center;
        font-size: $font-size-ml;
        line-height: 120%;
        text-decoration: none;
        @media screen and (max-width: $media-query-s) {
          font-size: $font-size-m;
        }
      }
    }

    &__socials {
      display: flex;
      gap: 32px;
    }
  }

  &_bottom {
    padding-bottom: 19px;
    @include flex-center-between;
    @media screen and (max-width: $media-query-m) {
      @include flex-column;
      gap: 12px;
    }
    @media screen and (max-width: $media-query-s) {
      @include flex-column;
      gap: 12px;
    }
    p {
      color: #6b7280;
      font-size: $font-size-m;
      line-height: 90%;
      @media screen and (max-width: $media-query-s) {
        font-size: $font-size-s;
      }
    }

    a {
      color: #374151;
      text-align: center;
      font-size: $font-size-ml;
      line-height: 120%;
      text-decoration: none;
      @media screen and (max-width: $media-query-s) {
        font-size: $font-size-s;
      }
    }

    &__docs {
      display: flex;
      gap: 27px;
      @media screen and (max-width: $media-query-s) {
        @include flex-column;
        padding-left: 0;
      }
      @media screen and (max-width: $media-query-s) {
        @include flex-column;
        gap: 12px;
        padding-left: 0;
      }
    }
  }
}
