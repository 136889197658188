@import "src/mixins/styles";

.dropdown-selector-wrapper {
  display: block;
  width: 100%;

  .dropdown-selector-component {
    display: flex;
    flex-direction: column;
    border: 0;

    .input-title {
      margin-bottom: 10px;
      margin-left: 5px;
      font-size: $font-size-m;
      color: $main-font-color;
    }

    .input {
      display: flex;
      align-items: center;
      height: 35px;
      width: 100%;
      border: 0;
      border-radius: 10px;
      color: $main-font-color;

      &.with-background {
        background: var(--Gray-100, #f2f3f3);
      }

      &.without-background {
        background: none;
      }

      .MuiInputBase-root {
        width: 100%;
        height: 100%;

        input {
          height: 100% !important;
        }
      }

      .MuiInputBase-input {
        padding: 0px 0px 0px 7px !important;
        font-size: 14px;
        min-height: 100%;
      }

      .MuiSelect-select {
        min-height: 100%;
        padding-right: 20px !important;
      }

      .MuiOutlinedInput-input {
        position: relative;
        padding-top: 10px !important;
      }

      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }

      &:focus-within {
        .MuiOutlinedInput-notchedOutline {
          border-color: gray !important;
        }
      }

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: gray !important;
        }
      }
    }

    &:disabled,
    [disabled] {
      background-color: rgba(0, 0, 0, 0.05);
      opacity: 0.5;
    }
  }
}

.MuiMenu-list {
  color: black !important;
}
