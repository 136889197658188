@import "src/mixins/styles";
.knowledgeGroup-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px 10px 10px 10px;
  overflow-x: hidden;
  overflow-y: auto;
  .top-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    .title {
      width: calc(100% - 80px);
      margin-left: 10px;
      font-size: $font-size-l;

      color: $main-font-color;
    }
  }
  .nameInput-wrapper {
    display: flex;
    width: 100%;
    height: fit-content;
    margin-bottom: 20px;
    gap: 10px;
    .nameInput {
      font-size: 18px;
    }
    .checkbox-block {
      display: flex;
      width: fit-content;
      align-items: center;
      //margin-left: -10px;
    }
  }
  .dependsOnGroupsSelector-label,
  .studiesAtGroups0Selector-label,
  .studiesAtGroups1Selector-label,
  .knowledgeTagsSelector-label {
    width: 100%;
    padding-left: 15px;
    margin-bottom: 5px;
  }

  .studiesAtGroups0Selector-label,
  .dependsOnGroupsSelector-label {
    margin-top: 20px;
  }

  .knowledgeTags-wrapper,
  .dependsOnGroups-wrapper,
  .studiesAtGroups0-wrapper,
  .studiesAtGroups1-wrapper {
    width: 100%;
    margin-bottom: 20px;
    .knowledgeTagsSelectorInput {
      align-items: flex-start;
    }
    .knowledgeTagsSelectorChip {
      font-size: 18px;
    }
  }
  .studiesAtGroups0-wrapper {
    margin-bottom: 10px;
  }

  .levelInput-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding-left: 15px;
    .levelDropdownSelector {
      flex-direction: row;
      align-items: center;
      gap: 10px;
      width: 100%;
      .input-title {
        margin-bottom: 0px;
      }
      .input {
        width: 60px;
      }
    }
  }

  .customize-button {
    min-width: fit-content;
    height: $button-height;
    font-size: $font-size-xs;
    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    }
  }
  .fetch-status {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
  }
}
