@import "src/mixins/styles";

.otherSoundSetEditor-page {
  @include root-page-style;

  .header {
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0;
    width: 300px;
    .home {
      border-radius: 20px;
      width: 290px;
      margin: 0;
      div {
        margin-left: 10px;
        font-size: $font-size-ml;
      }
      &:hover {
        width: 295px;
        height: 34px;
        margin-bottom: 0.5px;
        transform: scale(1);
      }
      &:hover > .header-title,
      &:hover > .icon-header {
        transform: scale(1.1);
      }
      &:hover > .icon-header {
        margin-right: 10px;
      }
      .header-title,
      .icon-header {
        transition: all 200ms;
      }
    }

    .back {
      display: none;
    }
  }

  .content-wrapper {
    display: flex;
    .left-side {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $white-color;
      border: 1px solid $border-color;
      box-sizing: border-box;
      border-radius: 15px;
      width: 300px;
      min-width: 300px;
      margin: 45px 5px 0 0;
      height: calc(100vh - 55px);
      padding: 10px 0px 10px 0px;
      gap: 10px;
      position: relative;
      overflow: hidden;
      .addButton {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        background-color: $main-accent-color;
        fill: white;
        &:hover {
          scale: 1.1;
          transition: all 200ms ease;
        }
        &:active {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
          transition: all 200ms ease;
        }
      }
      .soundSetsListWrapper {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        gap: 20px;
        .typedSoundSetsList {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          .soundSetTypeTitle {
            width: 280px;
            background-color: lightgray;
            border-radius: 10px 10px 3px 3px;
            text-align: left;
            font-size: 16px;
            font-weight: 600;
            padding-left: 10px;
          }
        }
      }
    }
    .middle-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $white-color;
      border: 1px solid $border-color;
      box-sizing: border-box;
      overflow-y: auto;
      border-radius: 15px;
      width: 100%;
      margin-right: 5px;
      height: calc(100vh - 10px);
      padding: 10px 10px 10px 10px;
      min-width: 360px;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      gap: 30px;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 10px;
        background-color: lightgray;
        border-radius: 10px 10px 0px 0px;
        height: 25px;
        padding-right: 3px;
        width: 100% !important;
        .title {
          display: flex;
          font-weight: 600;
          font-size: 16px;
        }
      }
      .soundSetTypeSelector {
        width: 200px;
        padding: 0px 0px 0px 0px;
      }
      .deleteButton {
        position: absolute;
        top: 13px;
        right: 13px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: $main-accent-color;
        fill: white;
        &:hover {
          scale: 1.1;
          transition: all 200ms ease;
          cursor: pointer;
        }
        &:active {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
          transition: all 200ms ease;
        }
      }
    }
    .right-side {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $white-color;
      border: 1px solid $border-color;
      box-sizing: border-box;
      border-radius: 15px;
      min-width: 350px;
      max-width: 350px;
      height: calc(100vh - 10px);
      padding: 10px;
      overflow-y: hidden;
      overflow-x: hidden;
    }
  }
}
