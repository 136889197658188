@import "src/mixins/styles";

.slider-with-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: $main-font-color;
  
  .slider-title {
    margin-right: 10px;
    white-space: nowrap;
  }

  .slider-range {
    margin-right: 10px;
    &:hover {
      outline: none;
    }

    .MuiSlider-thumb {
      background-color: $main-accent-color;
    }

    .MuiSlider-rail {
      background-color: $placeholder-color;
      height: 3px;
    }

    .MuiSlider-track {
      background-color: $main-accent-color;
      height: 3px;
    }

    .PrivateValueLabel-circle-4 {
      background-color: $main-accent-color;
    }

    .PrivateValueLabel-label-5 {
      color: $main-font-color;
    }
  }

  .slider-input {
    width: 53px;
    height: 20px;
    margin: 0 0 0 10px;
    padding: 2px 0 2px 3px;
    font-size: $font-size-s;
    border-radius: 5px;
  }
}
