@import "src/mixins/styles";

.user-editor-wrapper {
  @include background-style;
  padding: 5px;
  overflow: hidden;
  display: flex;
  .left-header {
    display: flex;
    width: 40px;
    padding: 5px;
  }
}
