@import "src/mixins/styles";

.item-passive-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  .item-passive-card {
    position: relative;
    min-width: 100px;
    max-width: 100%;
    min-height: 255px;
    box-shadow: none;
    border: 2px solid $border-color;
    margin: 5px;
    background: linear-gradient(270.14deg, rgba(255, 0, 0, 0.15) 0.13%, rgba(255, 0, 0, 0) 99.89%);
    .customize-button {
      position: absolute;
      left: 3px;
      top: 3px;
      width: 26px;
      height: 26px;
      z-index: 1;
      span {
        margin: 0;
        padding-top: 2px;
      }
    }
    .remove-btn {
      position: absolute;
      left: 10px;
      top: 12px;
      min-width: 34px;
      min-height: 34px;
      max-width: 34px;
      max-height: 34px;
      z-index: 1;
    }
    .item-passive-content {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .item-img {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 5px 0 5px;
        img {
          width: 60px;
        }
      }
      .item-ranges {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        margin-left: 10px;
        .x-y-zindex {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          .slider-with-input {
            margin: 0 0 10px 0;
            input {
              margin: 0;
            }
            .slider-title {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
