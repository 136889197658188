@import "src/mixins/styles";
.user-card-component {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 42px;
  background-color: white;
  border-radius: 15px;
  box-sizing: border-box;
  border: 1px solid $main-accent-color;
  padding: 0px 7px 0px 5px;
  position: relative;
  cursor: pointer;

  &.user-card-component-selected {
    transition: 200ms all;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
  }

  .user-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: $main-accent-color;
    border-radius: 50% 50%;
    border: 1px solid gray;
    svg {
      fill: white;
    }
  }
  .user-name-block {
    display: flex;
    align-items: center;
    width: calc(100% - 65px);
    .user-name {
      width: 100%;
      padding: 0px 5px;
      text-align: center;
      text-overflow: ellipsis;
      white-space: wrap;
      overflow: hidden;
    }
  }

  .unity-language-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    border: 1px solid $main-accent-color;
    border-radius: 5px;
    .label {
    }
    .language {
    }
  }
}
