@import "src/mixins/styles";
.filtersPanel-component {
  display: flex;
  flex-direction: column;
  .searchFilter {
    margin-bottom: 10px;
    &:first-child {
      margin-top: 20px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .selectFilters-container {
    margin-top: 10px;
    overflow-y: auto;
    .options-list-wrapper {
      display: flex;
      flex-direction: column;

      .search-box {
        margin-bottom: 10px;
        padding: 1px;
      }

      .options-list {
        width: 100%;

        .options-box-wrapper {
          margin-left: 0px;
          border-bottom: none;

          // .filter-label {
          //   font-weight: 600;
          //   font-size: 18px;
          //   margin-bottom: 15px;
          // }

          .options-box {
            overflow-y: auto;
            width: 100%;
            max-height: calc(100vh - 350px);

            .optionElement-wrapper {
              overflow: auto;
              border-radius: 20px;
              margin-bottom: 3px;

              .optionElement {
                display: flex;
                align-items: center;
                font-weight: 500;
                cursor: pointer;
                padding: 7px 5px 7px 10px;
                transition: all 200ms ease-in-out;

                &.with-checkbox {
                  padding: 7px 5px 7px 10px;
                }

                .checkbox {
                  position: relative;
                  border: 2px solid $placeholder-color;
                  border-radius: 2px;
                  max-height: 16px;
                  height: 16px;
                  min-width: 16px;
                  margin-right: 10px;

                  &.checkbox-selected {
                    &::after {
                      content: "+";
                      position: absolute;
                      top: -2px;
                      left: 2px;
                    }
                  }
                }
              }

              .optionElement-selected {
                background-color: rgba(0, 0, 0, 0.05);
                padding: 7px 5px 7px 10px;
              }
            }
          }
        }
      }
    }
  }
}
