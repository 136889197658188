@import "src/mixins/styles";

.styledSwitch-component {
  &.sliderMode {
    .Mui-checked {
      color: $main-accent-color;
    }
    .MuiSwitch-switchBase {
      color: $main-accent-color;
    }
    .MuiSwitch-track {
      background-color: $placeholder-color;
    }
  }
  &.checkMode {
    .Mui-checked {
      
    }
    .MuiSwitch-switchBase {
      
    }
    .MuiSwitch-track {
      
    }
  }
}
