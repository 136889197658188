@import "src/mixins/styles";

.user-profile-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;

  .toast-position {
    position: absolute;
    top: 0px;
    width: calc(100% - 0px);
  }
  .toastClassName {
    min-height: 30px;
  }
  .user-profile-tabs-block {
    display: flex;
    padding: 5px;
    margin-left: 255px;
    width: calc(100% - 255px);
  }
}
