@import "src/mixins/styles";

.assetBundles-component {
  @include background-style;
  padding: 5px;
  overflow: hidden;
  display: flex;
  .left-header {
    display: flex;
    width: 40px;
    padding: 5px;
  }
  .assetBundles-container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 10px);
    overflow-y: auto;
    padding: 0;
    width: calc(100% - 45px);
    margin-left: auto;
    .left-side {
      min-width: 280px;
      height: 100%;
      overflow: hidden;
      box-shadow: 12px 0px 78px rgba(0, 0, 0, 0.1);
      padding: 15px;
    }

    .right-side {
      padding: 5px;
      width: 100%;
      height: 100%;
      .right-side-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        height: 100%;
        padding-top: 35px;
        .progressExtraData {
          display: flex;
          position: absolute;
          top: 15px;
          right: 15px;
          width: fit-content;
          align-items: center;
          .progressExtraDataLabel {
            font-size: 16px;
            color: $main-font-color;
            margin-left: 10px;
          }
        }
        .progress-box {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          height: 100%;
          width: 100%;
          .progressFetchingList {
            position: relative;
            .progressFetchingListLabel {
              position: absolute;
              top: calc(100% / 2);
              width: 100%;
              font-size: 18px;
              text-align: center;
              color: $main-font-color;
            }
          }
        }
        .add-new-assetBundle-btn {
          border: none;
          background: none;
          transition: all 200ms ease-in-out;
          width: fit-content;
          margin-bottom: 45px;
          cursor: pointer;
          &:hover {
            transform: scale(1.1);
            filter: drop-shadow(0px 28px 27px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.05))
              drop-shadow(0px 11px 15px rgba(0, 0, 0, 0.06));
          }
          svg {
            circle {
              fill: $main-accent-color;
            }
            transition: all 200ms ease-in-out;
          }
        }
        .new-assetBundle-btn-active {
          svg {
            circle {
              fill: $main-accent-color;
            }
            transform: rotate(45deg);
          }
        }

        .assetBundles-list {
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow-x: hidden;
          overflow-y: auto;
          padding: 5px 20px 0px 20px;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
