@font-face {
  font-family: "TTRounds-Bold";
  src: url("../assets/fonts/TTRounds-Bold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

.userAchievementCardKnowledge {
  width: 1080px;
  height: 1080px;
  padding: 64px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  //gap: 52px;

  .top {
    display: flex;
    justify-content: center;
    width: 100%;
    height: fit-content;
    img {
      width: 179px;
    }
  }

  .user-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
    //gap: 10px;
    .user-block-header {
      margin-top: 10px;
      width: 100%;
      font-family: "Manrope" !important;
      font-size: 36px;
      font-weight: 500;
      //line-height: 52.71px;
      text-align: center;
      color: #374151;
    }
    .user-name {
      display: block;
      max-width: 950px;
      font-family: "Manrope" !important;
      font-size: 53px;
      font-weight: 500;
      text-align: left;
      color: #f3c00b;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .user-action {
      display: flex;
      margin-top: 10px;
      font-family: "Manrope" !important;
      font-size: 53px;
      font-weight: 500;
      line-height: 52.71px;
      text-align: left;
      color: #374151;
    }
  }
  .image-block {
    display: flex;
    justify-content: center;
    width: 100%;

    .image-container {
      display: flex;
      min-width: 600px;
      max-width: 600px;
      height: 600px;
      align-items: center;
      justify-content: center;
      img {
        //display: flex;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .knowledge-name {
    font-family: TTRounds-Bold !important;
    font-size: 84px;
    font-weight: 400;
    line-height: 81px;
    text-align: center;
    color: #374151;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}
