@import "src/mixins/styles";

.knowledgePairs-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;

  .title {
    display: flex;
    color: $main-font-color;
    font-size: $font-size-m;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  .knowledgePairs-filter-box {
    display: flex;
    padding: 5px 10px;
    width: 100%;
    .searchValue-input {
      width: 100%;
    }
  }

  .knowledgePairs-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 10px;
    width: 100%;
    .knowledgePair-card {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 10px;
      border: 1px solid $border-color;
      box-sizing: border-box;
      min-height: fit-content;
      cursor: pointer;
      padding: 5px 5px 5px 5px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      color: $main-font-color;
      font-size: 14px;
      margin-bottom: 20px;
      background-color: rgb(233, 245, 255);
      position: relative;

      .delete-button-wrapper {
        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: white;
        border-radius: 50%;
        border: 2px solid lightgray;
        height: 20px;
        width: 20px;
        cursor: pointer;
        svg {
          fill: gray;
          width: 10px;
          height: 10px;
        }
      }

      .alarmMessages {
        display: flex;
        flex-direction: column;
        padding-left: 5px;
      }

      .circular-progress {
        position: absolute;
        left: calc(100% / 2 - 50px);
        top: calc(100% / 2 - 50px);
      }
      transition: all 200ms ease;
      &:hover {
        box-shadow: 0px 10px 15px rgb(0, 0, 0, 0.15);
      }

      &.active {
        outline: 5px solid gray;
        border: none;
        box-shadow: 0px 0px 20px rgb(29, 29, 29);
        background-color: rgb(184, 220, 249);
        .delete-button-wrapper {
          display: flex;
        }
      }
      &.alarm-color {
        background-color: rgb(220, 160, 160);
        &.active {
          background-color: rgb(220, 121, 121);
        }
      }
    }
  }
}
