@import "src/mixins/styles";

.knowledgeGroupSelector {
  display: flex;
  width: 100%;
  align-items: center;

  .MuiAutocomplete-root {
    width: 100%;
    .MuiOutlinedInput-root {
      border-radius: 25px !important;
      padding: 5px 0 5px 5px;
      background-color: #fff;

      input:hover {
        outline: none;
      }
    }
  }
}

.knowledgeGroupSelector-popper {
  min-width: 0px;
  div {
    border-radius: 15px;
    margin: 10px 0;
  }
  li {
    color: $main-font-color;
    border-bottom: 1px solid rgb(240, 240, 240);
    .option-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: fit-content;
      width: 100%;
      margin: 0px;
    }
  }
}
