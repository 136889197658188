@import "src/mixins/styles";

.button {
  @include button;
  position: relative;
  .progress {
    position: absolute;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
  .icon-header {
    display: flex;
    align-items: center;
  }
  &:disabled,
  [disabled] {
    background-color: $placeholder-color;
    opacity: 0.5;
  }
  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
  }
}
