@import "src/mixins/styles";
.landing {
  margin: 0 auto;

  .global_main {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin: 0 auto;
    padding: 0px 64px 20px 64px;
    max-width: 1440px;

    @media screen and (max-width: $media-query-xl) {
      gap: 40px;
      padding: 0px 64px 20px 64px;
    }

    @media screen and (max-width: $media-query-l) {
      gap: 20px;
      padding: 0px 64px 20px 64px;
    }
    @media screen and (max-width: $media-query-m) {
      gap: 20px;
      padding: 0px 32px 20px 32px;
    }

    @media screen and (max-width: $media-query-s) {
      gap: 20px;
      padding: 0px 32px 20px 32px;
    }
  }
}
