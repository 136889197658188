@import "src/mixins/styles";

.customer-tile-wrapper {
  display: flex;
  width: 100%;
  background-color: $white-color;
  border-bottom: solid 1px $border-color;
  padding: 15px 0;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: #fafafa;
  }

  .tile-content {
    display: flex;
    width: 100%;

    .tile-row {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .tile-cell {
        flex: 1;
        display: flex;
        align-items: center;
        padding: 0 10px;
        justify-content: center;

        &:first-child {
          flex: 2;
          justify-content: flex-start;
        }

        .tile-icon {
          display: flex;
          margin-right: 5px;
        }

        .webUserEmailFlag {
          margin-right: 5px;
        }

        .green-badge {
          border-radius: 9px;
          padding: 6px 10px;
          color: $light-green-font-color;
          background-color: $light-green-background-color;
        }

        .gray-badge {
          border-radius: 9px;
          padding: 6px 10px;
          color: #233741;
          background-color: $game-group-tile-background-color;
        }

        .yellow-badge {
          border-radius: 9px;
          padding: 6px 10px;
          color: $main-accent-color;
          background-color: $light-yellow-background-color;
        }

        .badgeHover {
          transition: all 0.2s ease;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}
