@import "src/mixins/styles";

.sceneCard-component {
  position: relative;
  width: 200px;
  aspect-ratio: 16/9;
  border-radius: 10px;
  transition: all 200ms;
  cursor: pointer;
  overflow: hidden;
  @include round-remove-button;
  font-size: 0;
  .circular-progress {
    position: absolute;
    left: calc(100% / 2 - 40px);
    top: calc(100% / 2 - 40px);
  }
  .customize-button {
    width: 25px;
    height: 25px;
    z-index: 100;
    padding: 0;
    margin: 0;

    &.deleteScene {
      top: 5px;
      right: 5px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      .deleteScene-button-icon {
        width: 15px;
        height: 15px;
        fill: white;
      }
    }

    &.addToLibrary {
      bottom: 5px;
      left: 5px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      .addToLibrary-button-icon {
        width: 12px;
        height: 12px;
        margin-left: -2px;
        path {
          stroke: white;
        }
      }
    }
  }
  .sceneCard-content {
    position: relative;
    width: 100%;
    .scene-bg {
      width: 100%;
      border-radius: 10px;
      object-fit: cover;
      aspect-ratio: 16 / 9;
      transform: scale(1.33);
      &.no-scale {
        transform: scale(1);
      }
      &.srcSvg {
        object-fit: none;
      }
    }
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 15px rgb(0, 0, 0, 0.15);
  }
  &.active-sceneCard-component {
    outline: 5px solid $main-accent-color;
    border: none;
    box-shadow: 0px 10px 15px rgb(0, 0, 0, 0.15);
    //transform: scale(1.05);
  }
}
