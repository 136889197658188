@import "src/mixins/styles";

.tabs-wrapper {
  display: flex;
  width: 100%;
  height: 30px;
  .left-button-block,
  .right-button-block {
    display: flex;
    width: 20px;
    .scroll-back,
    .scroll-forward {
      height: 30px;
      border-radius: 10px 5px 5px 10px;
      display: flex;
      justify-content: center;
      width: 10px;
      span {
        margin-left: 5px;
      }
    }
    .scroll-forward {
      border-radius: 5px 10px 10px 5px;
      span {
        margin-left: 10px;
        svg {
          transform: rotate(0.5turn);
        }
      }
    }
  }
  .tabs-list-wrapper {
    width: calc(100% - 40px);
    .tabs-list {
      display: flex;
      overflow: hidden;
      margin: 0px 5px 0px 5px;
      .tab {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 5px 10px 5px 10px;
        height: 30px;
        font-size: 18px;
        font-weight: 500;
        border-radius: 5px;
        margin: 0px 5px 0px 5px;
        background: $user-plan-tabs-color;
        white-space: nowrap;
        &.selected {
          background-color: $bg-color;
        }
        &.disabled {
          display: none;
          background-color: lightgray;
          color: gray;
          cursor: default;
        }

        &:hover {
          transition: 0.2s ease-in-out;
          transform: scale(1.1);
          box-shadow: 0px 10px 15px rgb(0, 0, 0, 0);
          &.disabled {
            transition: none;
            transform: none;
            box-shadow: none;
          }
        }
      }
    }
  }
}
