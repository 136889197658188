@import "src/mixins/styles";

.debugPage-component {
  @include background-style;
  align-items: center;
  padding: 5px;
  color: $main-font-color;

  .main-header {
    margin: 0px;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0;
    width: 270px;
    .home {
      border-radius: 20px;
      width: 260px;
      margin: 0;
      div {
        margin-left: 10px;
        font-size: $font-size-ml;
      }
      &:hover {
        width: 265px;
        height: 34px;
        margin-bottom: 0.5px;
        transform: scale(1);
      }
      &:hover > .header-title,
      &:hover > .icon-header {
        transform: scale(1.1);
      }
      &:hover > .icon-header {
        margin-right: 10px;
      }
      .header-title,
      .icon-header {
        transition: all 200ms;
      }
    }

    .back {
      display: none;
    }
  }

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    height: 100%;
    .left-content-wrapper {
      display: flex;
      height: calc(100% - 45px);
      margin-top: 45px;
      width: 270px;
      min-width: 270px;
      margin-right: 5px;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      .unityUsers-list {
        display: flex;
        flex-direction: column;
        .unityUser-row {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          background-color: $border-color;
          border-radius: 15px;
          padding: 5px 7px;
          overflow-x: hidden;
          position: relative;
          cursor: pointer;
          .deleteOneUserDebugButton {
            right: 5px;
            left: auto;
            top: 5px;
          }
          &.active {
            border: 4px solid $main-accent-color;
          }
          .userName {
            font-weight: 600;
            word-wrap: break-word;
            display: inline-block;
          }
          .userUnityId {
            word-wrap: break-word;
            display: inline-block;
          }
          .userCreatedAt {
          }
        }
      }
    }
    .middle-content-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 360px;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;

      .debugList-area {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .title-box {
          position: relative;
          display: flex;
          justify-content: center;
          .deviceIdChip {
            position: absolute;
            left: 100px;
            top: -4px;
            width: fit-content;
            background-color: white;
            border: 1px solid rgba(0, 0, 0, 0.26);
            font-size: 16px;
          }
          .title {
            display: flex;
            color: $main-font-color;
            font-size: $font-size-ml;
            text-transform: uppercase;
            margin-bottom: 15px;
          }
          .prev-button,
          .next-button {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border-radius: 10px;
            border: 2px solid $border-color;
            cursor: pointer;
            background-color: $main-accent-color !important;
            transition: all 200ms ease-in-out;
            &:disabled,
            [disabled] {
              cursor: auto;
              background-color: rgb(240, 240, 240) !important;
            }
          }
          .next-button {
            right: 0px;
            top: -5px;
            svg {
              margin-left: 3px;
              transform: rotate(0.5turn);
            }
          }
          .prev-button {
            top: -5px;
            right: 40px;
            svg {
              margin-left: -3px;
            }
          }
          .deleteAllButton {
            right: 200px;
            left: auto;
          }
        }

        .debugList {
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow-y: auto;
          overflow-x: hidden;

          .debug-card {
            display: flex;
            padding: 0;
            margin-bottom: 10px;
            max-height: 550px;
            .debug-card-header {
              display: flex;
              gap: 20px;
              align-items: center;
              border-radius: 15px;
              background-color: $border-color;
              padding: 10px;
              cursor: pointer;
              &.active {
                border-radius: 15px 15px 0 0;
              }
              .debug-date {
                display: flex;
                width: fit-content;
                white-space: nowrap;
                font-size: 18px;
                text-align: center;
              }
              .app-version {
                display: flex;
                min-width: 110px;
                font-size: 18px;
                text-align: left;
              }
              .device-id {
                display: flex;
                justify-content: center;
                width: 100%;
                font-size: 18px;
                text-align: center;
                &:hover {
                  text-decoration: underline;
                }
              }

              .debug-unityId {
                display: flex;
                font-size: 18px;
                min-width: 140px;
                word-wrap: break-word;
                display: inline-block;
                &:hover {
                  text-decoration: underline;
                }
              }
              .collapser {
                display: flex;
                align-items: center;
                padding: 10px;
                transition: all 200ms ease-in-out;
                cursor: pointer;
                &.flip {
                  svg {
                    transition: all 200ms ease-in-out;
                    transform: scale(1.5);
                  }
                }
                svg {
                  transition: all 200ms ease-in-out;
                  transform: scale(1.5) rotate(180deg);
                }
              }
            }
            .content {
              display: flex;
              flex-direction: column;
              padding: 10px 10px;
              font-size: 16px;
              .firstLine {
                display: flex;
                width: 100%;
                margin-bottom: 10px;
                .debug-device {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  height: 100%;
                  .header {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 600;
                    height: 25px;
                    background-color: rgb(245, 245, 245);
                  }
                  pre,
                  .deviceModel,
                  .deviceId {
                    margin: 0 10px;
                  }
                }
              }
              .secondLine {
                display: flex;
                width: 100%;
                max-height: 410px;
                .debug-debug {
                  display: flex;
                  flex-direction: column;

                  width: calc((100% - 10px) / 2);
                  margin-right: 10px;

                  .header {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 600;
                    height: 25px;
                    background-color: rgb(245, 245, 245);
                  }
                  .content {
                    display: inline-block;
                    overflow-wrap: break-word;
                    padding: 10px 10px;

                    overflow: auto;
                  }
                }
                .debug-config {
                  display: flex;
                  flex-direction: column;
                  width: calc((100% - 10px) / 2);
                  .header {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 600;
                    height: 25px;
                    background-color: rgb(245, 245, 245);
                  }
                  pre {
                    overflow: auto;
                    margin: 10px 10px;
                    white-space: pre-wrap; /* Since CSS 2.1 */
                    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
                    white-space: -pre-wrap; /* Opera 4-6 */
                    white-space: -o-pre-wrap; /* Opera 7 */
                    word-wrap: break-word;
                  }
                }
              }
            }
          }
        }
      }

      .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        width: 100%;
        .MuiPagination-text {
          font-size: 25px;
        }
      }
    }
  }
}
