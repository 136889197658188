@import "src/mixins/styles";

.dragDrop-delimiter {
  display: flex;
  width: 100%;
  height: 20px;
  min-height: 20px;
  border-radius: 10px;
  margin-top: 0px;
  z-index: 10;
  &.color-dragOver {
    transition: 200ms all;
    background-color: lightgray;
  }
  &:last-child {
    height: 100%;
  }
}

.planPlus-component {
  display: flex;
  gap: 5px;
  height: 100%;
  .done-column,
  .inProgress-column,
  .toDo-column,
  .future-column {
    display: flex;
    flex-direction: column;
    border: 1px solid $border-color;
    border-radius: 15px;
    width: 100%;
    height: calc(100% - 5px);
    padding: 5px 0px;
    overflow: hidden;
    position: relative;
    .column-header {
      display: flex;
      width: 100%;
      justify-content: center;
      color: $main-font-color;
      font-weight: 550;
      font-size: 16px;
    }

    .checkPlanButton-block {
      top: 5px;
      right: 5px;
      width: fit-content;
      display: block;
      position: absolute;

      cursor: pointer;
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        span,
        .icon {
          margin: 0;
          display: flex;
          width: 16px;
          height: 16px;
          fill: white;
        }
      }

      &:disabled,
      [disabled] {
        background-color: $placeholder-color;
        opacity: 0.5;
        &:hover {
          transform: scale(1);
          cursor: auto;
        }
      }
      &:hover {
        transform: scale(1);
      }
    }
  }

  .done-column {
    .params {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      gap: 5px;
      .inputWithTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .param-title {
          display: flex;
          align-items: center;
          &[disabled] {
            color: gray;
          }
        }
        .param-input {
          display: flex;
          align-items: center;
          min-height: 25px;
          max-height: 25px;
          width: 50px;
          margin-left: 10px;
          margin-bottom: 0px;
          border-radius: 10px;
          padding: 3px;
          background-color: white !important;
        }
      }
      .buttons {
        display: flex;
        flex-direction: row;
        gap: 5px;
        width: 100%;
        justify-content: center;
        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          span,
          .icon {
            margin: 0;
            display: flex;
            width: 16px;
            height: 16px;
            fill: white;
          }
        }
      }
    }
    .done-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: calc(100% - 5px);
      height: calc(100vh - 100px);
      margin-top: 10px;
      margin-right: 10px;
      padding: 0px 5px 0px 10px;
      overflow-x: auto;
      gap: 10px;

      .battery {
        display: flex;
        flex-direction: column;
        width: 100px;
        min-height: 200px;
        height: 200px;
        border: 1px solid lightgray;
        border-radius: 10px;

        .battery-name {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          color: $main-font-color;
          white-space: wrap;
          text-align: center;

          width: 100%;
        }
        .battery-body {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: calc(100% - 40px);
          margin: 0px 10px 10px 10px;
          border-radius: 10px;
          border: 1px solid lightgray;
          overflow: hidden;
          position: relative;
          .battery-levels {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            position: absolute;
            top: calc(100% / 2 - 50px);
            color: $main-font-color;
            font-weight: 400;
          }
          .battery-delta-fill {
            display: flex;
            &.green {
              background-color: green;
            }
            &.light-green {
              background-color: lightgreen;
            }
            &.light-red {
              background-color: lightcoral;
            }
          }
          .battery-start-fill {
            display: flex;
            &.green {
              background-color: green;
            }
            &.light-green {
              background-color: lightgreen;
            }
          }
        }
      }
    }
  }
  .inProgress-column {
    .inProgress-list {
      display: flex;
      flex-direction: column;
      width: calc(100% - 5px);
      height: calc(100vh - 100px);
      margin-top: 10px;
      margin-right: 10px;
      padding: 0px 5px 0px 10px;
      overflow-x: auto;
      gap: 10px;
    }
  }
  .toDo-column {
    .toDo-list {
      display: flex;
      flex-direction: column;
      width: calc(100% - 5px);
      height: calc(100vh - 100px);
      margin-top: 10px;
      margin-right: 10px;
      padding: 0px 5px 0px 5px;
      overflow-x: auto;
      gap: 5px;
    }
  }
  .future-column {
    .future-list {
      display: flex;
      flex-direction: column;
      width: calc(100% - 5px);
      height: calc(100vh - 100px);
      margin-top: 10px;
      margin-right: 10px;
      padding: 0px 5px 0px 5px;
      overflow-x: auto;
      gap: 5px;
    }
  }
}
