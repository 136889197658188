@import "src/mixins/styles";

.knowledgeTagList-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .knowledgeTagList {
    display: flex;
    width: 100%;
    max-height: 100%;
    flex-wrap: wrap;
    overflow-y: auto;
    align-items: flex-start;
    .chip {
      margin: 3px;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.26);
      box-sizing: border-box;
      height: 25px;
      padding: 0px 0px;
      &.doesntHaveSoundSet {
        border: 2px solid rgb(253, 20, 20);
      }
      &.selected {
        background-color: rgba(0, 0, 0, 0.124);
      }
      &.selectedRoot {
        background-color: $main-accent-color-light;
      }
      .chipIcon {
        fill: white;
        background-color: rgba(0, 0, 0, 0.26);
        border-radius: 50%;
        height: 18px;
        width: 18px;
      }
    }
  }
}
