@import "src/mixins/styles";
.history {
  padding-left: 20px;
  width: 100%;
  align-items: flex-start;
  display: flex;
  gap: 20px;
  min-height: 550px;
  @media screen and (max-width: $media-query-xl) {
  }
  @media screen and (max-width: $media-query-l) {
    flex-direction: column;
  }
  @media screen and (max-width: $media-query-m) {
  }
  @media screen and (max-width: $media-query-s) {
  }
  .history-images-left {
    // padding: 0 40px;
    width: 45%;
    position: relative;
    display: flex;
    justify-content: center;

    .history-images-left__p1 {
      z-index: 1;
      position: absolute;
      left: calc(100% / 2 - 250px);
      top: 0px;
    }

    .history-images-left__p2 {
      // background: url("../../../assets/parent-bg.png") center center;
      //padding: 5px;
      position: absolute;
      left: calc(100% / 2 - 100px);
      top: 220px;
    }
    @media screen and (max-width: $media-query-l) {
      display: none;
    }
  }

  &_text {
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;

    p {
      font-family: "Manrope";
      font-weight: 400;
      color: #374151;
      font-size: 18px;
      line-height: 140%;
      .read-more {
        color: #ffb300;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    h3 {
      font-family: "Manrope";
      color: #374151;
      font-weight: 500;
    }

    &__heading {
      color: #374151;
      font-size: 36px;
      line-height: 120%;
      letter-spacing: -1px;

      span {
        color: #ffb300;
        font-size: 36px;
        line-height: 120%;
        letter-spacing: -1px;
      }
    }
    @media screen and (max-width: $media-query-l) {
      width: 100%;
    }
    .history-images-inside {
      // padding: 0 40px;
      width: 100%;
      position: relative;
      display: none;
      justify-content: center;
      height: 320px;

      .history-images-inside__p1 {
        z-index: 1;
        position: absolute;
        right: calc(100% / 2);
        top: -30px;
      }

      .history-images-inside__p2 {
        position: absolute;
        right: calc(100% / 2 - 250px);
        top: -30px;
      }
      @media screen and (max-width: $media-query-l) {
        display: flex;
      }
      @media screen and (max-width: $media-query-m) {
        height: 400px;
        .history-images-inside__p1 {
          z-index: 1;
          position: absolute;
          right: calc(100% / 2 - 120px);
          top: -90px;
          transform: scale(0.8);
        }

        .history-images-inside__p2 {
          position: absolute;
          right: calc(100% / 2 - 190px);
          top: 110px;
          transform: scale(0.8);
        }
      }
    }
  }
}
