@import "src/mixins/styles";

.game-knowledges-component {
  display: flex;
  width: 100%;
  .game-knowledges {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    .game-knowledge-wrapper {
      display: flex;
      align-items: center;
      margin-top: 5px;
      height: 20px;
      width: 100%;
      border-bottom: 1px solid $border-color;
      .game-knowledge-label {
        display: flex;
        display: inline;
        align-items: center;
        //min-width: 150px;
        min-width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .game-knowledge-progress {
        display: flex;
        align-items: center;
        margin-left: 5px;
        width: 100%;
        height: 10px;
        border-radius: 5px;
        overflow: hidden;
        &.controlled-knowledge-progress-color {
          border: 1px solid gray;
          background-color: #23eb00;
        }

        .game-knowledge-progress-element {
          height: 10px;
          background-color: rgb(255, 217, 0);
          border-radius: 5px;
          border: 1px solid gray;
        }
      }
      .game-knowledge-param {
        display: flex;
        justify-content: flex-end;
        margin-left: 5px;
        width: 40px;
        overflow: hidden;
      }
    }
  }
}