:root {
  --main-accent-color: #faa400;
  --main-accent-color-light: #faa20085;
  --button-color: #faa400;
}

$main-font-color: #2b2b2b;
$main-font-color-light: #4a4a4a;
$border-color: #e8e8e8;
$main-accent-color: var(--main-accent-color);
$main-accent-color-light: var(--main-accent-color-light);
$placeholder-color: #a4a4a4;
$white-color: #ffffff;
$alert-color: #f85959;
$button-icons-color: $main-font-color;
$button-color: var(--button-color);
$button-color-active: #c5e5f9;
$light-green-font-color: #8dbc03;
$light-green-background-color: #edf6d2;
$light-yellow-background-color: #fef0e4;
// $bg-color: #FAA400;
$badge-color: #233741;
$bg-color: #f2f3f3;
$game-group-tile-background-color: #e5e6e7;
$user-plan-tabs-color: var(--main-accent-color);
$user-plan-tabs-color-hover: var(--main-accent-color-light);
$form-text-color: #374151;
$form-text-header: #ffb300;
$form-input-color: #6b7280;
$form-btn-color: #f9fafb;
$statistic-controlled-correct-color: #9bed8d;
$statistic-controlled-incorrect-color: #ffab99;
$statistic-progress-color: rgb(255, 217, 0);
$statistic-progress-light-color: rgb(255, 255, 150);

$statistic-known-color: #5ed548;
$statistic-knownMinor-color: #e7ffe2;
$statistic-ready-to-control-color: rgb(255, 255, 150);
$statistic-ready-to-learn-color: rgb(255, 171, 150);

$subscription-button-color: #ffb300;

$font-size-xs: 11px;
$font-size-s: 12px;
$font-size-m: 14px;
$font-size-ml: 16px;
$font-size-xml: 20px;
$font-size-l: 24px;
$font-size-xl: 32px;
$font-size-xxl: 38px;

$media-query-xs: 320px;
$media-query-s: 480px;
$media-query-m: 768px;
$media-query-l: 1024px;
$media-query-xl: 1200px;

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flex-center-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
}

@mixin flex-center-between-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.button-icon {
  fill: $white-color;
}

.changes-icon {
  fill: $main-accent-color;
}

@mixin root-page-style {
  align-items: center;
  padding: 5px;
  color: $main-font-color;
  @include background-style;
}

@mixin background-style {
  height: 100vh;
  width: 100%;
  background: $bg-color;
}

@mixin button {
  background: $button-color;
  border: none;
  color: $white-color !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 10px 10px 10px;
  transition: all 200ms;
  text-align: center;
  span {
    margin-right: 7px;
  }
}

$button-height: 35px;

@mixin game-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 35px;
  border-radius: 25px;
  border: none;
  font-size: 16px;
  padding: 0 10px;
  height: 50px;
  width: 210px;
  max-width: 230px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
  }
}

@mixin scroll-styles {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: #e8e8e8;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 5px;
  }
}

@mixin input {
  height: 35px;
  // border: 1px solid $border-color;
  // border-radius: 20px;
  border: 0;
  border-radius: 10px;
  background: #f2f3f3;
  box-sizing: border-box;
  width: 100%;
  font-size: $font-size-m;
  &:focus {
    outline: 0;
  }
  &:hover {
    outline: 1px solid $main-accent-color;

    &:disabled {
      outline: none;
    }
  }
  &::-webkit-input-placeholder {
    color: $placeholder-color;
    font-size: $font-size-m;
  }
}

$input-search-icon: no-repeat 9px 9px;

@mixin card-template {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  width: 100%;
  background: $white-color;
}

@mixin card-landing-template {
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 28px 60px 0 rgba(154, 154, 175, 0.27);
  backdrop-filter: blur(14px);
}

@mixin round-remove-button {
  .customize-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    padding: 0;
    margin: 10px;
    z-index: 2;

    span {
      padding-top: 3px;
      margin-right: 0;
    }

    img {
      height: 20px;
    }
  }
}

@mixin round-copy-button {
  .customize-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    bottom: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    padding: 0;
    margin: 10px;
    z-index: 2;

    span {
      padding-top: 3px;
      margin-right: 0;
    }

    img {
      height: 20px;
    }
  }
}

.MuiListItemText-primary {
  color: $main-font-color;
}

.MuiTooltip-tooltip {
  background: $white-color !important;
  border: 1px solid $border-color !important;
  border-radius: 10px !important;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  color: $main-font-color !important;

  p {
    color: $main-font-color;
  }
}

.MuiTooltip-arrow {
  color: $white-color;

  &::before {
    border: 1px solid #e6e8ed;
    background-color: $white-color;
    color: $white-color;
  }
}

@mixin login-bg {
  height: 100vh;
  width: 100%;
  background: url("./../assets/images/Login/login_bg.svg") center center no-repeat, rgba(255, 255, 255, 0.9);
}
